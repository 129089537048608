import React from 'react'
import { Link } from 'react-router-dom'
export default function WithdrawFee() {
  return (
    <div>
      <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/" className="text-white"><i className="fa fa-angle-left m-0"></i> </Link>  &nbsp;
                                 Deposit & Withdrawal Fees
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            
            
    <section className="window">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-12">
          <div className="campus_box text-white w_F">
                
                <p>Bidexpro does not charge deposit fees.</p>
                <p>For each Withdrawal, a flat fee is paid by users to cover the transaction costs of moving the cryptocurrency out of their Bidexpro account. Withdrawals rates are determined by the blockchain network and can fluctute without notice due to factors such as network congestion. Please check the most recent data listed on each withdrawal page.</p>
                
               <div className='table-responsive'>

               
                <table class="table">
                  <thead>
                    <tr>
                      <th>Coin/Token</th>
                      <th>Full Name</th>
                      <th>Network</th>
                      <th>Minimum Withdrawal</th>
                      <th>Deposit Fee</th>
                      <th>Withdrawal Fee</th>
                    </tr>
                  </thead>
   <tbody>
      <tr>
         <td scope="row">
         <div >
                        <img src="coin-icon/btc.png" className='img-fluid' alt="" />
            1INCH
            </div>
            </td>
         <td>1inch</td>
         <td>BNB Smart Chain (BEP20) Ethereum (ERC20)</td>
         <td>0.3236</td>
         <td>Free Free</td>
         <td>01618</td>
      </tr>
      <tr>
         <td scope="row">
         <div >
                        <img src="coin-icon/btc.png" className='img-fluid' alt="" />
            1INCH
            </div>
         </td>
         <td>1inch</td>
         <td>BNB Smart Chain (BEP20) Ethereum (ERC20)</td>
         <td>0.3236</td>
         <td>Free Free</td>
         <td>01618</td>
      </tr>
      <tr>
         <td scope="row">
         <div >
                        <img src="coin-icon/btc.png" className='img-fluid' alt="" />
            1INCH
            </div>
         </td>
         <td>1inch</td>
         <td>BNB Smart Chain (BEP20) Ethereum (ERC20)</td>
         <td>0.3236</td>
         <td>Free Free</td>
         <td>01618</td>
      </tr>
      <tr>
         <td scope="row">
         <div >
                        <img src="coin-icon/btc.png" className='img-fluid' alt="" />
            1INCH
            </div>
         </td>
         <td>1inch</td>
         <td>BNB Smart Chain (BEP20) Ethereum (ERC20)</td>
         <td>0.3236</td>
         <td>Free Free</td>
         <td>01618</td>
      </tr>
      <tr>
         <td scope="row">
         <div >
                        <img src="coin-icon/btc.png" className='img-fluid' alt="" />
            1INCH
            </div>
         </td>
         <td>1inch</td>
         <td>BNB Smart Chain (BEP20) Ethereum (ERC20)</td>
         <td>0.3236</td>
         <td>Free Free</td>
         <td>01618</td>
      </tr>
      <tr>
         <td scope="row">
         <div >
                        <img src="coin-icon/btc.png" className='img-fluid' alt="" />
            1INCH
            </div>
         </td>
         <td>1inch</td>
         <td>BNB Smart Chain (BEP20) Ethereum (ERC20)</td>
         <td>0.3236</td>
         <td>Free Free</td>
         <td>01618</td>
      </tr>
      <tr>
         <td scope="row">
         <div >
                        <img src="coin-icon/btc.png" className='img-fluid' alt="" />
            1INCH
            </div>
         </td>
         <td>1inch</td>
         <td>BNB Smart Chain (BEP20) Ethereum (ERC20)</td>
         <td>0.3236</td>
         <td>Free Free</td>
         <td>01618</td>
      </tr>
      <tr>
         <td scope="row">
         <div >
                        <img src="coin-icon/btc.png" className='img-fluid' alt="" />
            1INCH
            </div>
         </td>
         <td>1inch</td>
         <td>BNB Smart Chain (BEP20) Ethereum (ERC20)</td>
         <td>0.3236</td>
         <td>Free Free</td>
         <td>01618</td>
      </tr>
      <tr>
         <td scope="row">
         <div >
                        <img src="coin-icon/btc.png" className='img-fluid' alt="" />
            1INCH
            </div>
         </td>
         <td>1inch</td>
         <td>BNB Smart Chain (BEP20) Ethereum (ERC20)</td>
         <td>0.3236</td>
         <td>Free Free</td>
         <td>01618</td>
      </tr>
   </tbody>
</table>
</div>
                </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}
