import React from "react";
import Footer from './Footer';
import { Link } from "react-router-dom";

function About(){
    return(
        <>
             <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/"> <i className="fa fa-angle-left"></i> </Link>
                                    About Us
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             <div className="py-5">
             <div className="container">
            <div className="row align-items-center">
                <div className="col-md-6">
                    <div className="about__content aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                        <h6 className="span_about">Our Philosophy</h6>
                        <h3 className="heading1 ">We Are Always Thinking In Global</h3>
                        <p className="fs-20 decs">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </p>
                        <p className="fs-20 decs">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                        </p>
                       
                        <a href="#" className="btn btn-primary">Explore More</a>
                        </div>
                </div>
                <div className="col-md-6">
                    <div className="about_img">
                        <img src="web/about-h1.png" className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
            </div>
           
             </div>           
           
             <section className="work">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="block-text text-center">
              <h3 className="heading1">Why choose Bidexpro Exchange?</h3>
              {/* <p className="desc">
              Why choose Bidexpro Exchange?
              </p> */}
            </div>

            <div className="work__main">
              <div className="work-box">
                <div className="image">
                  <img src="web/Cloud.png" alt="" />
                </div>
                <div className="content">
              
                  <a href="#" className="title">Quick deposits, quick withdrawals</a>
                  <p className="text">
                  Over a dozen of superb options for depositing and withdrawing money such as VISA, MasterCard, WebMoney, etc.
                  </p>
                </div>
                <img className="line" src="web/connect-line.png" alt="" />
              </div>
              <div className="work-box">
                <div className="image">
                  <img src="web/Wallet.png" alt="" />
                </div>
                <div className="content">
                  
                  <a href="#" className="title">Flawless customer experience</a>
                  <p className="text">
                  Bidexpro Exchange is proud of its Support Team that addresses any problem in just 46 sec 24/7/365
                  </p>
                </div>
                <img className="line" src="web/connect-line.png" alt="" />
              </div>
              <div className="work-box">
                <div className="image">
                  <img src="web/Mining.png" alt="" />
                </div>
                <div className="content">
                  
                  <a href="#" className="title">Comfortable trading</a>
                  <p className="text">
                  Bidexpro Exchange offers Web, Desktop, iOS and Android apps, so you can trade Crypto assets whenever wherever!
                  </p>
                </div>  
              </div>
              
            </div>


            <div className="work__main">
              <div className="work-box">
                <div className="image">
                  <img src="web/Cloud.png" alt="" />
                </div>
                <div className="content">
              
                  <a href="#" className="title">Short selling</a>
                  <p className="text">
                  Short selling a digital asset allows a trader to profit from a market move in any direction
                  </p>
                </div>
                <img className="line" src="web/connect-line.png" alt="" />
              </div>
              <div className="work-box">
                <div className="image">
                  <img src="web/Wallet.png" alt="" />
                </div>
                <div className="content">
                  
                  <a href="#" className="title">Reliable, high-liquidity platform</a>
                  <p className="text">
                  Bidexpro Exchange is a licensed and regulated broker, with a monthly trading volume of more than $11B
                  </p>
                </div>
                <img className="line" src="web/connect-line.png" alt="" />
              </div>
              <div className="work-box">
                <div className="image">
                  <img src="web/Mining.png" alt="" />
                </div>
                <div className="content">
                  
                  <a href="#" className="title">Comfortable trading</a>
                  <p className="text">
                  Bidexpro Exchange offers Web, Desktop, iOS and Android apps, so you can trade Crypto assets whenever wherever!
                  </p>
                </div>  
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>



    <section className="window">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-12">
            <div className="block-text text-center">
              <h3 className="heading1">The Way Cryptocurrency is Being Used Today</h3>
            </div>


            <ul className="nav nav-tabs border-bottom-0 justify-content-center mt-5" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Window</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Dell</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Expedia</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link" id="overstock-tab" data-bs-toggle="tab" data-bs-target="#overstock" type="button" role="tab" aria-controls="overstock" aria-selected="false">Overstock</button>
            </li>
            </ul>
            <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div className="t_content text-center mt-5 text-white w-50 m-auto">
                    <h3>Microsoft</h3>
                    <p className="fs-6">Having formed a partnership with BitPay, Microsoft started to accept Bitcoins as payments for their digital content, including their Xbox games and subscriptions.</p>
                </div>
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <div className="t_content text-center mt-5 text-white w-50 m-auto">
                    <h3>Dell</h3>
                    <p className="fs-6">One of the largest PC and server manufacturers has announced in 2016 that they’d be accepting cryptocurrencies for purchases made through Dell.com. Customers were even promised a 10% discount on the entire product line-up if they were to pay with Bitcoin - an offer that’s hard to turn down.</p>
                </div>
            </div>
            <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                <div className="t_content text-center mt-5 text-white w-50 m-auto">
                    <h3>Expedia</h3>
                    <p className="fs-6">This global travel company accepts Bitcoin payments for their package tours.</p>
                </div>
            </div>

            <div className="tab-pane fade" id="overstock" role="tabpanel" aria-labelledby="overstock-tab">
                <div className="t_content text-center mt-5 text-white w-50 m-auto">
                    <h3>Overstock</h3>
                    <p className="fs-6">This retail giant was among the first to start accepting Bitcoin payments in partnership with Coinbase. Beginning November 2013, Overstock customers can use Bitcoins to purchase clothes, shoes, household goods, kids apparel, electronics, jewelry, watches and much more.</p>
                </div>
            </div>
            </div>
            

            
          </div>
        </div>
      </div>
    </section>

    <Footer />

        </>
    )
}

export default About;