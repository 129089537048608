import React from 'react'
import { Link } from "react-router-dom";
export default function Security() {
  return (
    <div>
      <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <a href="/" className="text-white"><i className="fa fa-angle-left m-0"></i></a> &nbsp;
                                Security  
                                </h3>
                            </div>

                            <ul className="security">
                                <li><i className="fa fa-check"></i> <a href="#">Two-Factor Authentication (2FA)</a> </li>
                                <li><i className="fa fa-check"></i> <a href="#">Identity Verification</a> </li>
                                <li><i className="fa fa-times"></i> <a href="#">Anti-Phishing Code</a> </li>
                                <li><i className="fa fa-times"></i> <a href="#">Withdrawal Whitelist</a> </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>

            <section>
                <div className='container'>
                    <div className='row'>

                    <div className="col-md-12">
                            <div className="box_heading pb-3">
                                <h2>Profile</h2>
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div className='card pro_file'>
                                <div className="card-body">
                                    <div className='row align-items-center  '>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label className='form-label text-white'>Name</label>
                                                <input type="text" className='form-control' placeholder='Name'  />
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                    <label className='form-label text-white'>Email</label>
                                                    <input type="email" className='form-control' placeholder='Email'  />
                                                </div>
                                        </div>
                                        <div className='col-md-3'>
                                                <div className='form-group'>
                                                    <label className='form-label text-white'>Phone</label>
                                                    <input type="text" className='form-control' placeholder='Phone'  />
                                                </div>
                                        </div>
                                        <div className='col-md-3'>
                                                <div className='form-group'>
                                                    <button className='btn btn-primary mt-1' data-bs-toggle="modal" data-bs-target="#exampleModal">Change</button>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="box_heading pb-3">
                                <h2>Two-Factor Authentication (2FA)</h2>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='campus_box'>
                            <table className="table security1">
                                  
                                  <tbody>
                                      <tr>
                                      <td scope="row">
                                        <div>
                                            <h6>Passkeys and Biometrics</h6>
                                            <p>Protect your account and withdrawals with a security key such as Yubikey</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                            <i className='fa fa-times'></i>
                                            not linked
                                        </div>
                                      </td>
                                      <td>
                                            <Link to="#" className="btn btn-secondary float-end">Enable</Link>
                                      </td>
                                      </tr>

                                      <tr>
                                      <td scope="row">
                                        <div>
                                            <h6>Google Authenticator (Recommended)</h6>
                                            <p>Protect your account and transaction.</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                            <i className='fa fa-times'></i>
                                            not linked
                                        </div>
                                      </td>
                                      <td>
                                            <Link to="#" className="btn btn-secondary float-end">Enable</Link>
                                      </td>
                                      </tr>

                                      <tr>
                                      <td scope="row">
                                        <div>
                                            <h6>Phone Number Verification</h6>
                                            <p>Protect your account and transaction.</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                            <i className='fa fa-check'></i>
                                            999****777
                                        </div>
                                      </td>
                                      <td>
                                      <Link to="#" className="btn btn-secondary ms-3 float-end">Change</Link>
                                            <Link to="#" className="btn btn-secondary float-end">Remove</Link>
                                      </td>
                                      </tr>
                                      <tr>
                                      <td scope="row">
                                        <div>
                                            <h6>Email Address Verification</h6>
                                            <p>Protect your account and transactions.</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                            <i className='fa fa-times'></i>
                                            j.***@gmail.com
                                        </div>
                                      </td>
                                      <td>
                                            <Link to="#" className="btn btn-secondary float-end">Enable</Link>
                                      </td>
                                      </tr>
                                  </tbody>
                                  </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="box_heading pb-3">
                                <h2>Advanced Security</h2>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='campus_box'>
                            <table className="table security1">
                                  
                                  <tbody>
                                      <tr>
                                      <td scope="row">
                                        <div>
                                            <h6>Login</h6>
                                            <p>Login Password is used to log in to your account</p>
                                        </div>
                                      </td>
                                      <td colSpan="2">
                                            <Link to="#" className="btn btn-secondary float-end" data-bs-toggle="modal" data-bs-target="#exampleModal1">Change</Link>
                                      </td>
                                      </tr>

                                      {/* <tr>
                                      <td>
                                        <div>
                                            <h6>Account Connections</h6>
                                            <p>Use a third-party account. such as your Apple ID or Google account to log in to your Bidexpro account.</p>
                                        </div>
                                      </td>
                                      <td colSpan="2">
                                            <Link to="#" className="btn btn-secondary float-end">Manage</Link>
                                      </td>
                                      </tr> */}

                                      {/* <tr>
                                      <td scope="row">
                                        <div>
                                            <h6>Crypto Withdrawal</h6>
                                            <p>When this function is turned on. You can withdraw small amount crypto to whitelisted addresses without passing 2FA verification. </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                            <i className='fa fa-times'></i>
                                            OFF
                                        </div>
                                      </td>
                                      <td>
                                      <Link to="#" className="btn btn-secondary float-end">Enable</Link>
                                      </td>
                                      </tr> */}
                                      {/* <tr>
                                      <td scope="row">
                                        <div>
                                            <h6>Anti-Phishing Code</h6>
                                            <p>Protect your account from phishing attempts and ensure that your notification emails are from Bidexpro Only.</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                            <i className='fa fa-times'></i>
                                            OFF
                                        </div>
                                      </td>
                                      <td>
                                            <Link to="#" className="btn btn-secondary float-end">Enable</Link>
                                      </td>
                                      </tr> */}
                                  </tbody>
                                  </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="box_heading pb-3">
                                <h2>Devices and Activities</h2>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='campus_box'>
                            <table className="table security1">
                                  
                                  <tbody>
                                      {/* <tr>
                                      <td scope="row">
                                        <div>
                                            <h6>Device Management</h6>
                                            <p>Manage devices allowed to access your account.</p>
                                        </div>
                                      </td>
                                      <td colSpan="2">
                                            <Link to="#" className="btn btn-secondary float-end">Manage</Link>
                                      </td>
                                      </tr> */}

                                      <tr>
                                      <td>
                                        <div>
                                            <h6>Account Activity</h6>
                                            <p>Last Login: 2023-05-22 12:03:35</p>
                                        </div>
                                      </td>
                                      <td colSpan="2">
                                            <Link to="#" className="btn btn-secondary float-end">More</Link>
                                      </td>
                                      </tr>
                                  </tbody>
                                  </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            



<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered    ">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Profile</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <form action="">
            <div className='form-group'>
                <label className='form-label'>Name</label>
                <input type="text"  className='form-control' />
            </div>
            <div className='form-group'>
                <label className='form-label'>Email</label>
                <input type="email"  className='form-control' />
            </div>
            <div className='form-group'>
                <label className='form-label'>Phone</label>
                <input type="text"  className='form-control' />
            </div>
            <div className='form-group mt-3'>
                <button className="w-100 btn btn-primary">Change</button>
            </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered    ">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Login</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <form action="">
            <div className='form-group'>
                <label className='form-label'>Name</label>
                <input type="text"  className='form-control' />
            </div>
            <div className='form-group'>
                <label className='form-label'>Email</label>
                <input type="email"  className='form-control' />
            </div>
            <div className='form-group'>
                <label className='form-label'>Phone</label>
                <input type="text"  className='form-control' />
            </div>
            <div className='form-group mt-3'>
                <button className="w-100 btn btn-primary">Change</button>
            </div>
        </form>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
