import React from 'react'
import { Link } from "react-router-dom";


export default function Wallet() {
  return (
    <div>
         <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/" className="text-white"><i className="fa fa-angle-left m-0"></i></Link> &nbsp;
                                Wallet  
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="inner_box">
            <div className="container">
                <div className="row">
                <div className="col-md-12">

                        <div className="wallet_top float-end mt-5">
                            <div>
                                <ul className='d-flex'>
                                    <li><Link to="/deposit" className='btn'>Deposit</Link></li>
                                    <li><Link to="/cryptowithdrawl" className='btn'>Withdraw</Link></li>
                                    <li><a href="#" className='btn'>Transfer</a></li>
                                    <li><Link to="/transctionhistory" className='btn'>Transction History</Link></li>
                                </ul>
                            </div>

                        </div>

                        </div>

                <div className='col-md-12 table-responsive'>
                    <div className='estimate_balance'>
                        <div className='row'>
                            <div className='col-md-4 col-6'>
                                <div className='estimated_b'>
                                    <div >
                                        <h5>Estimated Balance <i className='fa fa-eye'></i></h5>
                                    </div>
                                    <h4>0.00662077 BTC <span>= $177.67 </span></h4>
                                </div>
                            </div>

                            <div className='col-md-3 col-6'>
                                <div className='estimated_b'>
                                    <div >
                                        <h6>Spot Balance</h6>
                                    </div>
                                    <h5>0.00662077 BTC <br /> <span>= $177.67 </span></h5>
                                </div>
                            </div>
                            <div className='col-md-3 col-6'>
                            <div className='estimated_b'>
                                    <div >
                                        <h6>Fiat Balance</h6>
                                    </div>
                                    <h5>0.00662077 BTC <br /> <span>= $177.67 </span></h5>
                                </div>
                            </div>
                            <div className='col-md-2 col-6'>
                                <div className='estimated_b'>
                                        <div >
                                            <h6 className='red'>Today's PNL</h6>
                                        </div>
                                        <h5 className='green'>+$1.34 <br /> <span>+0.76% </span></h5>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                        <div className="table-reponsive box">
<table id="example2" className="table table-striped table-bordered">
        <thead>
            <tr>
                <th>Coin</th>
                <th>Total</th>
                <th>Available</th>
                <th>In Order</th>
                <th>BTC Value</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>

            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>

            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>

            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>

            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>

            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>

            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>

            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>

            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>

            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>
            <tr>
                <td>XRP</td>
                <td>203.594000000</td>
                <td>203.594000000</td>
                <td>0.4000000</td>
                <td>
                    0.00350589 <br />
                    <span>=$94.08</span>
                </td>
                <td>
                    <div className='d-flex justify-content-around'> 
                    <a href="#" className='btn' >Trade</a>
                        <a href="#" className='btn' >Deposit</a>
                        <a href="#" className='btn' >Withdraw</a>
                    </div>
                </td>
            </tr>

            
            
        </tbody>
    </table>
</div>
                </div> 
            </div>
        </div>

    </div>
  )
}
