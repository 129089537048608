import React,{useState} from "react";
import { Link } from "react-router-dom";
import CoinLists from "./CoinLists"
import NetworkList from "./NetworkList"

function Deposit(props) {

  return (
    <>
      <div className="wrapper">
        <div className="Login_form">
          <div className="container">
            <div className="row">
              <div className="offset-md-3 col-md-6">
                <div className="form-inner1">
                  <div className="row align-items-center">
                    <div className="col-md-12" id="signup1">
                      <div className="form-section">
                        <div className="form-inner">
                          {/* <a href="login-1.html" className="logo">
                            <img src="assests/logo.png" alt="logo" />
                        </a> */}
                          <h1 className="text-center">Crypto Deposit</h1>

                          <div className="extra-login form-group clearfix">
                            <span>Or</span>
                          </div>

                          <form action="#" method="GET">
                            <div className="form-group form-box clearfix">
                  
                            <label className="form-label">Coin</label>

                              <input
                                name="email"
                                type="email"
                                className="form-control mb-1"
                                placeholder={props.coinSymbolName}
                                disabled
                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                              />
                              <i className="fa fa-caret-down care text-white" data-bs-toggle="modal" data-bs-target="#exampleModal" aria-hidden="true"></i>
                              
                            

                           <span className="p-2 text-start text-warning warning-size">{props.coinSelectWarning}</span>
                          
                            </div>
                            <div className="form-group form-box clearfix mb-0">
                            
                              <label className="mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal1">Select Network</label>
                              <div className="form-group">
                              <input
                                name="email"
                                type="email"
                                className="form-control mb-1"
                                placeholder={props.coinNetwork}
                                disabled
                                data-bs-toggle="modal" data-bs-target="#exampleModal1"
                              />
                                <i className="fa fa-caret-down care text-white" data-bs-toggle="modal" data-bs-target="#exampleModal" aria-hidden="true"></i>
                           <span className="p-2 text-start text-warning warning-size">{props.networkSelectWarning}</span>

                              </div>
                            </div>

                            <div className="form-group form-box clearfix">
                              
                              <label className="mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal1">Enter Amount</label>
                              <div className="form-group">
                              <input
                                name="email"
                                type="text"
                                className="form-control mb-1"
                                placeholder="Enter Amount"
                                onChange={props.handlerInputAmount}
                              />
                                <span className="p-2 text-start text-warning warning-size">{props.inputEnterWarning}</span>
                                <h6 className="text-danger">{props.inputAmountWarning}</h6>
                              </div>
                            </div>

                            <div className="row mb-4">
                              <div
                                className="col-md-4 col-6"
                                onClick={props.handleConnectMetamask}
                              >
                                <a
                                  href="#"
                                  className="btn_meta"
                                >
                                  <img
                                    src="assets/metamask.png"
                                    className="img-fluid"
                                  />{" "}
                                  Metamask
                                </a>
                              </div>
                              <div
                                className="col-md-4 col-6"
                                onClick={props.handleConnectTrustWallet}
                              >
                                <a
                                  href="#"
                                  className="btn_wallet"
                                >
                                  <img
                                    src="assets/trustwallet.png"
                                    className="img-fluid"
                                  />{" "}
                                  Trustwallet
                                </a>
                              </div>
                              <div className="col-md-4 col-6" 
                              
                              onClick={props.handleConnectTronWallet}>
                                <a
                                  href="#"
                                  className="btn_link"
                                >
                                  <img
                                    src="assets/tronlink.png"
                                    className="img-fluid"
                                   
                                  />{" "}
                                  Tronlink
                                </a>
                              </div>
                            </div>

                            {props.networkConnectWarning?
                            <>

                           <h5 className="bg-dark p-3 rounded fs-6 text-warning">{props.networkConnectWarning}</h5>
                           </> 
                           : <></> 

                            }

                            
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-5 d-none">
                      <div className="qr_box">
                        <h3>Scan to log in instantly</h3>
                        <img src="assests/qr.png" className="img-fluid" />
                      </div>
                      <ul className="list-unstyled text-white">
                        <li>1. Scan the above QR code</li>
                        <li>2. Tap Menu/Settings QR Code Scanner</li>
                        <li>3. Scan the above QR code</li>
                      </ul>
                    </div>

                  

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
{/* Modal */}


<div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Select Coin</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body p-0">
        <div className="coin_body">
          
            <ul className="my-4">

              <li>
                <div className="form-group px-3 mb-2">
                    <input type="text"  className="form-control" placeholder="Search Coin"/>
                </div>
              </li>

              <ul className="market-trade-usdt text-center">
                                <li   data-bs-dismiss="modal" aria-label="Close" onClick={()=>{props.handlertokenname("BNB")}}>
                                  <a href="#!">
                                    <img
                                      src="coin-icon/bnb.png"
                                      className="img-fluid"
                                    />
                                    BNB
                                  </a>
                                </li>
                                <li  data-bs-dismiss="modal" aria-label="Close" onClick={()=>{props.handlertokenname("TRX")}}>
                                  <a href="#!">
                                    <img
                                      src="coin-icon/trx.png"
                                      className="img-fluid"
                                    />
                                    TRX
                                  </a>
                                </li>
                                <li  data-bs-dismiss="modal" aria-label="Close" onClick={()=>{props.handlertokenname("ETH")}}>
                                  <a href="#!">
                                    <img
                                      src="coin-icon/eth.png"
                                      className="img-fluid"
                                    />
                                    ETH
                                  </a>
                                </li>
                                <li>
                                  <a href="#!">
                                    <img
                                      src="coin-icon/btc.png"
                                      className="img-fluid"
                                    />
                                    BTC
                                  </a>
                                </li>
                              </ul>
                {props.apiData.map((coin, index) => (
                        <>

                        <li  key={index} data-bs-dismiss="modal" aria-label="Close" onClick={()=>{props.handlerSetSymbol(coin)}}>        
                    <Link to="#">
                  <img  src={coin.logoURI} alt="" className="img-fluid" />
                  <div  >
                      <h6 className="m-0">{coin.symbol}</h6>
                      <p className="m-0"> {coin.name}</p>
                  </div>
                  </Link>
                          </li>

                        </>
                      ))}
              {/* <li>        
                <Link to="#">
                  <img src="coin-icon/bnb.png" alt="" className="img-fluid" />
                  <div>
                      <h6 className="m-0">BNB</h6>
                      <p className="m-0"> Bidexpro Chain Native Token</p>
                  </div>
                  </Link>
              </li>

              <li>
              <Link to="#">
                  <img src="coin-icon/trx.png" alt="" className="img-fluid" />
                  <div>
                      <h6 className="m-0">TRX</h6>
                      <p className="m-0"> Bidexpro Chain Native Token</p>
                  </div>
                  </Link>
              </li>

              <li>
              <Link to="#">
                  <img src="coin-icon/usdt.png" alt="" className="img-fluid" />
                  <div>
                      <h6 className="m-0">USDT</h6>
                      <p className="m-0"> Bidexpro Chain Native Token</p>
                  </div>
                  </Link>
              </li>
              <li>
              <Link to="#">
                  <img src="coin-icon/maker.png" alt="" className="img-fluid" />
                  <div>
                      <h6 className="m-0">MAKER</h6>
                      <p className="m-0"> Bidexpro Chain Native Token</p>
                  </div>
                  </Link>
              </li>
              <li>
              <Link to="#">
                  <img src="coin-icon/aurora.png" alt="" className="img-fluid" />
                  <div>
                      <h6 className="m-0">Aurora</h6>
                      <p className="m-0"> Bidexpro Chain Native Token</p>
                  </div>
                  </Link>
              </li>
              <li>
              <Link to="#">
                  <img src="coin-icon/eth.png" alt="" className="img-fluid" />
                  <div>
                      <h6 className="m-0">ETH</h6>
                      <p className="m-0"> Bidexpro Chain Native Token</p>
                  </div>
                  </Link>
              </li>
              <li>
              <Link to="#">
                  <img src="coin-icon/0x.png" alt="" className="img-fluid" />
                  <div>
                      <h6 className="m-0">0X</h6>
                      <p className="m-0"> Bidexpro Chain Native Token</p>
                  </div>
                  </Link>
              </li>
              <li>
              <Link to="#">
                  <img src="coin-icon/omisego.png" alt="" className="img-fluid" />
                  <div>
                      <h6 className="m-0">Omisego</h6>
                      <p className="m-0"> Bidexpro Chain Native Token</p>
                  </div>
                  </Link>
              </li> */}
            </ul>
        </div>
      </div>
    </div>
  </div>
</div>


<div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Select Network</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body p-0">
        <div className="coin_body">
            <ul className="my-4">

            <li>
                <p className="px-3 text-white">Ensure the Network you choose to deposit matches the  withdrawal network, or assets may be lost.</p>
              </li>


{/*  */}

{NetworkList.map((Network, index) => (
                        <>
                      <li key={index} data-bs-dismiss="modal" aria-label="Close" onClick={()=>{props.handlerSetCoinNetwork(Network)}} >
                <Link to="#">
                  <div>
                      <h6 className="m-0">{Network.NetworkSymbol}</h6>
                      <p className="m-0">{Network.NetworkName}</p>
                  </div>
                  </Link>
              </li>

                        </>
                      ))}
            
            </ul>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  );
}

export default Deposit;
