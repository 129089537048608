// import logo from './logo.svg';
// import './Login.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import Web3 from "web3";

//json abi
import contractABI from "../src/Abijson/ABI.json";
import erc20ABI from "../src/Abijson/erc20.json";
import paymentContractNileabi from "../src/Abijson/paymentContractNILEABI.json";
import trc20AbiNile from "../src/Abijson/trc20_abi_NILE.json";
//pages
import dashboard from "../src/css/dashboard/dashboard.css";
import login from "../src/css/login-css/Login.css";
import responsive from "../src/css/web-css/responsive.css";
import inner from "../src/css/web-css/inner.css";
import Signup from "./Dashboardpages/Signup/Signup";
import Layout from "./Layout/Layout";
import Exchange from "./Dashboardpages/Exchange/Exchange";
import Login from "./Dashboardpages/Login/Login";
import Deposit from "./Dashboardpages/Deposit/Deposit";
import Launchpad from "./Dashboardpages/Launchpad/Launchpad";
import OrderHistory from "./Dashboardpages/OrderHistory/OrderHistory";
import Launchpaddetail from "./Dashboardpages/Launchpad-detail/LaunchpadDetail";
import Profile from "./Dashboardpages/Profile/Profile";
import Security from "./Dashboardpages/Security/Security";
import About from "./Webpages/Aboutus";
import TermsCondition from "./Webpages/TermsCondition";
import Career from "./Webpages/Career";
import ReferralProgram from "./Webpages/ReferralProgram";
import Fee from "./Webpages/Fee";
import Home from "./Webpages/Home";
import Warning from "./Webpages/Warning";
import Privacy from "./Webpages/Privacy";
import News from "./Webpages/News";
import Feedback from "./Webpages/Feedback";
import Trade from "./Webpages/Trade";
import Affilate from "./Webpages/Affilate";
import Market from "./Webpages/Market";
import MarketDetail from "./Webpages/MarketDetail";
import Listing from "./Webpages/Listing";
import CryptoWithdrawl from "./Webpages/CryptoWithdrawl";
import LIstingwizard from "./Dashboardpages/ListingWizard/LIstingwizard";
import TransactionHistory from "./Dashboardpages/TransactionHistory/TransactionHistory";
import Wallet from "./Dashboardpages/Wallet/Wallet";
import MyReferral from "./Dashboardpages/MyReferrals/MyReferral";
import Nopage from "./Webpages/Nopage";
import WithdrawFee from "./Dashboardpages/WithdrawFee/WithdrawFee";

function App() {
  var requiredAmount;
  var networkId;
  const [account, setAccount] = useState("");
  const [apiData, setApiData] = useState([]);
  const [web3, setWeb3] = useState("");
  const [paymentContract, setPaymentContract] = useState("");
  const [erc20Contract, setErc20Contract] = useState("");
  const [coinSymbolName, setCoinSymbolName] = useState("Select Coin");
  const [coinNetwork, setCoinNetwork] = useState("Select Network");
  const [ercAddressForBnb, setErcAddressForBnb] = useState("");
  const [inputAmount, setInputAmount] = useState(0);
  const [inputAmountWarning, setInputAmountWarning] = useState();
  const [networkConnectWarning, setNetworkConnectWarning] = useState();
  const [coinSelectWarning, setCoinSelectWarning] = useState();
  const [networkSelectWarning, setNetworkSelectWarning] = useState();
  const [inputEnterWarning, setInputEnterWarning] = useState();
  const contractAddressForBnb = "0xf1355160aa0c99F2A3bB416851553D10304D70ee"; // bnb mainnet contract address

  // const to = "0x25a0cF1E232B789473e8bCeBb2fD4a9b8Fc9b321";
  //const ercAddressForEth = "0xd0570f9032Cb45a2499F2a782c1417aE7B5c8174"; //for mainnet
  // const ercAddressForEth = "0x19e526E038A46B6D4e3c4168010e150768cF78B6"; //for testnet
  const ercAddressForEth = "0x138DaA210718D45990f049BFBc98187818A5c04e"; //Ethereum goerli testnet
  const contractAddressForEth = "0x67E90d67E3197Be790d5dF86Bf77333EB0eA177E"; // Ethereum Eth goerli testnet address

  //connect-wallets
  //====================================================================================================================
  const connectWallet = async (walletType) => {
    setNetworkConnectWarning();
    try {
      var provider;

      if (walletType === "trustwallet") {
        if (window.ethereum && window.ethereum.isTrust) {
          // Connect to Trust Wallet
          provider = window.ethereum;
          // const isConnected = await window.ethereum.isConnected();
        } else {
          console.log(
            "Please install the Trust Wallet browser extension to Connect the Trust wallet. "
          );
          setNetworkConnectWarning(
            "Please install the Trust Wallet browser extension to Connect the Trust wallet. "
          );
          return;
        }
      } else if (walletType === "metamask") {
        if (window.web3 && window.web3.currentProvider) {
          // Connect to Metamask
          provider = window.web3.currentProvider;
        } else {
          console.log("Metamask Not Found Please Download Metamask Wallet");
          setNetworkConnectWarning(
            "Please install the Metamask  Wallet browser extension to Connect the Metamask wallet. "
          );

          return;
        }
      } else {
        console.log("Invalid wallet type");
        return;
      }
      const web3 = new Web3(provider);
      setWeb3(web3);
      await provider.enable();
      const accounts = await web3.eth.getAccounts();
      const selectedAccount = accounts[0];
      console.log(` ${walletType} selected account is --> ${selectedAccount}`);
      setAccount(selectedAccount);

      networkId = await web3.eth.net.getId();
      const networkName = await web3.eth.net.getNetworkType();
      console.log("network id " + networkId);
      console.log("network name  " + networkName);
      if (selectedAccount) {
        setNetworkConnectWarning(`${walletType} wallet connected` );
      }

      if (inputAmount === 0) {
        setInputEnterWarning("Enter Amount");
      }
      if (coinNetwork === "Select Network") {
        setNetworkSelectWarning("Select Network");
      }
      if (coinSymbolName === "Select Coin") {
        setCoinSelectWarning("Select Coin");
      }

      //===================================================================================================
      //Binance network session ------> ------> ------>
      //===================================================================================================

      //bnb condtion first  ------------->
      if (coinSymbolName === "BNB") {
        if (
          coinNetwork === "BNB Beacon Coin (BEP20)" ||
          coinNetwork === "BNB Smart Chain (BEP20)"
        ) {
          if (networkId == 56) {
            if (inputAmount <= 0) return;

            const handlereth = async () => {
              console.log("run depositETH ........for Binance network ");
              console.log("coin symbol name : " + coinSymbolName);
              console.log("input amount" + inputAmount);

              let myString = inputAmount.toString();
              console.log("input amount converted to string :  " + myString);
              requiredAmount = web3.utils.toWei(myString, "ether");

              console.log(" input amount change into ToWei : ");
              try {
                const paymentContract = new web3.eth.Contract(
                  contractABI,
                  contractAddressForBnb
                );
                setPaymentContract(paymentContract);

                const result = await paymentContract.methods.depositETH().send({
                  from: account,
                  value: requiredAmount,
                });

                console.log("ETH deposited successfully", result);
                if (result) {
                  setNetworkConnectWarning("Deposit Successful!");
                }
              } catch (error) {
                console.error("Failed to deposit ETH", error);
              }
            };

            handlereth();
          } else {
            console.log(
              "please connect to Binance network for run this contract "
            );
            setNetworkConnectWarning("Please switch to the Binance  network  ");
          }
        }
      }
      //bnb condtion second ------------->
      if (coinSymbolName !== "BNB" && coinSymbolName !== "ETH") {
        if (coinSymbolName !== "TRX" && coinSymbolName !== "BTC") {
          if (
            coinNetwork === "BNB Beacon Coin (BEP20)" ||
            coinNetwork === "BNB Smart Chain (BEP20)"
          ) {
            if (networkId == 56) {
              if (inputAmount <= 0) return;

              const handlererc20 = async () => {
                console.log("run depositERC20 for Binance network...");

                console.log("input amount  " + inputAmount);
                let myString = inputAmount.toString();
                console.log("input amount converted to string  :  " + myString);
                requiredAmount = web3.utils.toWei(myString, "ether");
                // const amount =requiredAmount  ;
                console.log(
                  "input amount esc20 from get require amount" + requiredAmount
                );

                try {
                  const erc20Contract = new web3.eth.Contract(
                    erc20ABI,
                    ercAddressForBnb
                  );
                  setErc20Contract(erc20Contract);

                  const paymentContract = new web3.eth.Contract(
                    contractABI,
                    contractAddressForBnb
                  );
                  setPaymentContract(paymentContract);

                  const result = await erc20Contract.methods
                    .approve(contractAddressForBnb, requiredAmount)
                    .send({
                      from: account,
                    });

                  const result1 = await paymentContract.methods
                    .depositERC20(ercAddressForBnb, requiredAmount)
                    .send({
                      from: account,
                    });

                  console.log("ERC20 tokens deposited successfully", result1);
                  if (result1) {
                    setNetworkConnectWarning("Deposit Successful!");
                  }
                } catch (error) {
                  console.error("Failed to deposit ERC20 tokens", error);
                }
              };

              handlererc20();
            } else {
              console.log(
                "please connect to Binance network for run this contract "
              );
              setNetworkConnectWarning("Please switch to the Binance  network  ");
            }
          }
        }
      }

      //===================================================================================================
      //Ethereum network session ------> -----> ------>
      //===================================================================================================

      //ETH condition first ----->
      if (coinSymbolName === "ETH") {
        if (coinNetwork === "Ethereum (ERC20)") {
          if (networkId == 5) {
            if (inputAmount <= 0) return;
            const handlereth = async () => {
              console.log("run depositETH ........for ethereum network");
              console.log("coin symbol name : " + coinSymbolName);
              console.log("input amount" + inputAmount);

              let myString = inputAmount.toString();
              console.log("input amount converted to string :  " + myString);
              requiredAmount = web3.utils.toWei(myString, "ether");

              console.log(" input amount change into ToWei : ");
              try {
                //contract connect ---> ---> ---> 
                const paymentContract = new web3.eth.Contract(
                  contractABI,
                  contractAddressForEth
                );
                setPaymentContract(paymentContract);

                // Deposit ETH by sending a transaction to the depositETH function:--->

                const result = await paymentContract.methods.depositETH().send({
                  from: account,
                  value: requiredAmount,
                });
                console.log("ETH deposited successfully", result);
                if (result) {
                  setNetworkConnectWarning("Deposit Successful!");
                }
              } catch (error) {
                console.error("Failed to deposit ETH", error);
              }
            };

            handlereth();
          } else {
            console.log("please connect to Ethereum network  ");
            setNetworkConnectWarning("Please switch to the  Ethereum network  ");
          }
        }
      }
      //ETH condition second ----->
      if (coinSymbolName !== "ETH" && coinSymbolName !== "BNB") {
        if (coinSymbolName !== "TRX" && coinSymbolName !== "BTC") {
          if (coinNetwork === "Ethereum (ERC20)") {
            if (networkId == 5) {
              if (inputAmount <= 0) return;

              const handlererc20 = async () => {
                console.log("run depositERC20 ... for Ethereum network");

                console.log("input amount  " + inputAmount);
                let myString = inputAmount.toString();
                console.log("input amount converted to string  :  " + myString);
                requiredAmount = web3.utils.toWei(myString, "ether");
                // const amount =requiredAmount  ;
                console.log(
                  "input amount esc20 from get require amount" + requiredAmount
                );

                try {
                  const erc20Contract = new web3.eth.Contract(
                    erc20ABI,
                    ercAddressForEth
                  );
                  setErc20Contract(erc20Contract);

                  const paymentContract = new web3.eth.Contract(
                    contractABI,
                    contractAddressForEth
                  );
                  setPaymentContract(paymentContract);

                  const result = await erc20Contract.methods
                    .approve(contractAddressForEth, requiredAmount)
                    .send({
                      from: account,
                    });

                  const result1 = await paymentContract.methods
                    .depositERC20(ercAddressForEth, requiredAmount)
                    .send({
                      from: account,
                    });

                  console.log("ERC20 tokens deposited successfully", result1);
                  if (result1) {
                    setNetworkConnectWarning("Deposit Successful!");
                  }
                } catch (error) {
                  console.error("Failed to deposit ERC20 tokens", error);
                }
              };

              handlererc20();
            } else {
              console.log("please connect to Ethereum network  ");
              setNetworkConnectWarning("Please switch to the  Ethereum network  ");
            }
          }
        }
      }
    } catch (error) {
      console.log("Error connecting wallet:", error);
    }
  };

  const handleConnectMetamask = () => {
    connectWallet("metamask");
  };

  const handleConnectTrustWallet = () => {
    connectWallet("trustwallet");
  };

  //===================================================================================================
  //Tron wallet connect ------->
  //===================================================================================================\

  const handleConnectTronWallet = async () => {
    setNetworkConnectWarning();

    var trxNetworkType;
    console.log("tron wallet callled");
    try {
      setNetworkConnectWarning();
      // && window.tronWeb.ready
      if (window.tronWeb ) {
        if(window.tronWeb.ready){
        const currentAddress = await window.tronWeb.defaultAddress.base58;
        console.log("Connected to TronLink with address:", currentAddress);
        // const balance = await window.tronWeb.trx.getBalance(currentAddress);
        // console.log("Account balance:", balance);
        const account = await window.tronWeb.trx.getAccount(currentAddress);
        console.log("Account:", account);
        trxNetworkType = window.tronWeb.fullNode.host;
        console.log("TronLink network type:", trxNetworkType);
        if (account) {
          setNetworkConnectWarning(" Tronlink Wallet Connected ");
        }

        //===================================================================================================
        //Tron Trx session FOR tron wallet------> ------> ------>
        //===================================================================================================

        // trx  condition first ---->
        if (coinSymbolName === "TRX") {
          if (coinNetwork === "Tron(TRC20)") {
            if (trxNetworkType === "https://api.nileex.io") {
              if (inputAmount <= 0) return;
              console.log("run depositTRX()");
              
              async function depositTRX() {
                const trxContractAddress = "TBFtvPNscBEJHJNjLrAPcXej3FQ1dcn7vH";
                // const trxTokenAddress = 'TPpbbzvE2vgRkwDPZ1yCbZwctVfLwQdpc4';
                const trxContract = window.tronWeb.contract(
                  paymentContractNileabi,
                  trxContractAddress
                );
                // const trxtokenContract = window.tronWeb.contract(trc20AbiNile, trxTokenAddress);
                try {

                  const selectedAddress = window.tronWeb.defaultAddress.base58;
                  console.log(
                    "selected address at depositeTrx()",
                    selectedAddress
                  );
                  const depositFunction = trxContract.depositTRX().send({
                    from: selectedAddress,
                    callValue: window.tronWeb.toSun(inputAmount),
                  });
                  const tx = await depositFunction;
                  console.log("TRX deposited successfully:", tx);
                  if (tx) {
                    setNetworkConnectWarning("Deposit Successful!");
                  }
                } catch (error) {
                  console.error("Failed to deposit TRX:", error);
                }
              }

              depositTRX();
            } else {
              console.log("please connect to Tron network  ");
              setNetworkConnectWarning("please switch to Tron  network  ");
            }
          }
        }
        // trx  condition second ---->
        if (coinSymbolName !== "TRX" && coinSymbolName !== "BTC") {
          if (coinSymbolName !== "BNB" && coinSymbolName !== "ETH") {
            if (coinNetwork === "Tron(TRC20)") {
              if (trxNetworkType === "https://api.nileex.io") {
                if (inputAmount <= 0) return;
                console.log("run depositTRC20 ");
                async function depositTRC20() {
                  const trxContractAddress ="TBFtvPNscBEJHJNjLrAPcXej3FQ1dcn7vH";
                  const trxTokenAddress = "TPpbbzvE2vgRkwDPZ1yCbZwctVfLwQdpc4";
                    const trxContract = window.tronWeb.contract(
                    paymentContractNileabi,
                    trxContractAddress
                  );
                  const trxtokenContract = window.tronWeb.contract(
                    trc20AbiNile,
                    trxTokenAddress
                  );

                  try {
                    const selectedAddress =
                      window.tronWeb.defaultAddress.base58;
                    console.log(
                      "selected address at depositeTRC20() ",
                      selectedAddress
                    );
                    const amount = window.tronWeb.toSun(inputAmount);
                    const balance = await trxtokenContract
                      .balanceOf(selectedAddress)
                      .call();
                    console.log("balance " + balance);
                    if (balance >= amount) {
                      const approveFunction = trxtokenContract
                        .approve(trxContractAddress, amount)
                        .send({
                          from: selectedAddress,
                        });
                      const tx1 = await approveFunction;
                      console.log("TRC20 tokens approved successfully:", tx1);
                      const depositFunction = trxContract
                        .depositTRC20(trxTokenAddress, amount)
                        .send({
                          from: selectedAddress,
                        });
                      const tx = await depositFunction;
                      console.log("TRC20 tokens deposited successfully:", tx);
                      if (tx) {
                        setNetworkConnectWarning("Deposit Successful!");
                      }
                    } else {
                      console.error("Insufficient TRC20 token balance");
                    }
                  } catch (error) {
                    console.error("Failed to deposit TRC20 tokens:", error);
                  }
                }

                depositTRC20();
              } else {
                console.log(" please connect to Tron network  ");
                setNetworkConnectWarning(" please  switch to Tron network  ");
              }
            }
          }
        }
      }
      } else {
        console.log("Please install  the Tron Wallet browser extension to Connect the Tron wallet")
        setNetworkConnectWarning(
          "Please install  the Tron Wallet browser extension to Connect the Tron wallet."
        );
      }
    } catch (error) {
      console.error("Error fetching account: --> ", error);
    }
  };

  //handler set coin
  const handlerSetSymbol = (coin) => {
    setCoinSymbolName(coin.symbol);
    setErcAddressForBnb(coin.address);
    setCoinSelectWarning();
    console.log("token address " + coin.address);
    console.log("token symbol " + coin.symbol);
  };
  //handler set static coin
  const handlertokenname = (tokenname) => {
    setCoinSymbolName(tokenname);
    setCoinSelectWarning();

    console.log("token symbol " + tokenname);
  };
  //handler set network
  const handlerSetCoinNetwork = (Network) => {
    setCoinNetwork(Network.NetworkName);
    setNetworkSelectWarning();
  };

  //handler get input value
  const handlerInputAmount = (e) => {
    setInputEnterWarning();

    if (e.target.value == 0) {
      setInputAmountWarning("please enter valid amount");
    } else {
      setInputAmountWarning("");
    }
    setInputAmount(e.target.value);
  };
  //==============================================================================================================connect-wallet-end

  //api fetch ---> <--- 
  useEffect(() => {
    const fetchData = async () => {
      try {
        var url = "https://tokens.pancakeswap.finance/pancakeswap-bnb-mm.json";
        const response = await fetch(url);
        const jsonData = await response.json();
        setApiData(jsonData.tokens);
        console.log(jsonData.tokens);
        console.log(jsonData.tokens[0].address);
      } catch (error) {
        console.log("error while fetching api  ",error);
      }
    };
    fetchData();
  }, []);

  return (
    <BrowserRouter>
      <Layout />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="login" element={<Login />}></Route>
        <Route path="signup" element={<Signup />}></Route>
        <Route path="exchange" element={<Exchange />}></Route>
        <Route
          path="deposit"
          element={
            <Deposit
              handleConnectMetamask={handleConnectMetamask}
              handleConnectTrustWallet={handleConnectTrustWallet}
              handleConnectTronWallet={handleConnectTronWallet}
              handlerSetSymbol={handlerSetSymbol}
              coinSymbolName={coinSymbolName}
              handlerSetCoinNetwork={handlerSetCoinNetwork}
              coinNetwork={coinNetwork}
              apiData={apiData}
              handlertokenname={handlertokenname}
              handlerInputAmount={handlerInputAmount}
              inputAmountWarning={inputAmountWarning}
              networkConnectWarning={networkConnectWarning}
              coinSelectWarning={coinSelectWarning}
              networkSelectWarning={networkSelectWarning}
              inputEnterWarning={inputEnterWarning}
            />
          }
        ></Route>
        <Route path="launchpad" element={<Launchpad />}></Route>
        <Route path="security" element={<Security />}></Route>
        <Route path="launchpaddetail" element={<Launchpaddetail />}></Route>
        <Route path="orderhistory" element={<OrderHistory />}></Route>
        <Route path="profile" element={<Profile />}></Route>
        <Route path="about" element={<About />}></Route>
        <Route path="career" element={<Career />}></Route>
        <Route path="referralprogram" element={<ReferralProgram />}></Route>
        <Route path="fee" element={<Fee />}></Route>
        <Route path="terms" element={<TermsCondition />}></Route>
        <Route path="privacy" element={<Privacy />}></Route>
        <Route path="warning" element={<Warning />}></Route>
        <Route path="news" element={<News />}></Route>
        <Route path="feedback" element={<Feedback />}></Route>
        <Route path="trade" element={<Trade />}></Route>
        <Route path="affilate" element={<Affilate />}></Route>
        <Route path="market" element={<Market />}></Route>
        <Route path="marketdetail" element={<MarketDetail />}></Route>
        <Route path="listing-application-form" element={<Listing />}></Route>

        <Route path="cryptowithdrawl" element={<CryptoWithdrawl />}></Route>
        <Route path="withdrawalfee" element={<WithdrawFee />}></Route>
        <Route path="listingwizard" element={<LIstingwizard />}></Route>
        <Route
          path="transactionhistory"
          element={<TransactionHistory />}
        ></Route>
        <Route path="wallet" element={<Wallet />}></Route>
        <Route path="referralrewards" element={<MyReferral />}></Route>
        <Route path="*" element={<Nopage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
