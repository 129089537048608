import React from 'react'
import Footer from './Footer'
import { Link } from 'react-router-dom'


export default function Market() {
  return (
    <div>
        <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/"> <i className="fa fa-angle-left"></i> </Link>
                                Markets Overview
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <div className="container">
            <div className="row align-items-center mt-5">
                <div className="col-md-3">
                    <div className="n_table">
                    <p>Highlight Coin</p>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <div>
                                            <img src="coin-icon/bnb.png" alt="" className="img-fluid" />
                                            BNB
                                        </div>
                                    </td>
                                    <td>$308.50</td>
                                    <td>-0.42%</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <img src="coin-icon/eth.png" alt="" className="img-fluid" />
                                            ETH
                                        </div>
                                    </td>
                                    <td>$308.50</td>
                                    <td>-0.42%</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                                            BNB
                                        </div>
                                    </td>
                                    <td>$308.50</td>
                                    <td>-0.42%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="col-md-3">
                    <div className="n_table">
                    <p>New Listing</p>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <div>
                                            <img src="coin-icon/bnb.png" alt="" className="img-fluid" />
                                            BNB
                                        </div>
                                    </td>
                                    <td>$308.50</td>
                                    <td>-0.42%</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <img src="coin-icon/eth.png" alt="" className="img-fluid" />
                                            ETH
                                        </div>
                                    </td>
                                    <td>$308.50</td>
                                    <td>-0.42%</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                                            BNB
                                        </div>
                                    </td>
                                    <td>$308.50</td>
                                    <td>-0.42%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="col-md-3">
                    <div className="n_table">
                    <p>Top Gainer Coin</p>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <div>
                                            <img src="coin-icon/bnb.png" alt="" className="img-fluid" />
                                            BNB
                                        </div>
                                    </td>
                                    <td>$308.50</td>
                                    <td>-0.42%</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <img src="coin-icon/eth.png" alt="" className="img-fluid" />
                                            ETH
                                        </div>
                                    </td>
                                    <td>$308.50</td>
                                    <td>-0.42%</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                                            BNB
                                        </div>
                                    </td>
                                    <td>$308.50</td>
                                    <td>-0.42%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="n_table">

                    <p>Top Volume Coin</p>

                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <div>
                                            <img src="coin-icon/bnb.png" alt="" className="img-fluid" />
                                            BNB
                                        </div>
                                    </td>
                                    <td>$308.50</td>
                                    <td>-0.42%</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <img src="coin-icon/eth.png" alt="" className="img-fluid" />
                                            ETH
                                        </div>
                                    </td>
                                    <td>$308.50</td>
                                    <td>-0.42%</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                                            BNB
                                        </div>
                                    </td>
                                    <td>$308.50</td>
                                    <td>-0.42%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="col-md-12">
                <div className="table-responsive box my-5">
<table id="example1" className="table table-striped table-bordered">
        <thead>
            <tr>
                <th>Name</th>
                <th>Price</th>
                <th>24h Change</th>
                <th>24h Volume</th>
                <th>Market Cap</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <Link to="/marketdetail">Detail</Link>
                        <Link to="#">Trade</Link>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                <div>
                        <Link to="/marketdetail">Detail</Link>
                        <Link to="#">Trade</Link>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                <div>
                        <Link to="/marketdetail">Detail</Link>
                        <Link to="#">Trade</Link>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                <div>
                        <Link to="/marketdetail">Detail</Link>
                        <Link to="#">Trade</Link>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <Link to="/marketdetail">Detail</Link>
                        <Link to="#">Trade</Link>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                <div>
                        <Link to="/marketdetail">Detail</Link>
                        <Link to="#">Trade</Link>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                <div>
                        <Link to="/marketdetail">Detail</Link>
                        <Link to="#">Trade</Link>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                <div>
                        <Link to="/marketdetail">Detail</Link>
                        <Link to="#">Trade</Link>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                <div>
                        <Link to="/marketdetail">Detail</Link>
                        <Link to="#">Trade</Link>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                <div>
                        <Link to="/marketdetail">Detail</Link>
                        <Link to="#">Trade</Link>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                <div>
                        <Link to="/marketdetail">Detail</Link>
                        <Link to="#">Trade</Link>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <a href="">Detail</a>
                        <a href="">Trade</a>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <a href="">Detail</a>
                        <a href="">Trade</a>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <a href="">Detail</a>
                        <a href="">Trade</a>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <a href="">Detail</a>
                        <a href="">Trade</a>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <a href="">Detail</a>
                        <a href="">Trade</a>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <a href="">Detail</a>
                        <a href="">Trade</a>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <a href="">Detail</a>
                        <a href="">Trade</a>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <a href="">Detail</a>
                        <a href="">Trade</a>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <a href="">Detail</a>
                        <a href="">Trade</a>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <a href="">Detail</a>
                        <a href="">Trade</a>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <a href="">Detail</a>
                        <a href="">Trade</a>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <a href="">Detail</a>
                        <a href="">Trade</a>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <a href="">Detail</a>
                        <a href="">Trade</a>
                    </div>
                </td>
            </tr>
            <tr>
                
                <td>
                    <div className='d-flex align-items-center'>
                        <img src="coin-icon/btc.png" alt="" className="img-fluid" />
                        <h6 className='m-0'>BTC</h6>
                        <p className='m-0'>Bitcoin</p>
                    </div>
                </td>
                <td>$26,854.04</td>
                <td>-0.20%</td>
                <td>$10.57B</td>
                <td>$520.38B</td>
                <td>
                    <div>
                        <a href="">Detail</a>
                        <a href="">Trade</a>
                    </div>
                </td>
            </tr>
            
        </tbody>
    </table>
</div>
                </div>


            </div>
        </div>
            
        <Footer />

    </div>
  )
}
