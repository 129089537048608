import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";


function Feedback(){
    return(
        <>

<div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/"> <i className="fa fa-angle-left"></i> </Link>
                                   Feedback Suggestions
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section>
                <div className="container">
                    <div className="row">
                        <div className="offset-md-4 col-md-4">
                            <div className="suggestion_box text-center">
                                <img src="banner/user.png" alt="" className="img-fluid" />
                                <h3>Share product Suggestions!</h3>
                                <p>Bidexpro is always here to listen. Let's enhance our 
                                   community together. You need to be logged in to <br />
                                   submit an idea.</p>
                                <a className="btn btn-primary w-100" href="#" id="feedback_btn">Login</a>
                            </div>
                        </div>
                    </div>
                </div> 
            </section>
            
            <section id="feedback" className="my-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                        <div className="col-md-12" id="login">
                          <div className="form-section text-ceter">
                    <div className="form-inner">
                        <h1 className="text-center">Feedback</h1>
                        <div className="extra-login form-group clearfix">
                                <span>Or</span>
                            </div>

                        <form action="#" method="GET">
                            <div className="form-group form-box clearfix">
                                <input name="text" type="text" className="form-control" placeholder="First Name" />
                                <i className="flaticon-mail-2"></i>
                            </div>
                            <div className="form-group form-box clearfix">
                                <input name="email" type="email" className="form-control"  placeholder="E-mail" />
                                <i className="flaticon-password"></i>
                            </div>

                            <div className="form-group form-box clearfix">
                                <input name="text" type="text" className="form-control"  placeholder="Subject" />
                                <i className="flaticon-password"></i>
                            </div>

                            <div className="form-group form-box clearfix">
                                <textarea name="text" type="text" className="form-control"  placeholder="Subject" />
                                <i className="flaticon-password"></i>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group ">
                                        <button type="submit" className="btn btn-primary btn-lg btn-theme" id='getstart'><span>Submit</span></button>
                                    </div>
                                </div>
                            
                            </div>
                           
                        </form>

                    </div>
                </div>
                    </div>
                        </div>
                    </div>
                </div>
            </section>
            
        <Footer />

        </>
    )
}


export default Feedback;