import React from 'react'

export default function MyReferral() {
  return (
    <div>
         <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <a href="/" className="text-white"> <i className="fa fa-angle-left m-0"></i></a>   &nbsp;
                               Referral Rewards
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


           <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>

                        <div className='bg_new mt-5 window'>
                            <ul className="nav nav-tabs border-bottom-0 justify-content-start " id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Referral Link</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Referral Id</button>
                            </li>
                            </ul>
                        </div>

                        
            <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            
            </div>
            </div>

                        <div className="box_heading pb-2 pt-5">
                                <h2><i className='fa fa-user' ></i> My Referrals</h2>
                            </div>

                        <div className="market-history market-order mt15 myreferral">
                            <ul className="d-flex justify-content-between market-order-item">
                                    <li>ID</li>
                                    <li>Registration Date</li>
                                    <li>Verification</li>
                                    <li>Deposit</li>
                                </ul>
                                <div className='refer'>
                                    <i className="fa fa-file"></i> <br />
                                    <h5 className='my-4'>No referrals found </h5>
                                    <button className="btn btn-primary mt-3 w-50">Invite Friends</button>
                                </div>
                            </div>
                            </div>


                            
                            </div>
                        </div>
                   
           </section>

           <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>



                        <div className="box_heading pb-2 pt-5">
                                <h2><i className='fa fa-user' ></i> My Rewards</h2>

                                <p className='my-3'>Once the activity concludes, you can continue to review your reward history on the Lite referral dashboard.</p>
                            </div>

                        <div className="market-history market-order mt15 myreferral">
                            <ul className="d-flex justify-content-between market-order-item">
                                    <li>Task Type</li>
                                    <li>Reward Type</li>
                                    <li>Distribution Date</li>
                                    <li>Bonus</li>
                                    <li>Status</li>
                                </ul>
                                <div className='refer'>
                                    <i className="fa fa-file"></i> <br />
                                    <h5 className='my-4'>No referrals found </h5>
                                    <button className="btn btn-primary mt-3 w-50">View Activity History & Overview </button>
                                </div>
                            </div>
                            </div>


                            
                            </div>
                        </div>
                   
           </section>

           <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>

                        <div className="box_heading pb-2 pt-5">
                                <h2>My Journey to 100 USDT</h2>

                                <p className='my-3'>Once the activity concludes, you can continue to review your reward history on the Lite referral dashboard.</p>
                            </div>

                        <div className="market-history market-order mt15 myreferral">
                            <ul className="d-flex justify-content-between market-order-item">
                                    <li>Task Type</li>
                                    <li>Reward Type</li>
                                    <li>Distribution Date</li>
                                    <li>Bonus</li>
                                    <li>Status</li>
                                </ul>
                                <div className='journey_box'>
                                    <div className='text-white fs-4'>
                                        <i className='fa fa-book'></i> Rules
                                    </div>
                                    <div className='forward text-white d-flex align-items-center my-3'>
                                         <i className='fa fa-share' ></i>       
                                         <div>
                                            <div className='gift_card d-flex align-items-center'>
                                                <span>1</span>
                                                <p className='m-0'>Win a 100 USDT Gift Card</p>
                                            </div>
                                         </div>
                                    </div>

                                    <div className='forward text-white d-flex align-items-center my-3'>
                                         <i className='fa fa-download' ></i>       
                                         <div>
                                            <div className='gift_card d-flex align-items-center'>
                                                <span>2</span>
                                                <p className='m-0'>Get 100 USDT in Trading Fee Rebate Vouchers</p>
                                            </div>
                                         </div>
                                    </div>

                                <p className='text-white mt-3'>Task 1: Each verified referral will bring the inviter  anything from 0.01-10 USDT in rewards. Inviters can only withdraw when the total amount received has reached 100 USDT . If the inviter fails to reach 100 USDT, the reward will expire. After 2023/06/05 23:59 (UTC), users will be unable to start new referral rounds. However, users can complete any ongoing rounds. </p>
                                <p className='text-white'>Task 2: Each verified referral will bring the inviter  anything from 0.01-10 USDT in rewards. Inviters can only withdraw when the total amount received has reached 100 USDT . If the inviter fails to reach 100 USDT, the reward will expire. After 2023/06/05 23:59 (UTC), users will be unable to start new referral rounds. However, users can complete any ongoing rounds. </p>

                                </div>
                            </div>
                            </div>


                            
                            </div>
                        </div>
                   
           </section>
    </div>
  )
}
