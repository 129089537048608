import React from 'react'
// import { Link } from "react-router-dom";


export default function Nopage() {
  return (
    <div>
        <div className="error"> 
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='error_box'>
                    <img src="/banner/404.png" alt="" className="img-fluid" />
                      {/* <h1>404</h1> */}
                    <h3>OPPS! PAGE NOT FOUND</h3>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}
