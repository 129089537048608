
import React, { useEffect } from 'react';
import TradingViewWidget from 'react-tradingview-widget';


const Trading = () => {
  useEffect(() => {
    // const script1 = document.createElement('script');
    // script1.src = `${process.env.PUBLIC_URL}/js/amcharts.min.js`;
    // document.body.appendChild(script1);

   
    const script2 = document.createElement('script');
    script2.src = `${process.env.PUBLIC_URL}/js/amcharts-core.min.js`;
    document.body.appendChild(script2);

    const script3 = document.createElement('script');
    script3.src = `${process.env.PUBLIC_URL}/js/custom.js`;
    document.body.appendChild(script3);

    const script4 = document.createElement('script');
    script4.src = `${process.env.PUBLIC_URL}/js/tv.js`;
    document.body.appendChild(script4);

    // --------
 

    
    return () => {
      // Clean up the dynamically added scripts when the component unmounts
      // document.body.removeChild(script1);
      document.body.removeChild(script2);
      document.body.removeChild(script3);
      document.body.removeChild(script4);
    };
  }, []);

  return (
    <div id="tradingview_e8053">
      <TradingViewWidget
        width="100%"
        height={550}
        symbol="BITSTAMP:BTCUSD"
        interval="D"
        timezone="Etc/UTC"
        theme="Dark"
        style="1"
        locale="en"
        toolbar_bg="#f1f3f6"
        enable_publishing={false}
        withdateranges={true}
        hide_side_toolbar={false}
        allow_symbol_change={true}
        show_popup_button={true}
        popup_width={1000}
        popup_height={600}
      />
    </div>
  );
};

export default Trading;