import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function Privacy(){
    return(
        <>
              <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/"> <i className="fa fa-angle-left"></i> </ Link>
                                    Privacy
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-5">
             <div className="container">
            <div className="row align-items-center">
                <div className="col-md-12">
                    <div className="about__content aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                        <h3 className="heading1 ">Privacy Notice - Bidexpro</h3>
                        <h6 className="span_about">Last updated: 28 March 2023</h6>
                        <p className="fs-20 decs">
                        Bidexpro (“Bidexpro”, “we”, or “us”) is committed to protecting the privacy of our customers, and we take our data protection responsibilities with the utmost seriousness.
                        </p>
                      
                        
                       <p> This Privacy Notice describes how Bidexpro collects and processes your personal information through the Bidexpro websites and applications that are referenced in this Privacy Notice. Bidexpro refers to an ecosystem comprising Bidexpro websites (whose domain names include but are not limited to www.Bidexpro.com/en-IN), mobile applications, clients, applets and other applications that are developed to offer Bidexpro Services, and includes independently-operated platforms, websites and clients within the ecosystem (e.g., Bidexpro’s Open Platform, Bidexpro Launchpad, Bidexpro Labs, Bidexpro Charity, Bidexpro DEX, Bidexpro X, JEX, and fiat gateways). “Bidexpro Operators” refer to all parties that run Bidexpro, including but not limited to legal persons, unincorporated organizations and teams that provide Bidexpro Services and are responsible for such services. “Bidexpro” as used in this Privacy Notice includes Bidexpro Operators.</p>
                       <p> This Privacy Notice applies to all Personal Information processing activities carried out by us, across platforms, websites, and departments of Bidexpro and Bidexpro Operators.</p>
                       <p> To the extent that you are a customer or user of our services, this Privacy Notice applies together with any terms of business and other contractual documents, including but not limited to any agreements we may have with you.</p>
                       <p> To the extent that you are not a relevant stakeholder, customer, or user of our services, but are using our website, this Privacy Notice also applies to you together with our Cookie Notice.</p>
                       <p> This Notice should therefore be read together with our Cookie Notice, which provides further details on our use of cookies on the website. Our Cookie Notice can be accessed here.</p>

                       <h6 className="span_about">1. Bidexpro Relationship with you</h6>
                        <div className="table-responsive">
                       <table className="table mt-5 mb-5">
                            <thead>
                                <tr>
                                <th scope="col">Where you reside</th>
                                <th scope="col">Services Provided</th>
                                <th scope="col">Your operating entity</th>
                                <th scope="col">Contact address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td scope="row">France</td>
                                <td>Digital Asset Services Provider</td>
                                <td>Bidexpro France SAS</td>
                                <td>17 rue de la Vanne 92129, Montrouge, France.</td>
                                </tr>
                                <tr>
                                <td scope="row">Italy</td>
                                <td>Digital Asset Services Provider</td>
                                <td>Bidexpro Italy S.R.L</td>
                                <td>Via Generale Francesco Poli, 31, 73100, Lecce, Lecce, Italy.</td>
                                </tr>
                                <tr>
                                <td scope="row">Spain</td>
                                <td>Virtual Assets Services Provider</td>
                                <td>Bidexpro Spain SL</td>
                                <td>C/ Don Ramón de la Cruz, 38, 28001, Madrid, Spain.</td>
                                </tr>
                                <tr>
                                <td scope="row">Sweden</td>
                                <td>Financial Institution</td>
                                <td>Bidexpro Nordics AB</td>
                                <td>Mailbox 625, 114 11 Stockholm Sverige.</td>
                                </tr>
                                <tr>
                                <td scope="row">Poland</td>
                                <td>Digital Asset Services</td>
                                <td>Bidexpro Poland Spółka z Ograniczoną Odpowiedzialnością</td>
                                <td>Ul. Aleje Ujazdowskie, No. 41, apt., locality Warsaw, 00-540, Poland.</td>
                                </tr>
                            </tbody>
                            </table>
                            </div>
                            <p>The Bidexpro operating entity you contract with determines the means and purposes of processing of your personal information in relation to the services provided to you (typically referred to as a “data controller”). For most jurisdictions in the European Economic Area, this is Bidexpro (Services) Holdings Limited, a company registered at 6th Floor, South Bank House, Barrow Street, 4 Dublin, Ireland.</p>
                            <p>If you reside in France, Italy, Spain, Sweden or Poland then the relevant data controller is Bidexpro (Service) Holdings Limited as joint controller with the Bidexpro entity referenced in the table above for your country of residence. In these cases, Bidexpro (Service) Holdings Limited is the joint controller with primary responsibility for your personal information, including providing you with information and responding to any requests you may make under data protection law.You may be asked to provide personal information when you are in contact with any Bidexpro companies. The Bidexpro companies may share your personal information with each other and if they do so they will use it consistently with this Privacy Notice.</p>

                            <h6 className="span_about">2. What Personal Information does Bidexpro collect and process?</h6>

                           <p> Personal information is data that identifies an individual or relates to an identifiable individual. This includes information you provide to us, information which is collected about you automatically, and information we obtain from third parties.</p>
                           <p> Information you provide to us. To open an account and access our services, we'll ask you to provide us with some information about yourself. This information is either required by law ( e.g., to verify your identity and comply with “Know Your Customer” obligations), necessary to provide the requested services (e.g., you will need to provide your email address in order to open your account), or is relevant for certain specified purposes, described in greater detail below. In some cases, if we add services and features you may be asked to provide us with additional information.</p>
                           <p> Failure in providing the data required implies that Bidexpro will not be able to offer you our services.</p>

                            <p><b>We may collect the following types of information from you:</b></p>
                            
                            <div className="table-responsive">

                            <table className="table mt-5">
                            <thead>
                                <tr>
                                <th scope="col">Category of Personal Information</th>
                                <th scope="col">Types of Personal Information</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td scope="row">Personal Identification Information.</td>
                                <td>Full name, e-mail address, gender, home address, phone address, phone number, date of birth, nationality, signature, utility bills, photographs, and a video recording of you</td>
                                </tr>
                                <tr>
                                <td scope="row">Sensitive and Biometric Personal Information</td>
                                <td>Bidexpro may also collect sensitive personal information when permitted by local law or with your consent, such as biometric information, for example to verify your identity by comparing the facial scan data extracted from your selfie or video with the photo in your government issued identity document</td>
                                </tr>
                                <tr>
                                <td scope="row">Institutional Information</td>
                                <td>Proof of legal formation, personal identification information for all material beneficial owners, personal information about the board of directors senior persons responsible for the operations of the body corporate</td>
                                </tr>
                                <tr>
                                <td scope="row">Financial Information</td>
                                <td>Bank account information, payment card information, source of funding, source of wealth,</td>
                                </tr>
                                <tr>
                                <td scope="row">Transaction Information</td>
                                <td>Information about the transaction you make on our services, such as the name of the recipient, your name and e-mail</td>
                                </tr>
                                <tr>
                                <td scope="row">Information from Cookies</td>
                                <td>See our Cookie Notice for more information.</td>
                                </tr>
                                <tr>
                                <td scope="row">Employment Information</td>
                                <td>Job title, salary wage rate, and company</td>
                                </tr>
                                <tr>
                                <td scope="row">Communications</td>
                                <td>Surveys responses, information contained in the Survey. Communications with us including call recordings with our customer services team.</td>
                                </tr>
                                <tr>
                                <td scope="row">Contact Information</td>
                                <td>E-mail address, country, region,</td>
                                </tr>
                            </tbody>
                            </table>
                            </div>
                            <p>Information we collect from you automatically. To the extent permitted under the applicable law, we may collect certain types of information automatically, for example whenever you interact with us or use the services. This information helps us address customer support issues, improve the performance of our sites and services, maintain and or improve your user experience, and protect your account from fraud by detecting unauthorized access.</p>

                            <p><b>Information collected automatically includes:</b></p>

                            <div className="table-responsive">

                            

                            <table className="table mt-5">
                            <thead>
                                <tr>
                                <th scope="col">Category of Personal Information</th>
                                <th scope="col">Types of Personal Information</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td scope="row">Browsing Information</td>
                                <td>Device ID, browsing information such as name and version, IP address</td>
                                </tr>
                                <tr>
                                <td scope="row">Usage Data</td>
                                <td>Authentication data, security questions, click-stream data, public social networking posts, and other data collected via cookies or similar technologies. <br />Information about how our Services are performing when you use them, e.g., error messages you receive, performance of the site information.</td>
                                </tr>
                                <tr>
                                <td scope="row"></td>
                                <td>Identifiers – the IP address, or other online identifiers of a person, e-mail address if used for direct marketing, and name and address <br />Demographic data - (e.g., income, family status, age bracket, gender, interests, etc) </td>
                                </tr>
                                <tr>
                                <td scope="row">Marketing and Research Information</td>
                                <td>Browser/web history data and preferences expressed through selection/viewing/purchase of goods, services and content, information about your mobile device including (where available) type of device, device identification number, mobile operating system. <br /> Analytics and profiles of the individuals based on the data collected on them. For more information about this please see our Cookie Policy. <br /> Interests or inferred interests and marketing preferences. </td>
                                </tr>
                            </tbody>
                            </table>
                            </div>
                        <p><b>Information we collect from our affiliates and third parties.</b></p>

                        <p>From time to time, we may obtain information about you from our affiliates or third parties sources as required or permitted by applicable law.</p>

                        <div className="table-responsive">

                        <table className="table mt-5 mb-5">
                            <thead>
                                <tr>
                                <th scope="col">Category of Personal Information</th>
                                <th scope="col">Types of Personal Information</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td scope="row"></td>
                                <td>In accordance with applicable law, we may obtain information about you from the group of companies related to us by common control or ownership (“Affiliates”) as a normal part of conducting business, so that we may offer our Affiliates’ Services to you.</td>
                                </tr>
                                <tr>
                                <td scope="row">Affiliates</td>
                                <td>We may obtain information about you such as Personal Identification Information, Transactional Information, Institutional Information, Usage Information. For example, if you want to convert cryptocurrency into fiat and make withdrawals into your bank account, we might need to exchange information with Bidexpro Connect. For more information about how Bidexpro Connect processes this information you can check its Privacy Notice here.</td>
                                </tr>
                                <tr>
                                <td scope="row">Blockchain Data</td>
                                <td>We may analyze public blockchain data, such as transaction ID’s, transaction amounts, wallet address, timestamps or transactions or events.</td>
                                </tr>
                            </tbody>
                            </table>
                            </div>

                            <h6 className="span_about">3. Why does Bidexpro process my personal information? Which legal bases are we relying on for our collection and processing of your personal information?</h6>

                            <p>Our primary purpose in collecting personal information is to provide our services in a secure, efficient, and smooth way. We generally use your personal information to deliver, provide, operate, our services, and for content and advertising, and for loss prevention and anti-fraud purposes. Below you’ll find an explanation on how we use</p>

                        <div className="table-responsive">

                        
                            <table className="table mt-5 mb-5">
    <thead>
        <tr>
            <th>
                
                            Why does Bidexpro process my Personal Information?
                       
            </th>
            <th>
               
                            Legal Basis for our use of personal information (EEA
                            and Switzerland EU GDPR)
                      
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>
               
                        - Managing our contractual relationship with you. To
                        create and maintain your account.
                   
                        This includes when we use your personal information to
                        take and handle orders and process payments.
                   
                        The Type of Information processed is Personal
                        Identification Information, Institutional Information,
                        Contact Information, Financial Information. The
                        consequences of not processing your personal information
                        for such purposes is the inability to open an account
                        with us or the termination of your account where one is
                        already open.
                   
            </td>
            <td>
               
                        Processing is necessary for the performance of a
                        contract of which you are a party.
                   
            </td>
        </tr>
        <tr>
            <td>
               
                        - To maintain legal and regulatory compliance
                    
                        Most of our core services such as the exchange services
                        are subject to strict and specific laws and regulations
                        requiring us to collect, use and store certain personal
                        information and process Personal Identification identity
                        information and in some cases Sensitive Personal
                        Data[(including biometrics) (as detailed in section I)
                        For example to comply with our Know Your Customer
                        (“KYC”) obligations under applicable laws and
                        regulations, and in particular to comply with Anti-Money
                        Laundering laws and regulations.
                   
                        The Type of Information processed is Personal
                        Identification Information, Institutional Information,
                        Sensitive and Biometric Personal Information, Government
                        Identifiers, Contact Information, Financial Information.
                        If you do not provide personal information required by
                        law, you may be unable to open an account, or we may
                        have to close your account where it is already opened.
                   
            </td>
            <td>
              
                        Processing is necessary to comply with our legal
                        obligations under applicable laws and regulations, and
                        Anti-Money Laundering laws and regulations.
                    
            </td>
        </tr>
        <tr>
            <td>
                
                        - Communicate with you on service and transaction-
                        related matters.
                    
                        We use your personal information to communicate with you
                        in relation to Bidexpro Services on administrative or
                        account-related information. We will communicate with
                        you to keep you updated about our Services for example,
                        to inform you of relevant security issues, updates, or
                        provide other transaction-related information. Without
                        such communications, you may not be aware of important
                        developments relating to your account that may affect
                        how you can use our services. You may not opt-out of
                        receiving critical service communications, such as
                        emails or mobile notifications sent for legal or
                        security purposes.
                   
            </td>
            <td>
               
                        Processing is necessary for the performance of a
                        contract of which you are a party.
                   
            </td>
        </tr>
        <tr>
            <td>
                
                        - To provide customer services:
                   
                        We process your personal information when you contact us
                        in order to provide support with respect to questions,
                        disputes, complaints, troubleshoot problems, etc.
                    
                        The Type of Information processed is Personal
                        Identification Information, Institutional Information,
                        Transactional Information, Communications, Contact
                        Information, Financial Information, Browsing
                        Information, Usage Data.
                   
                        Without processing your personal information for this
                        purpose, we can’t respond to your requests.
                   
            </td>
            <td>
                
                        Processing is necessary for the performance of a
                        contract of which you are a party.
                    
                        Processing is necessary for the purpose of the
                        legitimate interest pursued by us to improve our
                        services and enhance our user experience.
                   
            </td>
        </tr>
        <tr>
            <td>
               
                        - To promote safety, security, and integrity of our
                        platform.
                   
                        We process your personal information in order to enhance
                        security, monitor and verify identity or service access,
                        combat malware or security risks and to comply with
                        applicable security laws and regulations.
                   
                        We process your personal information to verify accounts
                        and related activity, find and address violations of our
                        Terms and Conditions, investigate suspicious activity,
                        detect, prevent and combat unlawful behaviour, detect
                        fraud, and maintain the integrity of our Services.
                    
                        The Type of Information processed is Personal
                        Identification Information, Institutional Information,
                        Transactional Information, Contact Information,
                        Financial Information, Browsing Information, Usage Data.
                   
                        Without processing your personal information, we may not
                        be able to ensure the security of our Services.
                  
                        We use your personal information to provide
                        functionality, analyse performance, fix errors, and
                        improve the usability and effectiveness of Bidexpro
                        Services.
                    
            </td>
            <td>
              
                 
                        Processing is necessary for the performance of a
                        contract of which you are a party.
                  
            </td>
        </tr>
        <tr>
            <td>
               
                        - To promote safety, security, and integrity of our
                        Services.
                   
                        Fraud prevention and detection and credit risks.
                   
                        The Type of Information processed is Personal
                        Identification Information, Institutional Information,
                        Transactional Information, Contact Information,
                        Financial Information, Browsing Information, Usage Data.
                   
                        We process Personal Identification Information to
                        prevent and detect, prevent and mitigate fraud and abuse
                        of our services and in order to protect you against
                        account compromise or funds loss and in order to ensure
                        the security of our users, Bidexpro services and others.
                        We may also use scoring methods to assess and manage
                        credit risks.
                    
            </td>
            <td>
               
                        Processing is necessary for the purpose of the
                        legitimate interests pursued by us and the interests of
                        our users when, for example, we detect and prevent fraud
                        and abuse in order to protect the security of our users,
                        ourselves, or others;
                   
            </td>
        </tr>
        <tr>
            <td>
                
                        - To provide Bidexpro services.
                   
                        We process your personal information to provide the
                        services to you and process your orders. For example,
                        when you want to use the exchange service on our
                        platform, we ask for certain information such as your
                        identification, contact information, and payment
                        information.
                   
                        The Type of Information processed is Personal
                        Identification Information, Institutional Information,
                        Transactional Information, Contact Information,
                        Financial Information, Browsing Information, Usage Data.
                   
                        We cannot provide you with services without such
                        information.
                  
            </td>
            <td>
                
                        Processing is necessary for the performance of a
                        contract of which you are a party.
                   
            </td>
        </tr>
        <tr>
            <td>
                
                 
                        - To improve our services.
                   
                        We process personal information to improve our services
                        and for you to have a better user experience;
                    
                        The Type of Information processed is Personal
                        Identification Information, Institutional Information,
                        Transactional Information, Browsing Information, Usage
                        Data.
                   
            </td>
            <td>
               
                        Processing is necessary for the purpose of the
                        legitimate interest pursued by us to improve our
                        services and enhance our user experience.
                  
            </td>
        </tr>
        <tr>
            <td>
                
                        - To provide you with promotions
                    
                        We use your information to provide you with promotions,
                        including offers, rewards, and other incentives for
                        using our Services.
                  
                        The Type of Information processed is Personal
                        Identification Information, Institutional Information,
                        Transactional Information, Browsing Information, Usage
                        Data.
                   
            </td>
            <td>
               
                        Processing is necessary for the purpose of our
                        legitimate interest and your interest to reward your
                        customer loyalty.
                   
            </td>
        </tr>
        <tr>
            <td>
                
                        - To do research and innovate.
                   
                        We carry out surveys to learn more about your experience
                        using our Services. We also use your information to
                        support research and development and drive innovations
                        of our Services and products. This information will also
                        be used for Marketing purposes.
                   
            </td>
            <td>
                
                        Processing is necessary for the purpose of our
                        legitimate interest to improve and run our Services
                        through information obtained from these surveys.
                   
            </td>
        </tr>
        <tr>
            <td>
                
                        - Recommendations and personalisation.
                    
                        We use your personal information to recommend features
                        and services that might be of interest to you, identify
                        your preferences, and personalise your experience with
                        Bidexpro services;
                    
            </td>
            <td>
                
                        Processing is necessary for the purpose of our
                        legitimate interest to provide a personalised service to
                        our customers.
                   
                        Processing is necessary for the performance of a
                        contract of which you are a party.
                    
            </td>
        </tr>
        <tr>
            <td>
               
                        - To provide marketing communications to you.
                    
                        We use your information based on your consent to send
                        you targeted marketing communications through email,
                        mobile, in-app, and push notifications.
                    
                        We also use your information to carry out profiling for
                        marketing purposes.
                    
                        The Type of Information processed is Personal
                        Identification Information, Institutional Information,
                        Transactional Information, Browsing Information, Usage
                        Data, Marketing and Research Information,
                        Communications.
                    
            </td>
            <td>
                
                        Where required by applicable law, we rely on your
                        consent to process your personal information for
                        marketing purposes. When you consent to processing your
                        personal information for a specified purpose, you may
                        withdraw your consent at any time and we will stop
                        processing your personal information for that purpose.
                        The withdrawal of consent does not affect the lawfulness
                        of processing based on consent before its withdrawal
                    
                        Where we carry out profiling for marketing purposes, for
                        example to establish what Services or promotions you may
                        be interested in, this processing is based on legitimate
                        interest.
                    
            </td>
        </tr>
        <tr>
            <td>
               
                        Cookies: where we use cookies and similar technologies
                        as part of our Service. The type of Information
                        processed is Information from Cookies.
                   
            </td>
            <td>
                
                        Where required by applicable law, we rely on your
                        consent to place cookies and similar technologies. When
                        you consent to processing your personal information for
                        a specified purpose, you may withdraw your consent at
                        any time and we will stop processing your personal
                        information for that purpose.
                   
                        The withdrawal of consent does not affect the lawfulness
                        of processing based on consent before its withdrawal
                    
            </td>
        </tr>
        <tr>
            <td>
                
                        To comply with other legal and regulatory obligations.
                   
                    
                        We may access, read, preserve, and disclose information
                        when we believe it is reasonably necessary to comply
                        with law, legal obligations, regulations, law
                        enforcement, government, and other legal requests, court
                        orders, or disclosure to tax authorities.
                   
                    
                        The Type of Information processed is Personal
                        Identification Information, Institutional Information,
                        Financial Information, Transactional Information,
                        Browsing Information, Usage Data, Blockchain Data.
                    
              
            </td>
            <td>
                
                        Processing is necessary to comply with our legal
                    
                   
                        We may also rely on legitimate interests in responding
                        to legal requests where we are not compelled by
                        applicable law but have a good faith belief it is
                        required by law in the relevant jurisdiction.
                   
            </td>
        </tr>
    </tbody>
</table>
</div>
                  <p>Automated individual decision-making, including profiling. Bidexpro does not rely solely on automated tools to help determine whether a transaction or a customer account presents a fraud or legal risk.</p>
          
          <h6 className="span_about">4.Can Children Use Bidexpro Services?</h6>

          <p>Bidexpro does not allow anyone under the age of 18 to use Bidexpro Services and does not knowingly collect personal information from children under 18.</p>
        
          <h6 className="span_about">5. What About Cookies and Other Identifiers?</h6>

        <p>We use cookies and similar tools to enhance your user experience, provide our services, enhance our marketing efforts and understand how customers use our services so we can make improvements. Depending on applicable laws in the region you are located in, the cookie banner on your browser will tell you how to accept or refuse cookies. A copy of our cookie policy is available here.</p>

        <h6 className="span_about">6. How and Why We Share your Personal Information?</h6>

        <p>We may share your Personal Information with third parties (including other Bidexpro entities) if we believe that sharing your Personal Information is in accordance with, or required by, any contractual relationship with you or us, applicable law, regulation or legal process. When sharing your Personal Information with other Bidexpro entities, we will use our best endeavours to ensure that such entities are either subject to this Privacy Notice, or follow practices at least as protective as those described in this Privacy Notice. For example, depending on where you reside and the entity responsible for running KYC checks. For more information please refer to Section 1.</p>

        <p>We may also share personal information with the following persons or in the following circumstances:</p>

        <p> Affiliates: Personal information that we process and collect may be transferred between Bidexpro companies as a normal part of conducting business and offering our Services to you. See Section 1 “Bidexpro Relationship with you” and Section 2. “What Personal Information does Bidexpro collect and process?”.</p>
        <p> Third party service providers: We employ other companies and individuals to perform functions on our behalf. Examples include analysing data, providing marketing assistance, processing payments, transmitting content, and assessing and managing credit risk. These third-party service providers only have access to personal information needed to perform their functions but may not use it for other purposes. Further, they must process the personal information in accordance with our contractual agreements and only as permitted by applicable data protection laws.</p>
        <p> Legal Authorities: We may be required by law or by Court to disclose certain information about you or any engagement we may have with you to relevant regulatory, law enforcement and/or other competent authorities. We will disclose information about you to legal authorities to the extent we are obliged to do so according to the law. We may also need to share your information in order to enforce or apply our legal rights or to prevent fraud.</p>
        <p> Business transfers: As we continue to develop our business, we might sell or buy other businesses or services. In such transactions, user information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing Privacy Notice (unless, of course, the user consents otherwise). Also, in the unlikely event that Bidexpro or substantially all of its assets are acquired by a third party, user information will be one of the transferred assets.</p>
        <p> Protection of Bidexpro and others: We release accounts and other personal information when we believe release is appropriate to comply with the law or with our regulatory obligations; enforce or apply our Terms of Use and other agreements; or protect the rights, property or safety of Bidexpro, our users or others. This includes exchanging information with other companies and organisations for fraud protection and credit risk reduction.</p>

        <h6 className="span_about">7. International transfers of Personal Information</h6>
        
        <p> To facilitate our global operations, Bidexpro may transfer your personal information outside of the European Economic Area (“EEA”), UK and Switzerland. The EEA includes the European Union countries as well as Iceland, Liechtenstein, and Norway. Transfers outside of the EEA are sometimes referred to as “third country transfers”.</p>
        <p> We may transfer your personal information to our Affiliates, third-party partners, and service providers based throughout the world. In cases where we intend to transfer personal information to third countries or international organisations outside of the EEA, Bidexpro puts in place suitable technical, organizational and contractual safeguards (including Standard Contractual Clauses), to ensure that such transfer is carried out in compliance with applicable data protection rules.</p>
        <p> We also rely on decisions from the European Commission where they recognise that certain countries and territories outside of the European Economic Area ensure an adequate level of protection for personal information. These decisions are referred to as “adequacy decisions”.</p>

        <h6 className="span_about">8. How Secure is My Information?</h6>

        <p> We design our systems with your security and privacy in mind. We have appropriate security measures in place to prevent your information being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. We work to protect the security of your personal information during transmission and while stored by using encryption protocols and softwares. We maintain physical, electronic and procedural safeguards in connection with the collection, storage and disclosure of your personal information. In addition, we limit access to your personal information to those employees, agents, contractors and other third parties who have a business need to know.</p>
        <p> Our security procedures mean that we may ask you to verify your identity to protect you against unauthorised access to your account. We recommend using a unique password for your Bidexpro account that is not utilized for other online accounts and to sign off when you finish using a shared computer.</p>
        
        <h6 className="span_about">9. What About Advertising?</h6>

        <p>In order for us to provide you with the best user experience, we may share your personal information with our marketing partners for the purposes of targeting, modelling, and/or analytics as well as marketing and advertising. You have a right to object at any time to processing of your personal information for direct marketing purposes (see Section 10 below).</p>
        
        <h6 className="span_about">10. What Rights Do I Have?</h6>

        <p> Subject to applicable law, as outlined below, you have a number of rights in relation to your privacy and the protection of your personal information. You have the right to request access to, correct, and delete your personal information, and to ask for data portability. You may also object to our processing of your personal information or ask that we restrict the processing of your personal information in certain instances. In addition, when you consent to our processing of your personal information for a specified purpose, you may withdraw your consent at any time. If you want to exercise any of your rights please contact us using the webform available here. These rights may be limited in some situations - for example, where we can demonstrate we have a legal requirement to process your personal information.</p>
        <p> Right to access: you have the right to obtain confirmation that your personal information are processed and to obtain a copy of it as well as certain information related to its processing;</p>
        <p> Right to rectify: you can request the rectification of your personal information which are inaccurate, and also add to it. You can also change your personal information in your Account at any time.</p>
        <p> Right to delete: you can, in some cases, have your personal information deleted;</p>
        <p> Right to object: you can object, for reasons relating to your particular situation, to the processing of your personal information. For instance, you have the right to object where we rely on legitimate interest or where we process your data for direct marketing purposes;</p>
        <p> Right to restrict processing: You have the right, in certain cases, to temporarily restrict the processing of your personal information by us, provided there are valid grounds for doing so. We may continue to process your personal information if it is necessary for the defense of legal claims, or for any other exceptions permitted by applicable law;</p>
        <p> Right to portability: in some cases, you can ask to receive your personal information which you have provided to us in a structured, commonly used and machine-readable format, or, when this is possible, that we communicate your personal information on your behalf directly to another data controller;</p>
        <p> Right to withdraw your consent: for processing requiring your consent, you have the right to withdraw your consent at any time. Exercising this right does not affect the lawfulness of the processing based on the consent given before the withdrawal of the latter;</p>
        <p> Right to lodge a complaint with the relevant data protection authority: We hope that we can satisfy any queries you may have about the way in which we process your personal information. However, if you have unresolved concerns, you also have the right to complain to the Irish Data Protection Commission or the data protection authority in the location in which you live, work or believe a data protection breach has occurred.</p>
        <p> If you have any questions or objection as to how we collect and process your personal information, please contact us using the webform available here.</p>

        <h6 className="span_about">11. How Long Does Bidexpro Keep My Personal Information?</h6>

        <p> We keep your personal information to enable your continued use of Bidexpro Services, for as long as it is required in order to fulfil the relevant purposes described in this Privacy Notice, and as may be required by law such as for tax and accounting purposes, compliance with Anti-Money Laundering laws, or to resolve disputes and/or legal claims or as otherwise communicated to you.</p>
        <p> While retention requirements vary by jurisdiction, information about our typical retention periods for different aspects of your personal information are described below.</p>
        <p> Personal Identifiable Information collected to comply with our legal obligations under financial or anti-money laundering laws may be retained after account closure for as long as is required under such laws.</p>
        <p> Contact Information such as your name, email address and telephone number for marketing purposes is retained on an ongoing basis and until you (a) unsubscribe or we (b) delete your account. Thereafter we will add your details to an unsubscribed list to ensure we do not inadvertently market to you.</p>
        <p> Content that you post on our website such as support desk comments, photographs, videos, blog posts, and other content may be kept after you close your account for audit and crime prevention purposes.</p>
        <p> Recording of voice calls with you may be kept for a period of up to six years, for audit/ compliance purposes and to resolve disputes and/or legal claims.</p>
        <p> Information collected via cookies, web page counters and other analytics tools is kept for a period of up to one year from the date of the collection of the cookie of the relevant cookie.</p>

        <h6 className="span_about">10. What Rights Do I Have?</h6>
        
        <p>Our data protection officer can be contacted using the webform available here, or via email at dpo@Bidexpro.com and will work to address any questions or issues that you have with respect to the collection and processing of your personal information.</p>

        <h6 className="span_about">10. What Rights Do I Have?</h6>

        <p> If you have any concerns about privacy at Bidexpro, please contact us, and we will try to resolve it. You also have the right to contact your local Data Protection Authority.</p>
        <p> Our business changes regularly, and our Privacy Notice may change also. You should check our websites frequently to see recent changes. Unless stated otherwise, our current Privacy Notice applies to all information that we have about you and your account.</p>

      </div>
      </div>
      </div>
      </div>
      </div>
           
           

            <Footer />
        </>
    )
}

export default Privacy;