import React from "react";
import { Link } from "react-router-dom";


function OrderHistory(){
    return(
        <div>
        <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/" className="text-white"><i className="fa fa-angle-left m-0"></i></Link>  &nbsp;
                                Order History 
                                </h3>
                            </div>
                        </div>
                       
                    </div>
                </div>

            </div>


            <div className="container">
            <div className="row align-items-center">
                

                <div className="col-md-12">
                    <div className="history_list mt-5">
                        <ul className="d-flex ps-0 w-100">
                            <li>
                            
                            <div className="dropdown">
                                <button className="btn btn-primary" >
                                    <input type="date" className="form-control drop_Date" placeholder="2023-05-22" />
                                </button>
                                </div>
                            </li>

                            <li>
                            
                            <div className="dropdown">
                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    Base
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <div className="form-group">
                                        <input type="text" className="form-control" />
                                        <i className="fa fa-search"></i>
                                    </div>
                                </li>
                                    <li><a className="dropdown-item" href="#">All </a></li>
                                    <li><a className="dropdown-item" href="#">1 Inch</a></li>
                                    <li><a className="dropdown-item" href="#">1 InchDown</a></li>
                                    <li><a className="dropdown-item" href="#">1 InchUp</a></li>
                                    <li><a className="dropdown-item" href="#">AAVE</a></li>
                                    <li><a className="dropdown-item" href="#">AAVEDOWN</a></li>
                                </ul>
                                </div>
                            </li>
                            <li>
                            
                            <div className="dropdown">
                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    Quote
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <div className="form-group">
                                        <input type="text" className="form-control" />
                                        <i className="fa fa-search"></i>
                                    </div>
                                </li>
                                    <li><a className="dropdown-item" href="#">All </a></li>
                                    <li><a className="dropdown-item" href="#">1 Inch</a></li>
                                    <li><a className="dropdown-item" href="#">1 InchDown</a></li>
                                    <li><a className="dropdown-item" href="#">1 InchUp</a></li>
                                    <li><a className="dropdown-item" href="#">AAVE</a></li>
                                    <li><a className="dropdown-item" href="#">AAVEDOWN</a></li>
                                </ul>
                                </div>
                            </li>

                            <li>
                            
                            <div className="dropdown">
                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    Side
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <div className="form-group">
                                        <input type="text" className="form-control" />
                                        <i className="fa fa-search"></i>
                                    </div>
                                </li>
                                    <li><a className="dropdown-item" href="#">All </a></li>
                                    <li><a className="dropdown-item" href="#">1 Inch</a></li>
                                    <li><a className="dropdown-item" href="#">1 InchDown</a></li>
                                    <li><a className="dropdown-item" href="#">1 InchUp</a></li>
                                    <li><a className="dropdown-item" href="#">AAVE</a></li>
                                    <li><a className="dropdown-item" href="#">AAVEDOWN</a></li>
                                </ul>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown">
                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    Order Time
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <div className="form-group">
                                        <input type="text" className="form-control" />
                                        <i className="fa fa-search"></i>
                                    </div>
                                </li>
                                    <li><a className="dropdown-item" href="#">All </a></li>
                                    <li><a className="dropdown-item" href="#">1 Inch</a></li>
                                    <li><a className="dropdown-item" href="#">1 InchDown</a></li>
                                    <li><a className="dropdown-item" href="#">1 InchUp</a></li>
                                    <li><a className="dropdown-item" href="#">AAVE</a></li>
                                    <li><a className="dropdown-item" href="#">AAVEDOWN</a></li>
                                </ul>
                                </div>   
                            </li>   
                        </ul>

                        <div className="campus_box table-responsive">
                        <table className="table transaction mt-3">

                            <thead>
                                <th></th>
                                <th>Order Time</th>
                                <th>Pair</th>
                                <th>Type</th>
                                <th>Side</th>
                                <th>Average</th>
                                <th>Price</th>
                                <th>Executed</th>
                                <th>Amount</th>
                                <th>Total</th>
                                <th>Tigger Conditions</th>
                                <th>Status</th>
                            </thead>

                            <tbody>
                                <tr>
                                    <td> <i className="fa fa-plus" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i> </td>
                                    <td scope="row">2023-05-22 12:27:23</td>
                                    <td><b>BNB/USDT</b></td>
                                    <td>Limit</td>
                                    <td><b className="red">Sell</b></td>
                                    <td>307.2</td>
                                    <td>307.2</td>
                                    <td>0.147</td>
                                    <td>0.147</td>
                                    <td>45.1584 USDT</td>
                                    <td>-</td>
                                    <td>Filled</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>

                        
                    </div>

                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body p-0">
                                    <div className="campus_box">
                                    <table className="table">
                                    <thead>
                                        <tr>
                                        <th scope="col">Order No. 4675880816 <i className="fa fa-clone"></i> </th>
                                        <th colSpan="6">Time Updated. 2023-05-22 12:27:23</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td>Date</td>
                                        <td>Trading Price</td>
                                        <td>Executed</td>
                                        <td>Transaction Fee</td>
                                        <td>Total</td>
                                        <td>Role <i className="fa fa-question-circle"></i></td>
                                        </tr>

                                        <tr>
                                        <td>2023-05-22 12:27:23</td>
                                        <td>307.2</td>
                                        <td>0.147</td>
                                        <td>0.00011025 BNB</td>
                                        <td>45.15840000 USDT</td>
                                        <td>Taker</td>
                                        </tr>
                                    </tbody>
                                    </table>  
                                    </div>                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                </div>
                </div>

    </div>
    )
}

export default OrderHistory;