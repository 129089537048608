import React from "react";
import { Link } from "react-router-dom";


function Launchpaddetail(){
    return(
     <>

<div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/"> <i className="fa fa-angle-left"></i> </Link>
                                    LaunchPad Detail
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        


        <div className="launchpadDetail">
            <div className="container">
            <div className="row align-items-center mt-5">
                    <div className="col-md-3">
                            <a href="#">
                            <div className="campus_img">
                                <img src="banner/campus.png" alt="img-fluid" />
                            </div>  
                            </a>
                        </div>

                        <div className="col-md-9">
                            <a href="#">
                            <div className="campus_box">
                                <div className="d-flex align-items-center">
                                    <h2>Open Campus</h2>
                                    <div className="breadcrum_btn">
                                        <a href="#"  >Launchpool <i className="fa fa-question-circle" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <p className="text-white mt-3 mb-5 fs-6">A Web3 Education Protocol to tokenize educational content - first adopted by Tiny Tap</p>

                               <ul className="d-flex launch_icon">
                                <li><a href="#"><i className="fa fa-link"></i>Website</a></li>
                                <li><a href="#"><i className="fa fa-file-text"></i>Whitepaper</a></li>
                                <li><a href="#"><i className="fa fa-lightbulb-o"></i>EDU Research Report</a></li>
                                <li><a href="#"><i className="fa fa-book"></i>View detailed rules</a></li>
                                <li><a href="#"><i className="fa fa-question-circle-o"></i>FAQ</a></li>
                               </ul>
                            </div>
                            </a>
                        </div>

                        <div className="investment_bg">
                                <div className="row align-items-center">
                                <div className="col-md-2 col-6">
                                    <div className="investment text-white">
                                        <p className="mb-2">Type</p>
                                        <h6>Subscription <i className="fa fa-question-circle-o"></i></h6>
                                    </div>
                                </div>
                                <div className="col-md-2 col-6">
                                    <div className="investment text-white">
                                        <p className="mb-2">Sale Price</p>
                                        <h6>1 EDU = 0.00015281 BNB</h6>
                                    </div>
                                </div>
                                <div className="col-md-2 col-6">
                                    <div className="investment text-white">
                                        <p className="mb-2">Tokens Offered</p>
                                        <h6>50,000,000.0000 EDU</h6>
                                    </div>
                                </div>
                                <div className="col-md-2 col-6">
                                    <div className="investment text-white">
                                        <p className="mb-2">Single Initial Investment</p>
                                        <h6>0.1 BNB</h6>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6">
                                    <div className="investment text-white">
                                        <p className="mb-2">Hard cap per user</p>
                                        <h6>300000 EDU = 45.8429 BNB (≈ 14198 USD)</h6>
                                    </div>
                                </div>
                                </div>
                        </div>
                        
                        <div className="row">
                        <div className="col-md-8">
                            <div className="investment_para text-white">
                                <h4>Open Campus - A Web3 education protocol to tokenize educational content</h4>
                                <h5>Project Introduction</h5>
                                <p>Open Campus Protocol is a web3 education protocol for tokenizing educational content by enabling teachers and creators to monetize the content that they created. Its mission is to ensure teachers’ contribution to the society is recognised and provide a global, alternative education system for learners worldwide.</p>
                                <p>TinyTap is Open Campus Protocol’s first adopter, it is known for its no-code authoring platform that has produced over 250k interactive lessons to date. It is a top 10 grossing kids mobile application on the App Store and Google Play with 9 million+ families and 100k+ content creators.</p>
                                <p>EDU is the native token of the Open Campus ecosystem (including TinyTap), where EDU can be used on the Open Campus platform and participate in Open Campus DAO governance proposals to guide the direction of the protocol development.</p>


                              
                                <h5>Key Features and Highlights</h5>
                                <p>Open Campus Protocol is building an infrastructure for a diverse ecosystem of adopters including schools, edtechs and other educational providers to bring billions of educators and learners worldwide to web3</p>
                                <p>Existing Web2 product -- TinyTap: founded in 2012, consistently ranked in the Top 10 Grossing Kids Application worldwide with 250k+ games, partnered with prominent brands and publishers including Sesame Street, Oxford University Press</p>
                                <p>Publisher NFTs: educational content can be tokenized into NFTs. Revenue generated from the content will be stored in a smart contract, co-owners will be able to withdraw based on their contribution</p>
                                <p>Marketplace: the publisher NFTs issued by Open Campus adopters (e.g. TinyTap) can be bought and sold in $EDU</p>
                                <p>Ecosystem partners: onboarded partners including GEMS Education, one of the largest private school networks in the world with over 60 schools and 130k+ students across North Africa and the Middle East, and many others including Animoca Brands, Sandbox, Hooked, and Highstreet</p>
                            </div>


                            <div className="detail">
                            <div className="investment_para text-white">
                                <h4>Open Campus Token Sale and Economics</h4>
                                </div>
                            <table className="table">
                           
                            <tbody>
                                <tr>
                                    <td>Hard Cap</td>
                                    <th>2,500,000 USD</th>
                                </tr>
                                <tr>
                                    <td>Total Token Supply</td>
                                    <th>1,000,000,000 EDU</th>
                                </tr>
                                <tr>
                                    <td>Initial Circulating Supply</td>
                                    <th>14.50% of Total Token Supply</th>
                                </tr>
                                <tr>
                                    <td>Public Sale Token Price</td>
                                    <th>0.05 USD (price in BNB will be determined prior to the start of subscription)</th>
                                </tr>
                                <tr>
                                    <td>Tokens Offered</td>
                                    <th>50,000,000 EDU</th>
                                </tr>
                                <tr>
                                    <td>Hard Cap Per User</td>
                                    <th>15,000 USD (price in BNB will be determined prior to the start of subscription)</th>
                                </tr>
                                <tr>
                                    <td>Token Sale Vesting Period</td>
                                    <th>No lockup</th>
                                </tr>
                                <tr>
                                    <td>Token Type</td>
                                    <th>BEP20</th>
                                </tr>
                                <tr>
                                    <td>Token Distribution</td>
                                    <th>After the end of token sale</th>
                                </tr>
                            </tbody>
                            </table>
                            </div>

                        </div>
                        
                        <div className="col-md-4">
                            <div className="social_channel">
                                <h5>Social Channels</h5>
                                <ul>
                                    <li>
                                        <i className="fa fa-twitter"></i><a href="#">http://discord.gg/opencampus</a>
                                    </li>
                                    <li>
                                        <i className="fa fa-facebook"></i><a href="#">https://twitter.com/opencampus_xyz</a>
                                    </li>
                                    <li>
                                        <i className="fa fa-linkedin"></i><a href="#">https://opencampus-xyz.medium.com/</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                       
                       
                    </div>
            </div>
        </div>
     </>   
    )
}

export default Launchpaddetail;