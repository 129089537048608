import React from 'react'
import Footer from './Footer'
import { Link } from 'react-router-dom'

export default function Trade() {
  return (
    <div>
        <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/"> <i className="fa fa-angle-left"></i> </Link>
                                    Trading
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className="my-5">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="block-text text-center">
                        <h3 className="heading1">Trading Rules ｜ Bidexpro</h3>
                    </div>

                    <div className="market_table mt-5 table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                            <th scope="col" className='fs-6'>Pair</th>
                            <th scope="col" className='fs-6'>Trade Amount</th>
                            <th scope="col" className='fs-6'>Amount</th>
                            <th scope="col" className='fs-6'>Price</th>
                            <th scope="col" className='fs-6'>Order</th>
                            <th scope="col" className='fs-6'>Market Order</th>
                            <th scope="col" className='fs-6'>Limit Orders</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td scope="row"> BTC/USDT</td>
                            <td>0.00001 BTC</td>
                            <td className="green">	0.00001 BTC</td>
                            <td>0.01 USDT</td>
                            <td>10 USDT</td>
                            <td>152.98939734 BTC</td>
                            <td>200</td>
                            </tr>
                            <tr>
                            <td scope="row"> BTC/USDT</td>
                            <td>0.00001 BTC</td>
                            <td className="green">	0.00001 BTC</td>
                            <td>0.01 USDT</td>
                            <td>10 USDT</td>
                            <td>152.98939734 BTC</td>
                            <td>200</td>
                            </tr>
                            <tr>
                            <td scope="row"> BTC/USDT</td>
                            <td>0.00001 BTC</td>
                            <td className="green">	0.00001 BTC</td>
                            <td>0.01 USDT</td>
                            <td>10 USDT</td>
                            <td>152.98939734 BTC</td>
                            <td>200</td>
                            </tr>
                            <tr>
                            <td scope="row"> BTC/USDT</td>
                            <td>0.00001 BTC</td>
                            <td className="green">	0.00001 BTC</td>
                            <td>0.01 USDT</td>
                            <td>10 USDT</td>
                            <td>152.98939734 BTC</td>
                            <td>200</td>
                            </tr>
                            <tr>
                            <td scope="row"> BTC/USDT</td>
                            <td>0.00001 BTC</td>
                            <td className="green">	0.00001 BTC</td>
                            <td>0.01 USDT</td>
                            <td>10 USDT</td>
                            <td>152.98939734 BTC</td>
                            <td>200</td>
                            </tr>
                            <tr>
                            <td scope="row"> BTC/USDT</td>
                            <td>0.00001 BTC</td>
                            <td className="green">	0.00001 BTC</td>
                            <td>0.01 USDT</td>
                            <td>10 USDT</td>
                            <td>152.98939734 BTC</td>
                            <td>200</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    

                </div>
            </div>
        </div>
    </section>

    <Footer />

    </div>
  )
}
