import React from "react";
import { Link } from "react-router-dom";

import Footer from './Footer';
function Fee(){
    return(
        <>
            <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/" className="text-white"><i className="fa fa-angle-left m-0"></i> </Link>  &nbsp;
                                Trading Fee
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                        <div className="col-md-12">
                            <div className="box_heading text-center">
                            <h3 className="heading1">WELCOME TO BIDEXPRO EXCHANGE</h3>
                                <p className="mt-3 fs-5">**World's Next Generation Digital Assets Exchange Intuitive User Interface | Advanced Features | Maximum Security</p>
                                </div>
                            </div>
                        </div>
                </div>

            
                <section className="work">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          

            <div className="work__main">
              <div className="work-box">
                <div className="image">
                  <img src="web/Cloud.png" alt="" />
                </div>
                <div className="content">
              
                  <a href="#" className="title">Trading Fees</a>
                  <p className="text">
                  Maker Fees & Taker Fees
                  </p>
                </div>
                <img className="line" src="web/connect-line.png" alt="" />
              </div>
              <div className="work-box">
                <div className="image">
                  <img src="web/Wallet.png" alt="" />
                </div>
                <div className="content">
                  
                  <a href="#" className="title">Fee for deposit</a>
                  <p className="text">
                  Free
                  </p>
                </div>
                <img className="line" src="web/connect-line.png" alt="" />
              </div>
              <div className="work-box">
                <div className="image">
                  <img src="web/Mining.png" alt="" />
                </div>
                <div className="content">
                  
                  <a href="#" className="title">Withdrawal charges</a>
                  <p className="text">
                    Withdrawal fees are regularly adjusted according to blockchain conditions.
                  </p>
                </div>  
              </div>
              
            </div>


            
          </div>
        </div>
      </div>
    </section>      
           
    <section className="window">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-12">
            <ul className="nav nav-tabs border-bottom-0 justify-content-center" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">SPOT TRADING</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">FUTURE</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">TAKER FEE</button>
            </li>
            </ul>
            <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div className="t_content text-center mt-5 text-white m-auto">

                <div className="campus_box">
                <h3 className="text-start mb-3">SPOT TRADING</h3>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Level</th>
                      <th>30d Trade Volume (BUSD)</th>
                      <th>and/or</th>
                      <th>BNB Balance</th>
                      <th>Maker / Taker</th>
                      <th>BNB 25% off</th>
                    </tr>
                  </thead>
   <tbody>
      <tr>
         <td scope="row">Regular User</td>
         <td>1,000,000 BUSD</td>
         <td>or</td>
         <td>0 BNB</td>
         <td>0.1000% / 0.1000%</td>
         <td>0.0750% / 0.0750%</td>
      </tr>
      <tr>
         <td scope="row">Regular User</td>
         <td>1,000,000 BUSD</td>
         <td>or</td>
         <td>0 BNB</td>
         <td>0.1000% / 0.1000%</td>
         <td>0.0750% / 0.0750%</td>
      </tr>
      <tr>
         <td scope="row">Regular User</td>
         <td>1,000,000 BUSD</td>
         <td>or</td>
         <td>0 BNB</td>
         <td>0.1000% / 0.1000%</td>
         <td>0.0750% / 0.0750%</td>
      </tr>
      <tr>
         <td scope="row">Regular User</td>
         <td>1,000,000 BUSD</td>
         <td>or</td>
         <td>0 BNB</td>
         <td>0.1000% / 0.1000%</td>
         <td>0.0750% / 0.0750%</td>
      </tr>
      <tr>
         <td scope="row">Regular User</td>
         <td>1,000,000 BUSD</td>
         <td>or</td>
         <td>0 BNB</td>
         <td>0.1000% / 0.1000%</td>
         <td>0.0750% / 0.0750%</td>
      </tr>
      <tr>
         <td scope="row">Regular User</td>
         <td>1,000,000 BUSD</td>
         <td>or</td>
         <td>0 BNB</td>
         <td>0.1000% / 0.1000%</td>
         <td>0.0750% / 0.0750%</td>
      </tr>
   </tbody>
</table>
                </div>

                
                    <p className="fs-6 mt-4 text-start">Meanwhile, when you place an order that doesn’t fill immediately (like a limit order), you are a “maker,” and you can expect to pay a reduced “maker” fee for this. As noted above, this terminology comes from the fact that placing limit orders on the books helps to “make the market,” (it makes you a “market maker”).</p>
                    
                </div>
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div className="t_content text-center mt-5 text-white m-auto">

<div className="campus_box">
<h3 className="text-start mb-3">SPOT TRADING</h3>
<table class="table">
  <thead>
    <tr>
      <th>Level</th>
      <th>30d Trade Volume (BUSD)</th>
      <th>and/or</th>
      <th>BNB Balance</th>
      <th>Maker / Taker</th>
      <th>BNB 25% off</th>
    </tr>
  </thead>
<tbody>
<tr>
<td scope="row">Regular User</td>
<td>1,000,000 BUSD</td>
<td>or</td>
<td>0 BNB</td>
<td>0.1000% / 0.1000%</td>
<td>0.0750% / 0.0750%</td>
</tr>
<tr>
<td scope="row">Regular User</td>
<td>1,000,000 BUSD</td>
<td>or</td>
<td>0 BNB</td>
<td>0.1000% / 0.1000%</td>
<td>0.0750% / 0.0750%</td>
</tr>
<tr>
<td scope="row">Regular User</td>
<td>1,000,000 BUSD</td>
<td>or</td>
<td>0 BNB</td>
<td>0.1000% / 0.1000%</td>
<td>0.0750% / 0.0750%</td>
</tr>
<tr>
<td scope="row">Regular User</td>
<td>1,000,000 BUSD</td>
<td>or</td>
<td>0 BNB</td>
<td>0.1000% / 0.1000%</td>
<td>0.0750% / 0.0750%</td>
</tr>
<tr>
<td scope="row">Regular User</td>
<td>1,000,000 BUSD</td>
<td>or</td>
<td>0 BNB</td>
<td>0.1000% / 0.1000%</td>
<td>0.0750% / 0.0750%</td>
</tr>
<tr>
<td scope="row">Regular User</td>
<td>1,000,000 BUSD</td>
<td>or</td>
<td>0 BNB</td>
<td>0.1000% / 0.1000%</td>
<td>0.0750% / 0.0750%</td>
</tr>
</tbody>
</table>
</div>


    <p className="fs-6 mt-4 text-start">Meanwhile, when you place an order that doesn’t fill immediately (like a limit order), you are a “maker,” and you can expect to pay a reduced “maker” fee for this. As noted above, this terminology comes from the fact that placing limit orders on the books helps to “make the market,” (it makes you a “market maker”).</p>
    
</div>
            </div>
            <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                <div className="t_content text-center mt-5 text-white w-50 m-auto">
                    <h3>Taker Fee</h3>
                    <p className="fs-6">When you place an order that is immediately filled in its entirety (for example a market or stop order) you are a “taker,” and you pay a “taker” fee for this. The idea is that you are “taking” the price you want, right now, generally by buying or selling limit orders sitting on the books</p>
                </div>
            </div>
            </div>
            

            
          </div>
        </div>
      </div>
    </section>

        <Footer />

        </>
    );
}

export default Fee;