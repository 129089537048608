import React from "react";
import { Link } from "react-router-dom";
// import  { useState } from "react";
function Layout(props){
return(
<>
<div className="wrapper">
   <header className="header">
      <div className="container-fluid">
         <div className="row align-items-center">
            <div className="col-md-12">
               <nav className="navbar navbar-expand-lg navbar-light">
                  <div className="container-fluid">
                     <Link className="navbar-brand" to="/">
                     <img src="assets/logo.png" className="img-fluid" /></Link>
                     <div className="d-flex align-items-center">
                     <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                     <span className="navbar-toggler-icon"></span>
                     </button>

                     <div className="d-resp">
                              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="/banner/profile.png" alt="" className="img-fluid profile_img" />
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                 <li>
                                    <div className="d-flex align-items-center pb-4 mx-7 border-bottom">
                                       <img src="assets/user-1.jpg" className="rounded-circle" width="60" height="60" alt="" />
                                       <div className="ms-3">
                                          <h5 className="mb-1 fs-6 text-white">Mathew Anderson</h5>
                                          <p className="mb-0 d-flex text-white align-items-center gap-2">
                                             <i className="fa fa-envelope-open-o fs-6"></i> info@modernize.com
                                          </p>
                                          <div className="d-flex justify-content-between align-items-center verify">
                                      <p> <img src="/banner/verified.png" className="img-fluid" alt="" /> Verified</p>
                                  </div>
                                       </div>
                                    </div>
                                 </li>
                                 {/* <li>
                                    <div className="d-flex align-items-center">
                                       <div className="profile-img">
                                          <img src="assets/icon-account.svg" className="img-fluid" />
                                       </div>
                                       <Link className="dropdown-item" to="/profile">
                                       Dashboard</Link>
                                    </div>
                                 </li> */}
                                 <li>
                                    <div className="d-flex align-items-center">
                                       <div className="profile-img">
                                          <img src="assets/icon-inbox.svg" className="img-fluid" />
                                       </div>
                                       <Link  className="dropdown-item" to="/security">
                                       Security</Link>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="d-flex align-items-center">
                                       <div className="profile-img">
                                          <img src="assets/icon-tasks.svg" className="img-fluid" />
                                       </div>
                                       <Link className="dropdown-item" to="/myreferral">
                                       Referral Reward</Link>
                                    </div>
                                 </li>
                                 <li>
                                    <button className="btn btn-primary w-100">Log Out</button>
                                 </li>
                              </ul>
                              </div>
                              </div>
                     <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                           <li className="nav-item">
                              <Link className="nav-link active" aria-current="page" to="/exchange">
                              Exchange</Link>
                           </li>
                           <li className="nav-item">
                              <Link className="nav-link" aria-current="page" to="/launchpad">
                              Launchpad</Link>
                           </li>
                           <li className="nav-item">
                              <Link className="nav-link" aria-current="page" to="/market">
                              Market</Link>
                           </li>
                           <li className="nav-item">
                              <a className="nav-link" aria-current="page" href="https://dex.bidexpro.com">
                              Swap</a>
                           </li>
                        </ul>
                        <form className="resp_drop">
                           <div className="dropdown summary">
                              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              Wallet
                              </button>
                              <ul className="dropdown-menu pb-0" aria-labelledby="dropdownMenuButton1">
                                 <li><a className="dropdown-item" href="/wallet"><img src="banner/wallet1.png" className="img-fluid" alt="wallet" /> &nbsp; Wallet Summary</a></li>
                                 <li><a className="dropdown-item" href="/transactionhistory"><img src="banner/transaction.png" className="img-fluid" alt="wallet" /> &nbsp; Transaction</a></li>
                                 <li><a className="dropdown-item" href="/cryptowithdrawl"><img src="banner/withdrawal.png" className="img-fluid" alt="wallet" /> &nbsp; Withdrawal</a></li>
                              </ul>
                           </div>
                           <div className="dropdown profile">
                              <Link to="/orderhistory" className="btn btn-secondary">
                              Order    
                              </Link>
                              <Link to="/login" className="btn btn-secondary">
                              Login
                              </Link>
                              <Link to="/signup" className="btn btn-secondary">
                              SignUp
                              </Link>
                              <Link to="/deposit" className="btn btn-primary ps-3">
                              <img src="/banner/deposit.png" className="img-fluid wallet" alt="" />  Deposit
                              </Link>&nbsp;&nbsp;
                              <span className="d-desk">
                              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="/banner/profile.png" alt="" className="img-fluid profile_img" />
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                               
                                 <li>
                                    <div className="d-flex align-items-center pb-4 mx-7 border-bottom">
                                       <img src="assets/user-1.jpg" className="rounded-circle" width="60" height="60" alt="" />
                                       <div className="ms-3">
                                          <h5 className="mb-1 fs-6 text-white">Mathew Anderson</h5>
                                          <p className="mb-0 d-flex text-white align-items-center gap-2">
                                             <i className="fa fa-envelope-open-o fs-6"></i> info@modernize.com
                                          </p>
                                          <div className="d-flex justify-content-between align-items-center verify">
                                      <p> <img src="/banner/verified.png" className="img-fluid" alt="" /> Verified</p>
                                  </div>
                                       </div>
                                    </div>
                                 </li>
                                 {/* <li>
                                    <div className="d-flex align-items-center">
                                       <div className="profile-img">
                                          <img src="assets/icon-account.svg" className="img-fluid" />
                                       </div>
                                       <Link className="dropdown-item" to="/profile">
                                       Dashboard</Link>
                                    </div>
                                 </li> */}
                                 <li>
                                    <div className="d-flex align-items-center">
                                       <div className="profile-img">
                                          <img src="assets/icon-inbox.svg" className="img-fluid" />
                                       </div>
                                       <Link  className="dropdown-item" to="/security">
                                       Security</Link>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="d-flex align-items-center">
                                       <div className="profile-img">
                                          <img src="assets/icon-tasks.svg" className="img-fluid" />
                                       </div>
                                       <Link className="dropdown-item" to="/referralrewards">
                                       Referral Reward</Link>
                                    </div>
                                 </li>
                                 <li>
                                    <button className="btn btn-primary w-100">Log Out</button>
                                 </li>
                              </ul>
                              </span>
                           </div>
                        </form>
                     </div>
                  </div>
               </nav>
            </div>
         </div>
      </div>
   </header>
</div>
</>
)
}
export default Layout;