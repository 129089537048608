const  NetworkArray = [
    {
       NetworkSymbol: "BNB",
       NetworkName: "BNB Beacon Coin (BEP20)"
       
    },
    {
        NetworkSymbol: "BSC",
        NetworkName:  "BNB Smart Chain (BEP20)"
     },
   
    {
      NetworkSymbol: "ETH",
      NetworkName:  "Ethereum (ERC20)"
   },
   {
      NetworkSymbol: "SOL",
      NetworkName:  "Solana"
      
   },
    {
       NetworkSymbol: "TRX",
       NetworkName:  "Tron(TRC20)"
    }
    
 ];

 export default  NetworkArray;