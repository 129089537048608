import React from 'react'
import Trading from '../Dashboardpages/Exchange/Trading'
import Footer from './Footer'
import { Link } from 'react-router-dom'


export default function MarketDetail() {
  return (
    <div>
        <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/"> <i className="fa fa-angle-left"></i> </ Link>
                                Market Detail
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className=''>
                <div className='container'>
                    <div className='row' >
                        <div className='col-md-8'>
                            <Trading/>
                            <div className='r_snm text-white py-5'>
                                <h2>SNM Price Live Data</h2>
                                <p>The live price of SONM (BEP-20) is $ 0.4320158 per (SNM / USD) today with a current market cap of $ 19.18M USD. 24-hour trading volume is $ 7.94M USD. SNM to USD price is updated in real-time. SONM (BEP-20) is -14.80% in the last 24 hours. It has a circulating supply of 44.40M USD.</p>

                                <h4 className='mt-4'>SNM Price Performance USD</h4>

                                <div className='table-responsive'>
                                <table className='table mt-4'>
                                    <thead>
                                        <tr>
                                            <th>
                                                Change
                                            </th>
                                            <th>
                                                Amount
                                            </th>
                                            <th>
                                                %
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Today
                                            </td>
                                            <td>
                                                $ -0.0639538
                                            </td>
                                            <td>
                                                -14.80%
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                30 Days
                                            </td>
                                            <td>
                                                $ -0.172383
                                            </td>
                                            <td>
                                                -39.90%
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                60 Days
                                            </td>
                                            <td>
                                                $ -0.1854986
                                            </td>
                                            <td>
                                                -42.94%
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                90 Days
                                            </td>
                                            <td>
                                                $ -0.2308383
                                            </td>
                                            <td>
                                                -53.43%
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>

                                <h4 className='mt-4'>SNM Price Information</h4>
                                

                                <div className='table-responsive'>
                                <table className='table mt-4'>
                                    <thead>
                                        <tr>
                                            <th>
                                            All Time High 
                                            </th>
                                            <th>
                                            Price Change (1h)
                                            </th>
                                            <th>
                                            Price Change (24h)
                                            </th>
                                            <th>
                                            Price Change (7d)
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                            $ 13.54
                                            </td>
                                            <td>
                                            -0.41%
                                            </td>
                                            <td>
                                            -14.80%
                                            </td>
                                            <td>
                                            +3.3%
                                            </td>
                                        </tr>
                                        
                                      
                                    </tbody>
                                </table>
                                </div>

                                <h4 className='mt-4'>SNM Market Information</h4>
                                
                                <div className="table-responsive">
                                <table className='table mt-4'>
                                    <thead>
                                        <tr>
                                            <th>
                                            Popularity
                                            </th>
                                            <th>
                                            Market Cap
                                            </th>
                                            <th>
                                            Volume (24hours)
                                            </th>
                                            <th>
                                            Circulation Supply
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                            #631
                                            </td>
                                            <td>
                                            $ 19.18M
                                            </td>
                                            <td>
                                            $ 7.94M
                                            </td>
                                            <td>
                                            44.40M
                                            </td>
                                        </tr>
                                        
                                      
                                    </tbody>
                                </table>
                                </div>

                                <h4 className='mt-4'>SNM to Local Currency</h4>
                                
                                <div className='table-responsive'>
                                <table className='table mt-4'>
                                    <tbody>
                                        <tr>
                                            <td>
                                            1 SNM to TRY
                                            </td>
                                            <td>
                                            ₺ 8.5539126
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            1 SNM to TRY
                                            </td>
                                            <td>
                                            ₺ 8.5539126
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            1 SNM to TRY
                                            </td>
                                            <td>
                                            ₺ 8.5539126
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            1 SNM to TRY
                                            </td>
                                            <td>
                                            ₺ 8.5539126
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            1 SNM to TRY
                                            </td>
                                            <td>
                                            ₺ 8.5539126
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            1 SNM to TRY
                                            </td>
                                            <td>
                                            ₺ 8.5539126
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            1 SNM to TRY
                                            </td>
                                            <td>
                                            ₺ 8.5539126
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            1 SNM to TRY
                                            </td>
                                            <td>
                                            ₺ 8.5539126
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            1 SNM to TRY
                                            </td>
                                            <td>
                                            ₺ 8.5539126
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            1 SNM to TRY
                                            </td>
                                            <td>
                                            ₺ 8.5539126
                                            </td>
                                        </tr>
                                      
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div>
                                <div className="snm_price_cal">
                                    <h3>SNM Price Calculator</h3>

                                    <div className="buy_box">
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <form action="">
                                                    <div className="form-group">
                                                        <label className="form-label text-white">Buy</label>
                                                        <input type="text" name="" className='form-control' placeholder='1' />
                                                    </div>
                                                </form>
                                            </div>
                                            <div className='col-md-4'>
                                                    <div className='snm_img d-flex align-items-center'>
                                                        <img src="coin-icon/9931.png" className='img-fluid' />
                                                        <h6 className='m-0'>SNM</h6>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='d-flex justify-content-between py-4 text-white'>
                                            <h4>1 SNM =</h4>
                                            <h4>USD $0.4320158</h4>
                                        </div> 
                                        <button className="btn btn-primary w-100">
                                            Buy SNM
                                        </button>
                                    </div>

                                    <div className='coinbase mt-3'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-1'>
                                                <i className='fa fa-exclamation'></i>
                                            </div>
                                            <div className='col-md-11'>
                                                <div className='coin_text'>
                                                    <p className='m-0'>Bidexpro has the lowest transaction fee rate amongst all major trading platforms.</p>
                                                </div>
                                            </div>

                                            <div className='row align-items-center py-2'>
                                                <div className='col-md-4'>
                                                    <h5 className='m-0 fs-6'>Coinbase</h5>
                                                </div>
                                                <div className='col-md-8'>
                                                    <div className="progress">
                                                        <div className="progress-bar" role="progressbar" style={{width: "90%",backgroundColor:"#F6465D"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                </div>


                                                <div className='row align-items-center py-2'>
                                                <div className='col-md-4'>
                                                    <h5 className='m-0 fs-6'>Kraken</h5>
                                                </div>
                                                <div className='col-md-8'>
                                                    <div className="progress">
                                                        <div className="progress-bar" role="progressbar" style={{width: "50%",backgroundColor:"#F6465D"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                </div>


                                                <div className='row align-items-center py-2'>
                                                <div className='col-md-4'>
                                                    <h5 className='m-0 fs-6'>Coinbase</h5>
                                                </div>
                                                <div className='col-md-8'>
                                                    <div className="progress">
                                                        <div className="progress-bar" role="progressbar" style={{width: "25%",backgroundColor:"#0ECB81"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                </div>   
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="n_table my-4">
                                <h3 className="text-white my-3">Trending cryptos</h3>
                                <div className='table-responsive'>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>
                                            <div className='mt-3'><img src="coin-icon/bnb.png" alt="" className="img-fluid" />BNB</div>
                                            </td>
                                            <td>
                                                <div>
                                                    <h6>$308.50</h6>
                                                    <p className='m-0'>-0.66%</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div className='mt-3'><img src="coin-icon/eth.png" alt="" className="img-fluid" />ETH</div>
                                            </td>
                                            <td>
                                                <div>
                                                    <h6>$308.50</h6>
                                                    <p className='m-0'>-0.66%</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div className='mt-3'><img src="coin-icon/btc.png" alt="" className="img-fluid" />BTC</div>
                                            </td>
                                            <td>
                                                <div>
                                                    <h6>$308.50</h6>
                                                    <p className='m-0'>-0.66%</p>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                            <div className='mt-3'><img src="coin-icon/bnb.png" alt="" className="img-fluid" />BNB</div>
                                            </td>
                                            <td>
                                                <div>
                                                    <h6>$308.50</h6>
                                                    <p className='m-0'>-0.66%</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div className='mt-3'><img src="coin-icon/eth.png" alt="" className="img-fluid" />ETH</div>
                                            </td>
                                            <td>
                                                <div>
                                                    <h6>$308.50</h6>
                                                    <p className='m-0'>-0.66%</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div className='mt-3'><img src="coin-icon/btc.png" alt="" className="img-fluid" />BTC</div>
                                            </td>
                                            <td>
                                                <div>
                                                    <h6>$308.50</h6>
                                                    <p className='m-0'>-0.66%</p>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                            <div className='mt-3'><img src="coin-icon/bnb.png" alt="" className="img-fluid" />BNB</div>
                                            </td>
                                            <td>
                                                <div>
                                                    <h6>$308.50</h6>
                                                    <p className='m-0'>-0.66%</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div className='mt-3'><img src="coin-icon/eth.png" alt="" className="img-fluid" />ETH</div>
                                            </td>
                                            <td>
                                                <div>
                                                    <h6>$308.50</h6>
                                                    <p className='m-0'>-0.66%</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div className='mt-3'><img src="coin-icon/btc.png" alt="" className="img-fluid" />BTC</div>
                                            </td>
                                            <td>
                                                <div>
                                                    <h6>$308.50</h6>
                                                    <p className='m-0'>-0.66%</p>
                                                </div>
                                            </td>
                                        </tr>

                                        
                                    </tbody>
                                </table>
                                </div>
                                </div>

                        </div>
                    </div>
                </div>
            </section>


        <Footer />

    </div>
  )
}
