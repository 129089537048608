import React from "react";
import Footer from "./Footer";
import {  Link } from "react-router-dom";

function Home() {
  return (
    <>
      <section className="banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-md-12">
              <div className="banner__content">
                <h2 className="title">
                  Buy &amp; Sell Digital Assets In The Rockie
                </h2>
                <p className="fs-20 desc">
                  Coin rockie is the easiest, safest, and fastest way to buy
                  &amp; sell crypto asset exchange.
                </p>
                <a href="#" className="btn btn-primary">
                  <span>Get started now</span>
                </a>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="banner__image">
                <img src="banner/banner-01.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="my-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block-text text-center">
                <h3 className="heading1">
                  Acquire Bidexpro Cryptocurrency <br />
                  Exchange Market Here.
                </h3>
                <p className="desc">
                  Trade any Crypto asset with minimum Gas Fees and Less
                  Transaction Charge, especially <br />
                  assisted for Hardcore Traders.
                </p>
              </div>

              <div className="market_table mt-5 table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Market Price</th>
                      <th scope="col">Price</th>
                      <th scope="col">Volume (24h)</th>
                      <th scope="col">Circulating Supply</th>
                      <th scope="col">Change (24h)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">
                        {" "}
                        <img
                          src="/coin-icon/ethereum1.png"
                          className="img-fluid"
                        />{" "}
                      </td>
                      <td>52.08000000 BTC</td>
                      <td className="green">$3054.9909</td>
                      <td>0.00001005 BTC</td>
                      <td>0.00001005 BTC</td>
                      <td>-0.50%</td>
                    </tr>
                    <tr>
                      <td scope="row">
                        {" "}
                        <img
                          src="/coin-icon/bnb.png"
                          className="img-fluid"
                        />{" "}
                      </td>
                      <td>52.08000000 BTC</td>
                      <td className="green">$3054.9909</td>
                      <td>0.00001005 BTC</td>
                      <td>0.00001005 BTC</td>
                      <td>-0.50%</td>
                    </tr>
                    <tr>
                      <td scope="row">
                        {" "}
                        <img
                          src="/coin-icon/maker.png"
                          className="img-fluid"
                        />{" "}
                      </td>
                      <td>52.08000000 BTC</td>
                      <td className="green">$3054.9909</td>
                      <td>0.00001005 BTC</td>
                      <td>0.00001005 BTC</td>
                      <td>-0.50%</td>
                    </tr>
                    <tr>
                      <td scope="row">
                        {" "}
                        <img
                          src="/coin-icon/omisego.png"
                          className="img-fluid"
                        />{" "}
                      </td>
                      <td>52.08000000 BTC</td>
                      <td className="green">$3054.9909</td>
                      <td>0.00001005 BTC</td>
                      <td>0.00001005 BTC</td>
                      <td>-0.50%</td>
                    </tr>
                    <tr>
                      <td scope="row">
                        {" "}
                        <img
                          src="/coin-icon/0x.png"
                          className="img-fluid"
                        />{" "}
                      </td>
                      <td>52.08000000 BTC</td>
                      <td className="green">$3054.9909</td>
                      <td>0.00001005 BTC</td>
                      <td>0.00001005 BTC</td>
                      <td>-0.50%</td>
                    </tr>
                    <tr>
                      <td scope="row">
                        {" "}
                        <img
                          src="/coin-icon/aurora.png"
                          className="img-fluid"
                        />{" "}
                      </td>
                      <td>52.08000000 BTC</td>
                      <td className="green">$3054.9909</td>
                      <td>0.00001005 BTC</td>
                      <td>0.00001005 BTC</td>
                      <td>-0.50%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our_products">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block-text text-center mb-5 mt-0">
                <h3 className="heading1">Our Products</h3>
                <p className="desc">
                  Do more with Bidexpro exchange, get multiple products on a
                  single platform.
                </p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="product_box text-center">
                <i className="fa fa-shield "></i>
                <h4>Secure storage</h4>
                <p>
                  We store the vast majority of the digital assets in secure
                  offline storage.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn more
                </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="product_box text-center">
                <i className="fa fa-shield "></i>
                <h4>Protected by insurance</h4>
                <p>
                  Cryptocurrency stored on our servers is covered by our
                  insurance policy.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn more
                </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="product_box text-center">
                <i className="fa fa-shield "></i>
                <h4>Industry best practices</h4>
                <p>
                  Tradientsupports a variety of the most popular digital
                  currencies.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn more
                </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="product_box text-center">
                <i className="fa fa-shield "></i>
                <h4>Industry best practices</h4>
                <p>
                  Tradientsupports a variety of the most popular digital
                  currencies.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div
                className="about__content aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h3 className="heading1">
                  Tight Spreads and Ultra-fast execution.
                </h3>

                <p className="text-white fs-5 m-0">
                  Enhance your trading with the Bidexpro exchange and enjoy
                  multiple related benefits.
                </p>

                <ul className="mt-4">
                  <li>
                    {" "}
                    <i
                      className="fa fa-caret-right"
                      aria-hidden="true"
                    ></i>{" "}
                    Protect Against Negative Balances
                  </li>
                  <li>
                    {" "}
                    <i
                      className="fa fa-caret-right"
                      aria-hidden="true"
                    ></i>{" "}
                    Managed and Segregated Investment Pool
                  </li>
                  <li>
                    {" "}
                    <i
                      className="fa fa-caret-right"
                      aria-hidden="true"
                    ></i>{" "}
                    Deposit & Withdraw Money Instantly
                  </li>
                </ul>

                <a href="#" className="btn btn-primary">
                  Explore More
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about_img">
                <img src="web/about-h1.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our_products">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block-text text-center mb-5 mt-0">
                <h3 className="heading1">
                  Most Valued Cryptocurrency Exchange Platform
                </h3>
                <p className="desc">
                  Here are a few reasons to choose the most valuable
                  Cryptocurrency Exchange Platform :
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="product_box text-center">
                <i className="fa fa-shield "></i>
                <h4>Secure storage</h4>
                <p>
                  We have secure storage facilities maintained to store digital
                  assets easily.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn more
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="product_box text-center">
                <i className="fa fa-shield "></i>
                <h4>Protected by Blockchain</h4>
                <p>
                  We have implemented the use of Blockchain technology to
                  protect Cryptocurrencies.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn more
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="product_box text-center">
                <i className="fa fa-shield "></i>
                <h4>Industry Professionalism</h4>
                <p>
                  We drive and support industrial professionalism with expert
                  solutions.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="block-text text-center mb-5 mt-0">
                <h3 className="heading1">
                  Enhance Your Bidexpro Cryptocurrency <br />
                  Portfolio Today
                </h3>
                <p className="desc">
                  Here are a few reasons to choose the most valuable
                  Cryptocurrency Exchange Platform :
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="portfolio">
                <div className="media">
                  <span className="port-icon">
                    {" "}
                    <i className="fa fa-bar-chart"></i>
                  </span>
                  <div className="media-body">
                    <h4>Manage your Portfolio</h4>
                    <p>
                      With Bidexpro, upgrade your portfolio to maintain trading
                      and tracking in one place.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <span className="port-icon">
                    {" "}
                    <i className="fa fa-calendar-check-o"></i>
                  </span>
                  <div className="media-body">
                    <h4>Recurring Buys</h4>
                    <p>
                      Invest in cryptocurrencies with limited pace and schedule
                      buys daily, weekly, and monthly.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <span className="port-icon">
                    {" "}
                    <i className="fa fa-lock"></i>
                  </span>
                  <div className="media-body">
                    <h4>Vault Protection</h4>
                    <p>
                      For faster trading, store Crypto assets in Bidexpro Vault
                      and prevent delayed withdrawals.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <span className="port-icon">
                    {" "}
                    <i className="fa fa-android"></i>
                  </span>
                  <div className="media-body">
                    <h4>Mobile Apps</h4>
                    <p>
                      Get Bidexpro Android or iOS mobile application to stay on
                      top of the trading markets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about_img">
                <img src="banner/portfolio.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our_products">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block-text text-center mb-5 mt-0">
                <h3 className="heading1">Powerful and easy-to-use Features</h3>
                <p className="desc">
                  Online trading can be complex and confusing, thus get the
                  Bidexpro simplified and efficient
                  <br /> IQ option enhanced with intuitive tools.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="app_screen">
                <h4>
                  Integrated Bidexpro Smart Newsfeeds for informed trading.
                </h4>
                <div className="app_area">
                  <img src="banner/app-1.png" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="app_screen">
                <h4>Use Multiple Indicators for wiser trading.</h4>
                <div className="app_area">
                  <img src="banner/app-2.png" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="app_screen">
                <h4>Get assisted by a Supportive Community of traders.</h4>
                <div className="app_area">
                  <img src="banner/app-3.png" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="block-text text-center mb-5 mt-0">
                <h3 className="heading1">Bidexpro Features</h3>
                <p className="desc">
                  Tradient has a variety of features that make it the best place
                  to start trading
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div
                className="about__content aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h3 className="heading1 ">Analysis & Alerts</h3>
                <p className="fs-20 decs">
                  Get the most out of fundamental and technical analysis with
                  our News Feed and Economic Calendars. More than 100 most
                  widely-used technical indicators and widgets. Always stay
                  up-to-date on what is happening in financial markets with our
                  customizable price alerts.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="feature_img">
                <img src="banner/features1.jpg" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our_products">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 ">
              <div className="feature_img">
                <img src="banner/features2.jpg" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="about__content aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h3 className="heading1 ">Analysis & Alerts</h3>
                <p className="fs-20 decs">
                  Get the most out of fundamental and technical analysis with
                  our News Feed and Economic Calendars. More than 100 most
                  widely-used technical indicators and widgets. Always stay
                  up-to-date on what is happening in financial markets with our
                  customizable price alerts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div
                className="about__content aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h3 className="heading1 ">Analysis & Alerts</h3>
                <p className="fs-20 decs">
                  Get the most out of fundamental and technical analysis with
                  our News Feed and Economic Calendars. More than 100 most
                  widely-used technical indicators and widgets. Always stay
                  up-to-date on what is happening in financial markets with our
                  customizable price alerts.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="feature_img">
                <img src="banner/features1.jpg" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Home;
