import React from "react";
import { Link } from "react-router-dom";


import Footer from "./Footer";

function ReferralProgram(){
    return(
        <>
             <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="inner_heading">
                                <h3>
                                <i className="fa fa-angle-left m-0"></i> 
                                <Link to="/" className="text-white"> Referral Program</Link>  
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="breadcrum_btn">
                                <a href="#" className="float-end" >Carrier</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="container">
                <div className="row">
                        <div className="col-md-12">
                            <div className="box_heading text-center">
                                <p className="mt-3 fs-5">Don't just be a Trader do the smart earning through the referral program providing<br />
    affiliates that has the ability to generate income by driving traffic</p>
                                <button className="btn btn-primary">Join Us With Referral</button>
                                </div>
                            </div>
                        </div>
                </div>



                <div className="py-5">
             <div className="container">
            <div className="row align-items-center">
                <div className="col-md-6">
                    <div className="about__content aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                        <h6 className="span_about">Our Philosophy</h6>
                        <h3 className="heading1">We Are Always Thinking In Global</h3>
                       
                       <ul className="mt-4">
                        <li> <i className="fa fa-caret-right" aria-hidden="true"></i> Refer and be a partner with Bidexpro Exchange in the Trading Fees.</li>
                        <li> <i className="fa fa-caret-right" aria-hidden="true"></i> Refer a friend and earn up to 50% in the fee margin.</li>
                        <li> <i className="fa fa-caret-right" aria-hidden="true"></i> Increase your earning by making a chain of referrals.</li>
                        <li> <i className="fa fa-caret-right" aria-hidden="true"></i> Develop your chain and be the part of chain formed by your referrals.</li>
                        <li> <i className="fa fa-caret-right" aria-hidden="true"></i> Be the part of most unique way of earning in the world of BlockChain.</li>
                        <li> <i className="fa fa-caret-right" aria-hidden="true"></i> Build your own community and share the ownership with the best exchange.</li>
                       </ul>
                       
                        <a href="#" className="btn btn-primary">Explore More</a>
                        </div>
                </div>
                <div className="col-md-6">
                    <div className="about_img">
                        <img src="web/about-h1.png" className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
            </div>
           
             </div>   
            <Footer />
        </>
    )
}

export default ReferralProgram;