import React from 'react'
import { Link } from "react-router-dom";


export default function TransactionHistory() {
  return (
    <div>
        <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/" className="text-white"><i className="fa fa-angle-left m-0"></i></Link> &nbsp;
                                Transaction History
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div className="container">
            <div className="row align-items-center">
                <div className="col-md-12">
                    <div className="history_list">
                        <ul className="d-flex ps-0 mt-5">
                            <li>
                            <h6>Deposit</h6>
                            <div className="dropdown">
                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    Deposit
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                                </div>
                            </li>

                            <li>
                            <h6>Time</h6>
                            <div className="dropdown">
                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    Past 30 days
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                                </div>
                            </li>
                            <li>
                            <h6>Asset</h6>
                            <div className="dropdown">
                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    All
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                                </div>
                            </li>

                            <li>
                            <h6>Status</h6>
                            <div className="dropdown">
                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    All
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                                </div>
                            </li>

                            <li>
                            <h6>TxID</h6>
                            <div className="dropdown">
                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    TxID
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                                </div>
                            </li>
                        </ul>

                        <div className="campus_box table-responsive">
                        <table className="table transaction my-5">

                            <thead>
                                <th>Time</th>
                                <th>Type</th>
                                <th>Deposit Wallet</th>
                                <th>Asset</th>
                                <th>Amount</th>
                                <th>Destination</th>
                                <th>TxID</th>
                                <th>Status</th>
                                <th></th>
                            </thead>

                            <tbody>
                                <tr>
                                    <td scope="row">2023-04-28 19:58</td>
                                    <td><b>Deposit</b></td>
                                    <td>Spot Wallet</td>
                                    <td><b>USDT</b></td>
                                    <td>51.98368137</td>
                                    <td>
                                    <div>
                                            0xfdf....89be4
                                            <a href="#"> <i className="fa fa-link"></i></a>
                                            <a href="#"><i className="fa fa-copy"></i></a>
                                        </div>
                                    </td>
                                    <td>
                                    <div>
                                            0xfdf....89be4
                                            <a href="#"> <i className="fa fa-link"></i></a>
                                            <a href="#"><i className="fa fa-copy"></i></a>
                                        </div>
                                    </td>
                                    <td>Completed</td>
                                    <td>
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            <i className='fa fa-angle-right detail_icon'></i>
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td scope="row">2023-04-28 19:58</td>
                                    <td><b>Deposit</b></td>
                                    <td>Spot Wallet</td>
                                    <td><b>USDT</b></td>
                                    <td>51.98368137</td>
                                    <td>
                                        <div>
                                            0xfdf....89be4
                                            <a href="#"> <i className="fa fa-link"></i></a>
                                            <a href="#"><i className="fa fa-copy"></i></a>
                                        </div>
                                    </td>
                                    <td>
                                    <div>
                                            0xfdf....89be4
                                            <a href="#"> <i className="fa fa-link"></i></a>
                                            <a href="#"><i className="fa fa-copy"></i></a>
                                        </div>
                                    </td>
                                    <td>Completed</td>
                                    <td>
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            <i className='fa fa-angle-right detail_icon'></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td scope="row">2023-04-28 19:58</td>
                                    <td><b>Deposit</b></td>
                                    <td>Spot Wallet</td>
                                    <td><b>USDT</b></td>
                                    <td>51.98368137</td>
                                    <td>
                                    <div>
                                            0xfdf....89be4
                                            <a href="#"> <i className="fa fa-link"></i></a>
                                            <a href="#"><i className="fa fa-copy"></i></a>
                                        </div>
                                    </td>
                                    <td>
                                    <div>
                                            0xfdf....89be4
                                            <a href="#"> <i className="fa fa-link"></i></a>
                                            <a href="#"><i className="fa fa-copy"></i></a>
                                        </div>
                                    </td>
                                    <td>Completed</td>
                                    <td>
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            <i className='fa fa-angle-right detail_icon'></i>
                                        </a>
                                    </td>
                                </tr>
                               
                            </tbody>
                            </table>
                        </div>

                        
                    </div>
                </div>

                </div>
                </div>




                


<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Deposit Details</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body depost_detail">
      <table className="table">
            <tbody>
                <tr>
                <th scope="row">Status</th>
                <td>Completed</td>
                </tr>
                <tr>
                <th scope="row">Date</th>
                <td>2023-04-28 19:58</td>
                </tr>
                <tr>
                <th >Coin</th>
                <td >USDT</td>
                </tr>
                <tr>
                <th>Deposit amount</th>
                <td >51.98368137</td>
                </tr>
                <tr>
                <th >Network</th>
                <td>BSC</td>
                </tr>
                <tr>
                <th >Address</th>
                <td >BSC</td>
                </tr>

                <tr>
                <th >TxID</th>
                <td >0xacfe5aba270cad278c4bc5f21</td>
                </tr>

                <tr>
                <th scope="row">Deposit Wallet</th>
                <td >Spot Wallet</td>
                </tr>
            </tbody>
            </table>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
