import React from "react";
import Footer from './Footer';
import { Link } from "react-router-dom";


function Affilate(){
    return(
        <>

<div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/"> <i className="fa fa-angle-left"></i> </ Link>
                                Affiliate
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section>
            <div className="py-5">
             <div className="container">
            <div className="row align-items-center">
                <div className="col-md-12">
                    <div className="about__content aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                        <h3 className="heading1 ">How Does the Bidexpro Affiliate Program Work?</h3>
                        <p className="m-0 fs-6">Recommend Bidexpro. Earn commission in crypto.</p>
                        </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                   <div className="affilate_box">
                    <h5>01</h5>
                    <h6 className="my-3">Sign up to become an affiliate</h6>

                    <p>Submit your application by filling the form below. Our team will evaluate your application and ensure you meet our affiliate criteria.</p>
                   </div>
                </div>

                <div className="col-md-4">
                   <div className="affilate_box">
                    <h5>02</h5>
                    <h6 className="my-3">Create and share your affiliate link</h6>

                    <p>Create, manage and track the performance of your affiliate links right from your Bidexpro account.</p>
                   </div>
                </div>

                <div className="col-md-4">
                   <div className="affilate_box">
                    <h5>03</h5>
                    <h6 className="my-3">Earn up to 50% commissions</h6>

                    <p>When users create an account with your affiliate link, you’ll receive commission on every trade they make.</p>
                   </div>
                </div>

            </div>

            <section className="bg_new mt-4">
            <div className="row align-items-center">
                <div className="col-md-12">
                    <div className="about__content mt-2 text-center" >
                        <h3 className="heading1 ">Bidexpro Affiliate Program Commission Benefits</h3>
                        <p className="m-0 fs-6 text-center">Check more detailed rules of the affiliate program Detailed Rules</p>

                        <div className="mt-4">
                        <button className="btn btn-primary">Join Now</button>
                        </div>

                    </div>
                </div>
            </div>
            </section>


            <div className="row align-items-center">
                <div className="col-md-12">
                    <div className="about__content aos-init aos-animate mt-5" data-aos="fade-up" data-aos-duration="1000">
                        <h3 className="heading1 ">Earn more affiliate incentives</h3>
                    </div>

                    <div className="campus_box mt-5">
                        <table className="table">
                            <tbody>
                                <tr>
                                
                                <td>Spot commission</td>
                                <td>Up to 50%</td>
                                </tr>
                                <tr>
                                <td>Futures commission</td>
                                <td>30%</td>
                                </tr>

                                <tr>
                                <td>Bidexpro pool commission</td>
                                <td>30%</td>
                                </tr>

                                <tr>
                                <td>Sign-up bonus package</td>
                                <td>Share up to a $600 new user sign-up bonus package with your community.</td>
                                </tr>

                                <tr>
                                <td>Minimum requirements</td>
                                <td>Must have more than 5000 followers on social media or more than 500 members in a trading community.</td>
                                </tr>

                                <tr>
                                <td>Eligibility</td>
                                <td>Only eligible users can participate after submitting an application</td>
                                </tr>
                            </tbody>
                            </table>
                    </div>

                </div>
            </div>

            <div className="row align-items-center mt-5">
                <div className="col-md-12">
                    <div className="about__content aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                        <h3 className="heading1 mt-4">Additional Affiliate Program Benefits</h3>
                        </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-3">
                   <div className="affilate_box mt-0">
                    <h6 className="my-3">Exclusive content program</h6>

                    <p>Earn BUSD every month and a special sign-up bonus for your audience when you create quality content.</p>
                   </div>
                </div>

                <div className="col-md-3">
                   <div className="affilate_box mt-0">
                    <h6 className="my-3">More Reward</h6>

                    <p>Earn a bonus reward of up to $72,000 every month based on the total fees paid by Futures referrals.</p>
                   </div>
                </div>

                <div className="col-md-3">
                   <div className="affilate_box mt-0">
                    <h6 className="my-3">Convenient payments</h6>

                    <p>Get paid for every first-time buyer, with no referral limit and a lifetime attribution for spot referrals.</p>
                   </div>
                </div>

                <div className="col-md-3">
                   <div className="affilate_box mt-0">
                    <h6 className="my-3">Dedicated account manager</h6>

                    <p>Gain access to professional support, tutorials, marketing material, and a dedicated Bidexpro Affiliate manager.</p>
                   </div>
                </div>

            </div>

            <div className="row align-items-center mt-5">
                <div className="col-md-12">
                    <div className="about__content aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                        <h3 className="heading1 mt-4">Affiliate FAQ</h3>
                        </div>
                </div>
            </div>


            <div className="row align-items-center mb-5">
                <div className="col-md-12">
                    <div className="accordion" id="accordionExample">

                        <div className="row">
                            <div className="col-md-6">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        What is the Bidexpro Affiliate Program?
                                    </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne">
                                        What are the requirements to be a Bidexpro Affiliate?
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                    </div>
                                </div>
                            </div>

                             <div className="col-md-6">
                                <div className="accordion-item mt-4">
                                    <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseOne">
                                       How do I earn a bonus of up to $72,000 every month?
                                    </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                     


                        </div>
                </div>
            </div>

            </div>
           
            <Footer />

             </div>   
            </section>
        </>
    )
}

export default Affilate;