import React from 'react'
import { Link } from "react-router-dom";


export default function Footer() {
  return (
    <div className="footer">
        <div className='container'>
            <div className="row">
                        <div className="col-sm-2 col-6">
                            <ul className="list-unstyled">
                                <li><Link to="/exchange">Exchange</Link></li>
                                <li><Link to="/launchpad">Launch Pad</Link></li>
                            </ul>
                        </div>
                        <div className="col-sm-2 col-6"> 
                        <ul className="list-unstyled">
                            <li><Link  to="/about">About</Link></li>
                            <li><Link to="/career">Career</Link></li>
                            <li><Link to="/referralprogram">Referral Program</Link></li>
                            <li><Link to="/news">News</Link></li>
                        </ul>
                    </div>
                    <div className="col-sm-2 col-6">
                        <ul className="list-unstyled">
                            <li><Link to="/fee">Trade Fees</Link></li>
                            <li><Link to="/withdrawalfee">Withdrawal Fees</Link></li>
                            <li><Link to="/feedback">Feedback</Link></li>
                        </ul>
                        </div>

                        <div className="col-sm-2 col-6">
                    <ul className="list-unstyled">
                        <li><Link to="/listingwizard">Listing</Link></li>
                        <li><Link to="/affilate">Affiliate</Link></li>
                    </ul>
                </div>

                <div className="col-sm-4">
                            <div className="app_logo d-flex">
                                <a href="#"><img src="web/android.png" className="img-fluid" /></a>
                                <a href="#"><img src="web/ios.png" className="img-fluid" /></a>
                            </div>

                            <div className="footer-social-icons left-alignment">
                                <ul className="list-inline d-flex">
                                    <li><a href="https://www.facebook.com/bidexpro.com" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="https://www.youtube.com/channel/UCgf_rDP1AuZAdmrlVuQ5w4A/" target="_blank"><i className="fa fa-youtube"></i></a></li>
                                    <li><a href="https://telegram.me/Bidexpro Exchange/" target="_blank"><i className="fa fa-telegram"></i></a></li>
                                    <li><a href="https://www.instagram.com/bidexpro.com/" target="_blank"><i className=" fa fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>  

            </div>

            <p className="mt-5"><span className="font-weight-600">Risk Warning : </span> The Financial Products proposed by the company include Contracts for Difference (CFDs) and other complicated financial products. Trading CFDs carries high liability of risk since leverage can work both to your advantage and disadvantage.</p>

            <div className="payment-cls-btm ">
                
                <ul className="list-inline d-flex justify-content-between">
                    <li><a href="/terms">Terms of use</a></li>
                    <li><a href="/privacy">Privacy Policy</a></li>
                    <li><a href="/warning">Warning Policy</a></li>
                    <li><a href="/trade">Trading Rule</a></li>
                </ul>
                   
            </div>

            <p className="mt-5 text-center">Copyright © 2023 All Rights Reserved By <Link to="/" className='text-white'>bidexpro.com</Link></p>
        </div>
    </div>
  )
}
