import React from "react";
import Footer from './Footer';
import { Link } from "react-router-dom";


function Career(){
    return(
        <>
             <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/" className="text-white"><i className="fa fa-angle-left m-0"></i></Link> &nbsp; 
                                 Career
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             <div className="py-5">
             <div className="container">
            <div className="row align-items-center">
                <div className="col-md-6">
                    <div className="about__content aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                        <h6 className="span_about">Our Philosophy</h6>
                        <h3 className="heading1">We Are Always Thinking In Global</h3>
                        <p className="fs-20 decs">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </p>
                        <p className="fs-20 decs">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                        </p>
                       
                        <a href="#" className="btn btn-primary">Explore More</a>
                        </div>
                </div>
                <div className="col-md-6">
                    <div className="about_img">
                        <img src="web/about-h1.png" className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
            </div>
           
             </div>           
           
         
        <Footer />


    
        </>
    )
}

export default Career;