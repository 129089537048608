import React from 'react'
import { Link } from "react-router-dom";


export default function CryptoWithdrawl() {
  return (
    <div>
        <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/" className="text-white"><i className="fa fa-angle-left m-0"></i> </Link>  &nbsp;
                                Withdrawl Crypto
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <section>
                <div className="container">
                    <form action="" className="m-4">
                    <div className="row">
                        <div className="col-md-6 offset-md-3 bg_new">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label text-white">Select Coin</label>
                                </div>
                                <div className="col-md-9">
                                    
                                    <div className="form-group form-box clearfix">
                         <label className='form-label text-white'>Coin</label>
                         <select className="form-select" aria-label="Default select example">
                                      <option>XRP Ripple</option>
                                      <option>XRP Ripple</option>
                                      <option>XRP Ripple</option>
                                      <option>XRP Ripple</option>
                                      <option>XRP Ripple</option>
                                    </select>
                         </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-md-3">
                                    <label className="form-label text-white">Select Coin</label>
                                </div>
                                <div className="col-md-9">
                                <ul className="nav crypto_tab nav-tabs mb-5 text-white" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Address</button>
                                    </li>
                                    <li className="nav-item " role="presentation">
                                        <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Bidexpro user</button>
                                    </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="form-group form-box clearfix">
                                        <label className='form-label text-white mt-3'>Address</label>
                                        <input name="text" type="text" className="form-control" placeholder='Enter Address'  />
                                        <i className="flaticon-password"></i>
                                    </div>

                                    <label className='form-label text-white mt-4'>Network</label>
                                        <select className="form-select" aria-label="Default select example">
                                                    <option selected>Select withdrawal network</option>
                                                    <option value="1">A.</option>
                                                    <option value="2">B.</option>
                                                    <option value="3">C.</option>
                                                    <option value="3">D.</option>
                                                    </select>

                                                    <table className="table mt-3 withdrawl_table">

                                                        <tbody>
                                                            <tr className='border-0'>
                                                            <td>XRP balance</td>
                                                            <td>Minimum withdrawal</td>
                                                            </tr>
                                                            <tr className='border-0'>
                                                            <th>203.594 XRP</th>
                                                            <th>0.48  XRP</th>
                                                            </tr>
                                                            <tr className='border-0'>
                                                            <td>Network fee</td>
                                                            <td>24h remaining limit</td>
                                                            </tr>
                                                            <tr >
                                                            <th>0.2 ~ 17 XRP</th>
                                                            <th>8,000,000.00 BUSD/8,000,000.00 BUSD</th>
                                                            </tr>
                                                        </tbody>
                                                        </table>

                                    </div>
                                                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">

                                                    <ul className="d-flex launch_icon mb-3">
                                                        <li><a href="#">Email</a></li>
                                                        <li><a href="#">Bidexpro Id</a></li>
                                                    </ul>

                                                    <div className="form-group form-box clearfix">
                                                        <textarea name="text" type="text" className="form-control" placeholder='Recipients Email'  />
                                                        <i className="flaticon-password"></i>
                                                    </div>
    

                                                    <table className="table mt-3 withdrawl_table">

                                                        <tbody>
                                                            <tr className='border-0'>
                                                            <td>XRP balance</td>
                                                            <td>Daily remaining limit</td>
                                                            </tr>
                                                            <tr className='border-0'>
                                                            <th>203.594 XRP</th>
                                                            <th>8,000,000 BUSD/8,000,000 BUSD</th>
                                                            </tr>
                                                        </tbody>
                                                        </table>
                                    </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
            </section>

    </div>
  )
}
