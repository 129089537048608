import React from 'react';
import Trading from './Trading';
// import Trading1 from "./Trading"



function Exchange(){
return(
<>
<div className="container-fluid mtb15 no-fluid">
    <div className="row sm-gutters">
      <div className="col-md-3">
        <div className="market-pairs">
          <div className="input-group">
            {/* <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm"><i className="fa fa-search"></i></span>
            </div> */}
            <input type="text" className="form-control" placeholder="Search" aria-describedby="inputGroup-sizing-sm" />
          </div>

          {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
               <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Home</button>
            </li>
            <li className="nav-item" role="presentation">
               <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Profile</button>
            </li>
            </ul> */}
         <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
               <a className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Home</a>
            </li>
            <li className="nav-item" role="presentation">
               <a className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Profile</a>
            </li>
            </ul>
            <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <table className="table">
                <thead>
                  <tr>
                    <th>Pairs</th>
                    <th>Last Price</th>
                    <th>Change</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><i className="icon ion-md-star"></i> ETH/BTC</td>
                    <td>0.00020255</td>
                    <td className="red">-2.58%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> KCS/BTC</td>
                    <td>0.00013192</td>
                    <td className="green">+5.6%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> XRP/BTC</td>
                    <td>0.00002996</td>
                    <td className="red">-1.55%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> VET/BTC</td>
                    <td>0.00000103</td>
                    <td className="green">+1.8%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> EOS/BTC</td>
                    <td>0.00000103</td>
                    <td className="red">-2.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> BTT/BTC</td>
                    <td>0.00002303</td>
                    <td className="red">-1.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> LTC/BTC</td>
                    <td>0.03520103</td>
                    <td className="green">+1.5%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> TRX/BTC</td>
                    <td>0.00330103</td>
                    <td className="red">-3.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> BSV/BTC</td>
                    <td>0.00300103</td>
                    <td className="green">+2.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> COTI/BTC</td>
                    <td>0.003500103</td>
                    <td className="green">+2.85%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> XYT/BTC</td>
                    <td>0.00003103</td>
                    <td className="green">+3.55%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> BNB/BTC</td>
                    <td>0.003500103</td>
                    <td className="red">-2.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> XMR/BTC</td>
                    <td>0.003500103</td>
                    <td className="red">-1.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> TRY/BTC</td>
                    <td>0.00000123</td>
                    <td className="red">-2.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> ADA/BTC</td>
                    <td>0.00050103</td>
                    <td className="green">+5.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> NEO/BTC</td>
                    <td>0.00340103</td>
                    <td className="red">-1.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> XLM/BTC</td>
                    <td>0.00035103</td>
                    <td className="green">+5.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> ENQ/BTC</td>
                    <td>0.00354103</td>
                    <td className="green">+2.02%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> AVA/BTC</td>
                    <td>0.02535103</td>
                    <td className="green">+3.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> AMB/BTC</td>
                    <td>0.05335103</td>
                    <td className="green">+1.0%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> MAP/BTC</td>
                    <td>0.00234103</td>
                    <td className="red">-2.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> GO/BTC</td>
                    <td>0.00354103</td>
                    <td className="red">-6.50%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> KICK/BTC</td>
                    <td>0.02053103</td>
                    <td className="red">-6.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> DBC/BTC</td>
                    <td>0.02535103</td>
                    <td className="green">+7.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> GGC/BTC</td>
                    <td>0.00353103</td>
                    <td className="red">-4.05%</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <table className="table">
                <thead>
                  <tr>
                    <th>Pairs</th>
                    <th>Last Price</th>
                    <th>Change</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><i className="icon ion-md-star"></i> BTC/ETH</td>
                    <td>0.00020255</td>
                    <td className="green">+1.58%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> KCS/ETH</td>
                    <td>0.00013192</td>
                    <td className="red">-0.6%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> XRP/ETH</td>
                    <td>0.00002996</td>
                    <td className="red">-0.55%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> VET/ETH</td>
                    <td>0.00000103</td>
                    <td className="green">+1.8%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> EOS/ETH</td>
                    <td>0.00000103</td>
                    <td className="red">-2.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> BTT/ETH</td>
                    <td>0.00002303</td>
                    <td className="red">-1.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> LTC/ETH</td>
                    <td>0.03520103</td>
                    <td className="green">+1.5%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> TRX/ETH</td>
                    <td>0.00330103</td>
                    <td className="red">-3.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> BSV/ETH</td>
                    <td>0.00300103</td>
                    <td className="green">+2.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> COTI/ETH</td>
                    <td>0.003500103</td>
                    <td className="green">+2.85%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> XYT/ETH</td>
                    <td>0.00003103</td>
                    <td className="green">+3.55%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> BNB/ETH</td>
                    <td>0.003500103</td>
                    <td className="red">-2.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> XMR/ETH</td>
                    <td>0.003500103</td>
                    <td className="red">-1.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> TRY/ETH</td>
                    <td>0.00000123</td>
                    <td className="red">-2.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> ADA/ETH</td>
                    <td>0.00050103</td>
                    <td className="green">+5.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> NEO/ETH</td>
                    <td>0.00340103</td>
                    <td className="red">-1.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> XLM/ETH</td>
                    <td>0.00035103</td>
                    <td className="green">+5.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> ENQ/ETH</td>
                    <td>0.00354103</td>
                    <td className="green">+2.02%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> AVA/ETH</td>
                    <td>0.02535103</td>
                    <td className="green">+3.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> AMB/ETH</td>
                    <td>0.05335103</td>
                    <td className="green">+1.0%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> MAP/ETH</td>
                    <td>0.00234103</td>
                    <td className="red">-2.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> GO/ETH</td>
                    <td>0.00354103</td>
                    <td className="red">-6.50%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> KICK/ETH</td>
                    <td>0.02053103</td>
                    <td className="red">-6.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> DBC/ETH</td>
                    <td>0.02535103</td>
                    <td className="green">+7.05%</td>
                  </tr>
                  <tr>
                    <td><i className="icon ion-md-star"></i> GGC/ETH</td>
                    <td>0.00353103</td>
                    <td className="red">-4.05%</td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
         
        
        </div>
      </div>
      <div className="col-md-6">
        <div className="main-chart mb15">
          {/* TradingView Widget BEGIN  */}
          <div className="tradingview-widget-container">
            <div id="tradingview_e8053"></div>
            <Trading/>
            
          </div>
          
         {/* TradingView Widget END */}
        </div>
        <div className="market-trade">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="Limit-tab" data-bs-toggle="tab" data-bs-target="#Limit" type="button" role="tab" aria-controls="Limit" aria-selected="true">Limit</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="Market-tab" data-bs-toggle="tab" data-bs-target="#Market" type="button" role="tab" aria-controls="Market" aria-selected="false">Market</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="Stop-tab" data-bs-toggle="tab" data-bs-target="#Stop" type="button" role="tab" aria-controls="Stop" aria-selected="false">Stop Limit</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="Stop-tab" data-bs-toggle="tab" data-bs-target="#Stop_market" type="button" role="tab" aria-controls="Stop_market" aria-selected="false">Stop
                Market</button>
          </li>
        </ul>
          {/* <ul className="nav nav-pills" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="pill" href="#pills-trade-limit" role="tab"
                aria-selected="true">Limit</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="pill" href="#pills-market" role="tab" aria-selected="false">Market</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="pill" href="#pills-stop-limit" role="tab" aria-selected="false">Stop
                Limit</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="pill" href="#pills-stop-market" role="tab" aria-selected="false">Stop
                Market</a>
            </li>
          </ul> */}
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="Limit" role="tabpanel" aria-labelledby="Limit-tab">
            <div className="d-flex justify-content-between">
                <div className="market-trade-buy">
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Price" />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div>
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Amount" />
                    <div className="input-group-append">
                      <span className="input-group-text">ETH</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li><a href="#!">25%</a></li>
                    <li><a href="#!">50%</a></li>
                    <li><a href="#!">75%</a></li>
                    <li><a href="#!">100%</a></li>
                  </ul>
                  <p>Available: <span>0 BTC = 0 USD</span></p>
                  <p>Volume: <span>0 BTC = 0 USD</span></p>
                  <p>Margin: <span>0 BTC = 0 USD</span></p>
                  <p>Fee: <span>0 BTC = 0 USD</span></p>
                  <button className="btn buy">Buy</button>
                </div>
                <div className="market-trade-sell">
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Price" />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div>
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Amount" />
                    <div className="input-group-append">
                      <span className="input-group-text">ETH</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li><a href="#!">25%</a></li>
                    <li><a href="#!">50%</a></li>
                    <li><a href="#!">75%</a></li>
                    <li><a href="#!">100%</a></li>
                  </ul>
                  <p>Available: <span>0 BTC = 0 USD</span></p>
                  <p>Volume: <span>0 BTC = 0 USD</span></p>
                  <p>Margin: <span>0 BTC = 0 USD</span></p>
                  <p>Fee: <span>0 BTC = 0 USD</span></p>
                  <button className="btn sell">Sell</button>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="Market" role="tabpanel" aria-labelledby="Market-tab">
            <div className="d-flex justify-content-between">
                <div className="market-trade-buy">
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Price" />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div>
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Amount" />
                    <div className="input-group-append">
                      <span className="input-group-text">ETH</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li><a href="#!">25%</a></li>
                    <li><a href="#!">50%</a></li>
                    <li><a href="#!">75%</a></li>
                    <li><a href="#!">100%</a></li>
                  </ul>
                  <p>Available: <span>0 BTC = 0 USD</span></p>
                  <p>Volume: <span>0 BTC = 0 USD</span></p>
                  <p>Margin: <span>0 BTC = 0 USD</span></p>
                  <p>Fee: <span>0 BTC = 0 USD</span></p>
                  <button className="btn buy">Buy</button>
                </div>
                <div className="market-trade-sell">
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Price" />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div>
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Amount" />
                    <div className="input-group-append">
                      <span className="input-group-text">ETH</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li><a href="#!">25%</a></li>
                    <li><a href="#!">50%</a></li>
                    <li><a href="#!">75%</a></li>
                    <li><a href="#!">100%</a></li>
                  </ul>
                  <p>Available: <span>0 BTC = 0 USD</span></p>
                  <p>Volume: <span>0 BTC = 0 USD</span></p>
                  <p>Margin: <span>0 BTC = 0 USD</span></p>
                  <p>Fee: <span>0 BTC = 0 USD</span></p>
                  <button className="btn sell">Sell</button>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="Stop" role="tabpanel" aria-labelledby="Stop-tab">
            <div className="d-flex justify-content-between">
                <div className="market-trade-buy">
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Price" />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div>
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Amount" />
                    <div className="input-group-append">
                      <span className="input-group-text">ETH</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li><a href="#!">25%</a></li>
                    <li><a href="#!">50%</a></li>
                    <li><a href="#!">75%</a></li>
                    <li><a href="#!">100%</a></li>
                  </ul>
                  <p>Available: <span>0 BTC = 0 USD</span></p>
                  <p>Volume: <span>0 BTC = 0 USD</span></p>
                  <p>Margin: <span>0 BTC = 0 USD</span></p>
                  <p>Fee: <span>0 BTC = 0 USD</span></p>
                  <button className="btn buy">Buy</button>
                </div>
                <div className="market-trade-sell">
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Price" />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div>
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Amount" />
                    <div className="input-group-append">
                      <span className="input-group-text">ETH</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li><a href="#!">25%</a></li>
                    <li><a href="#!">50%</a></li>
                    <li><a href="#!">75%</a></li>
                    <li><a href="#!">100%</a></li>
                  </ul>
                  <p>Available: <span>0 BTC = 0 USD</span></p>
                  <p>Volume: <span>0 BTC = 0 USD</span></p>
                  <p>Margin: <span>0 BTC = 0 USD</span></p>
                  <p>Fee: <span>0 BTC = 0 USD</span></p>
                  <button className="btn sell">Sell</button>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="Stop_market" role="tabpanel" aria-labelledby="Stop_market">
            <div className="d-flex justify-content-between">
                <div className="market-trade-buy">
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Price" />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div>
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Amount" />
                    <div className="input-group-append">
                      <span className="input-group-text">ETH</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li><a href="#!">25%</a></li>
                    <li><a href="#!">50%</a></li>
                    <li><a href="#!">75%</a></li>
                    <li><a href="#!">100%</a></li>
                  </ul>
                  <p>Available: <span>0 BTC = 0 USD</span></p>
                  <p>Volume: <span>0 BTC = 0 USD</span></p>
                  <p>Margin: <span>0 BTC = 0 USD</span></p>
                  <p>Fee: <span>0 BTC = 0 USD</span></p>
                  <button className="btn buy">Buy</button>
                </div>
                <div className="market-trade-sell">
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Price" />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div>
                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="Amount" />
                    <div className="input-group-append">
                      <span className="input-group-text">ETH</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li><a href="#!">25%</a></li>
                    <li><a href="#!">50%</a></li>
                    <li><a href="#!">75%</a></li>
                    <li><a href="#!">100%</a></li>
                  </ul>
                  <p>Available: <span>0 BTC = 0 USD</span></p>
                  <p>Volume: <span>0 BTC = 0 USD</span></p>
                  <p>Margin: <span>0 BTC = 0 USD</span></p>
                  <p>Fee: <span>0 BTC = 0 USD</span></p>
                  <button className="btn sell">Sell</button>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div className="col-md-3">
        <div className="order-book mb15">
          <h2 className="heading">Order Book</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Price(BTC)</th>
                <th>Amount(ETH)</th>
                <th>Total(ETH)</th>
              </tr>
            </thead>
            <tbody>
              <tr className="red-bg-80">
                <td className="red">0.022572</td>
                <td>1.253415</td>
                <td>15.27648</td>
              </tr>
              <tr className="red-bg-60">
                <td className="red">0.020371</td>
                <td>1.205415</td>
                <td>15.25648</td>
              </tr>
              <tr className="red-bg-40">
                <td className="red">0.023572</td>
                <td>1.645415</td>
                <td>15.23648</td>
              </tr>
              <tr className="red-bg-20">
                <td className="red">0.032378</td>
                <td>1.206715</td>
                <td>15.25348</td>
              </tr>
              <tr className="red-bg-10">
                <td className="red">0.022573</td>
                <td>1.262415</td>
                <td>15.19648</td>
              </tr>
              <tr className="red-bg-8">
                <td className="red">0.154377</td>
                <td>1.225415</td>
                <td>15.35648</td>
              </tr>
              <tr className="red-bg-5">
                <td className="red">0.120373</td>
                <td>1.285415</td>
                <td>15.25648</td>
              </tr>
              <tr className="red-bg">
                <td className="red">0.028576</td>
                <td>1.291415</td>
                <td>15.26448</td>
              </tr>
            </tbody>
            <tbody className="ob-heading">
              <tr>
                <td>
                  <span>Last Price</span>
                  0.020367
                </td>
                <td>
                  <span>USD</span>
                  148.65
                </td>
                <td className="red">
                  <span>Change</span>
                  -0.51%
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr className="green-bg">
                <td className="green">0.158373</td>
                <td>1.209515</td>
                <td>15.23248</td>
              </tr>
              <tr className="green-bg-5">
                <td className="green">0.020851</td>
                <td>1.206245</td>
                <td>15.25458</td>
              </tr>
              <tr className="green-bg-8">
                <td className="green">0.025375</td>
                <td>1.205715</td>
                <td>15.65648</td>
              </tr>
              <tr className="green-bg-10">
                <td className="green">0.020252</td>
                <td>1.205495</td>
                <td>15.24548</td>
              </tr>
              <tr className="green-bg-20">
                <td className="green">0.020373</td>
                <td>1.205415</td>
                <td>15.25648</td>
              </tr>
              <tr className="green-bg-40">
                <td className="green">0.020156</td>
                <td>1.207515</td>
                <td>15.28948</td>
              </tr>
              <tr className="green-bg-60">
                <td className="green">0.540375</td>
                <td>1.205915</td>
                <td>15.25748</td>
              </tr>
              <tr className="green-bg-80">
                <td className="green">0.020372</td>
                <td>1.205415</td>
                <td>15.25648</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="market-history">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="Recent-tab" data-bs-toggle="tab" data-bs-target="#Recent" type="button" role="tab" aria-controls="Recent" aria-selected="true">Recent
                Trades</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="Depth-tab" data-bs-toggle="tab" data-bs-target="#Depth" type="button" role="tab" aria-controls="Depth" aria-selected="false">Market
                Depth</button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="Recent" role="tabpanel" aria-labelledby="Recent-tab">
        <table className="table">
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Price(BTC)</th>
                    <th>Amount(ETH)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>13:03:53</td>
                    <td className="red">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="red">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="red">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="red">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="red">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="red">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                </tbody>
              </table>
        </div>
        <div className="tab-pane fade" id="Depth" role="tabpanel" aria-labelledby="Depth-tab">
        <table className="table">
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Price(BTC)</th>
                    <th>Amount(ETH)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>13:03:53</td>
                    <td className="red">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="red">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="red">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="red">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="red">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="green">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                  <tr>
                    <td>13:03:53</td>
                    <td className="red">0.020191</td>
                    <td>0.2155045</td>
                  </tr>
                </tbody>
              </table>
        </div>
      </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="market-news mt15">
          <h2 className="heading">Market News</h2>
          <ul>
            <li><a href="#!">
                <strong>KCS Pay Fees Feature is Coming Soon</strong>
                To accelerate the ecosystem construction of KuCoin Share (KCS) and promote the implementation of the KCS
                application.
                <span>2019-12-04 20:22</span>
              </a></li>
            <li><a href="#!">
                <strong>KCS Pay Fees Feature is Coming Soon</strong>
                To accelerate the ecosystem construction of KuCoin Share (KCS) and promote the implementation of the KCS
                application.
                <span>2019-12-04 20:22</span>
              </a></li>
            <li><a href="#!">
                <strong>KCS Pay Fees Feature is Coming Soon</strong>
                To accelerate the ecosystem construction of KuCoin Share (KCS) and promote the implementation of the KCS
                application.
                <span>2019-12-04 20:22</span>
              </a></li>
            <li><a href="#!">
                <strong>KCS Pay Fees Feature is Coming Soon</strong>
                To accelerate the ecosystem construction of KuCoin Share (KCS) and promote the implementation of the KCS
                application.
                <span>2019-12-04 20:22</span>
              </a></li>
            <li><a href="#!">
                <strong>KCS Pay Fees Feature is Coming Soon</strong>
                To accelerate the ecosystem construction of KuCoin Share (KCS) and promote the implementation of the KCS
                application.
                <span>2019-12-04 20:22</span>
              </a></li>
          </ul>
        </div>
      </div>
      <div className="col-md-9">
        <div className="market-history market-order mt15">

        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="open_orders-tab" data-bs-toggle="tab" data-bs-target="#open_orders" type="button" role="tab" aria-controls="open_orders" aria-selected="true">Open
                Orders</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="closed-tab" data-bs-toggle="tab" data-bs-target="#closed" type="button" role="tab" aria-controls="closed" aria-selected="false">Closed
                Orders</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="history-tab" data-bs-toggle="tab" data-bs-target="#history" type="button" role="tab" aria-controls="history" aria-selected="false">Order
                history</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="Balance-tab" data-bs-toggle="tab" data-bs-target="#Balance" type="button" role="tab" aria-controls="Balance" aria-selected="false">Balance</button>
          </li>
        </ul>

        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="open_orders" role="tabpanel" aria-labelledby="open_orders-tab">
          <ul className="d-flex justify-content-between market-order-item">
                <li>Time</li>
                <li>All pairs</li>
                <li>All Types</li>
                <li>Buy/Sell</li>
                <li>Price</li>
                <li>Amount</li>
                <li>Executed</li>
                <li>Unexecuted</li>
              </ul>
              <span className="no-data">
                <i className="fa fa-file"></i><br /><br /><br />
                No data
              </span>
          </div>
          <div className="tab-pane fade" id="closed" role="tabpanel" aria-labelledby="closed-tab">
          <ul className="d-flex justify-content-between market-order-item">
                <li>Time</li>
                <li>All pairs</li>
                <li>All Types</li>
                <li>Buy/Sell</li>
                <li>Price</li>
                <li>Amount</li>
                <li>Executed</li>
                <li>Unexecuted</li>
              </ul>
              <span className="no-data">
                <i className="fa fa-file"></i><br /><br /><br />
                No data
              </span>
          </div>
          <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
          <ul className="d-flex justify-content-between market-order-item">
                <li>Time</li>
                <li>All pairs</li>
                <li>All Types</li>
                <li>Buy/Sell</li>
                <li>Price</li>
                <li>Amount</li>
                <li>Executed</li>
                <li>Unexecuted</li>
              </ul>
              <span className="no-data">
                <i className="fa fa-file"></i><br /><br /><br />
                No data
              </span>
          </div>
          <div className="tab-pane fade" id="Balance" role="tabpanel" aria-labelledby="Balance-tab">
          <ul className="d-flex justify-content-between market-order-item">
                <li>Time</li>
                <li>All pairs</li>
                <li>All Types</li>
                <li>Buy/Sell</li>
                <li>Price</li>
                <li>Amount</li>
                <li>Executed</li>
                <li>Unexecuted</li>
              </ul>
              <span className="no-data">
                <i className="fa fa-file"></i><br /><br /><br />
                No data
              </span>
          </div>
        </div>

        
          
        </div>
      </div>
    </div>
  </div>

</>
)
}
export default Exchange;