import React from "react";
import { Link } from "react-router-dom";


function Launchpad(){
    return(
        <>
            <div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/"> <i className="fa fa-angle-left"></i> </Link>
                                    Launchpad
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="inner_box">
                <div className="container">
                    <div className="row align-items-center mt-5">
                    <div className="col-md-4">
                            <Link to="/launchpaddetail">
                            <div className="campus_img">
                                <img src="banner/campus.png" alt="img-fluid" />
                            </div>  
                            </Link>
                        </div>

                        <div className="col-md-8">
                            <Link to="/launchpaddetail">
                            <div className="campus_box">
                                <div className="d-flex align-items-center">
                                    <h2>Open Campus</h2>
                                    <div className="breadcrum_btn">
                                        <a href="#"  >Launchpool <i className="fa fa-question-circle" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <p className="text-white mt-3 fs-6">A Web3 Education Protocol to tokenize educational content - first adopted by Tiny Tap</p>

                                <table className="table">
                                  
                                    <tbody>
                                        <tr>
                                        <td scope="row">Tokens Offered</td>
                                        <td><b>50,000,000.0000 EDU</b></td>
                                        <td>Participants</td>
                                        <td><b>120,292</b></td>
                                        </tr>
                                        <tr>
                                        <td scope="row">Sale Price</td>
                                        <td><b>1 EDU = 0.00015281 BNB</b></td>
                                        <td>Total  committed</td>
                                        <td><b>9,088,202.4116 BNB</b></td>
                                        </tr>

                                        <tr>
                                        <td>End time:</td>
                                        <td colSpan="3">2023-04-28</td>
                                        </tr>
                                    </tbody>
                                    </table>
                            </div>
                            </Link>
                        </div>
                    </div>


                    
                

                   
                    

                    
                </div>
            </div>
        </>
    );
}

export default Launchpad;