import React from 'react'

export default function Listing() {
  return (
    <div>
    <div className="Login_form">
     <div className="container">
     <div className="row">
         <div className="offset-md-3 col-md-6">
             
             <div className="form-inner1">

             <div className="row align-items-center">    
                 <div className="col-md-12" id="login">
                       <div className="form-section text-ceter">
                 <div className="form-inner">
                     {/* <a href="login-1.html" className="logo">
                         <img src="assets/logo.png" alt="logo" className='img-fluid' />
                     </a>  */}
                     <h1 className="text-center">Listing Application Form</h1>
                     <p className="b_text text-center"> Please be aware that we receive a large volume of applications, therefore, please keep your answer concisely, and we appreciate your patience and cooperation. While we endeavor to process them as quickly as possible, we are unable to provide any direct feedback on your application if it does not pass our initial review based on the current stage your project is at.</p>

                     <div className="extra-login form-group clearfix">
                             <span>Or</span>
                         </div>

                     <form action="#" method="GET">
                         <div className="form-group form-box clearfix">
                             <input name="date" type="date" className="form-control" placeholder="Date" />
                             <i className="flaticon-mail-2"></i>
                         </div>
                         <div className="form-group form-box clearfix">
                             <label className='form-label'>Please provide your full name, project email, and Telegram handle.</label>
                             <input name="text" type="text" className="form-control" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>Who introduce/ refer you to apply for Bidexpro listing ?</label>
                         <select className="form-select" aria-label="Default select example">
                                      <option selected>Total Assets Under Management (AUM)</option>
                                      <option value="1">A. Listing BD Manager</option>
                                      <option value="2">B. Labs Portfolio</option>
                                      <option value="3">C. BNB chain Grants Project</option>
                                      <option value="3">D. Others</option>
                                    </select>
                         </div>


                         <div className="form-group form-box clearfix">
                         <label className='form-label'>1. Which platform are you applying for your coin/token to be on?</label>
                         <select className="form-select" aria-label="Default select example">
                                      <option selected>Please leave the name of your referral here</option>
                                      <option value="1">A. Listing BD Manager</option>
                                      <option value="2">B. Labs Portfolio</option>
                                      <option value="3">C. BNB chain Grants Project</option>
                                      <option value="3">D. Others</option>
                                    </select>
                         </div>


                         <div className="form-group form-box clearfix">
                         <label className='form-label'>2. Please provide the name and token symbol of your project.</label>
                             <textarea name="text" type="text" className="form-control" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>3. If the token has been circulated?</label>
                                    <select className="form-select" aria-label="Default select example">
                                      <option selected>Select your answer</option>
                                      <option value="1">---</option>
                                      <option value="2">Yes</option>
                                      <option value="3">No</option>
                                    </select>
                         </div>
                        

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>4. What is the ETA of TGE?</label>
                             <textarea name="text" type="text" className="form-control" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>5a. What is the project doing - in one sentence.</label>
                             <textarea name="text" type="text" className="form-control" placeholder="Summary" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>5b. Category: high potential vertical the project is in</label>
                             <textarea name="text" type="text" className="form-control" placeholder="Summary" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>5c. Pain point(s) identified (how pressing?)</label>
                             <textarea name="text" type="text" className="form-control" placeholder="Summary" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>5d. Solution(s) and the relevance (how relevant to the pain point?)</label>
                             <textarea name="text" type="text" className="form-control" placeholder="Summary" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>5e. Who are the target audience of the product(s)?</label>
                             <textarea name="text" type="text" className="form-control" placeholder="Summary" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>5f. What’s the benefit brought to Bidexpro?</label>
                             <textarea name="text" type="text" className="form-control" placeholder="Summary" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>6. Team</label>
                         <p className='text-start text-white mb-2'>a. What’s the size of the team?</p>
                         <p className='text-start text-white mb-2'>b. Who are the founders and core members? (at least 3 members, and include experiences with linkedin link or brief experience if applicable)</p>
                         <p className='text-start text-white mb-2'>c. how relevant the core member's background is to the solution; any strong experience?</p>
                             <textarea name="text" type="text" className="form-control" placeholder="Summary" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>7. Please describe the utilities of your token. What are the major drivers of demand for the token? or any strong value accrual?</label>
                             <textarea name="text" type="text" className="form-control" placeholder="Tokenomics" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>8. Please share token metrix (token release schedule)</label>
                             <textarea name="text" type="text" className="form-control" placeholder="Tokenomics" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>9. Is the product (or BETA) launched already?</label>
                                    <select className="form-select" aria-label="Default select example">
                                      <option selected>Product and Roadmap</option>
                                      <option value="1">---</option>
                                      <option value="2">Yes</option>
                                      <option value="3">No</option>
                                    </select>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>10a. Number of users</label>
                         <p className='text-start mb-3 text-white'>Please provide rough stats and on-chain proof/reference sites, if applicable.</p>
                             <textarea name="text" type="text" className="form-control" placeholder="Tokenomics" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>10b. Total Value Locked (TVL)</label>
                         <p className='text-start mb-3 text-white'> Please provide rough stats and on-chain proof/reference sites, if applicable.</p>
                             <textarea name="text" type="text" className="form-control" placeholder="Tokenomics" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>10c. Total Volume</label>
                         <p className='text-start mb-3 text-white'>Please provide rough stats and on-chain proof/reference sites, if applicable.</p>
                             <textarea name="text" type="text" className="form-control" placeholder="Tokenomics" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>10d. NFT Sales</label>
                         <p className='text-start mb-3 text-white'>Please provide rough stats and on-chain proof/reference sites, if applicable.</p>
                             <textarea name="text" type="text" className="form-control" placeholder="Tokenomics" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>10e. Community: Size and activeness</label>
                         <p className='text-start mb-3 text-white'>Please provide rough stats and on-chain proof/reference sites, if applicable.</p>
                             <textarea name="text" type="text" className="form-control" placeholder="Tokenomics" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>10f. Community: Geographical demography of the community</label>
                         <p className='text-start mb-3 text-white'>Please provide rough stats and on-chain proof/reference sites, if applicable.</p>
                             <textarea name="text" type="text" className="form-control" placeholder="Tokenomics" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>11a. If there is any innovation, please share more details.</label>
                         <p className='text-start mb-3 text-white'>Business Model</p>
                             <textarea name="text" type="text" className="form-control" placeholder="Tokenomics" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>11b. Current and planned revenue streams and usage</label>
                         <p className='text-start mb-3 text-white'>Business model</p>
                             <textarea name="text" type="text" className="form-control" placeholder="Tokenomics" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>12. Is the team working on multiple projects at the same time?</label>
                                    <select className="form-select" aria-label="Default select example">
                                      <option selected>Team</option>
                                      <option value="1">---</option>
                                      <option value="2">Yes</option>
                                      <option value="3">No</option>
                                    </select>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>13. Please share the FDV of each round of funding (including equity round if any) </label>
                         <p className='text-start mb-3 text-white'>Business model</p>
                             <textarea name="text" type="text" className="form-control" placeholder="Tokenomics" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>14a. Size and activeness of your community (Twitter/Telegram/Discord et al.)</label>
                         <p className='text-start mb-3 text-white'>Business model</p>
                             <textarea name="text" type="text" className="form-control" placeholder="Community" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>14b. What is the geographical demographic of your community</label>
                         <p className='text-start mb-3 text-white'>Community</p>
                             <textarea name="text" type="text" className="form-control" placeholder="Community" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>15. Please share a list of your key incubators/advisors and major investors/partners. (Tokens and equity)</label>
                         <p className='text-start mb-3 text-white'>Investors & Partners</p>
                             <textarea name="text" type="text" className="form-control" placeholder="Community" />
                             <i className="flaticon-password"></i>
                         </div>


                         <div className="form-group form-box clearfix">
                         <label className='form-label'>16a. Who are your major competitors?</label>
                         <p className='text-start mb-3 text-white'>Major Competitors</p>
                             <textarea name="text" type="text" className="form-control" placeholder="Community" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>16b. Main innovation / differentiation over the competitors?</label>
                         <p className='text-start mb-3 text-white'>Major Competitors</p>
                             <textarea name="text" type="text" className="form-control" placeholder="Community" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>17. Is the project open to launch on the BNB chain and what’s the timeline?</label>
                                    <select className="form-select" aria-label="Default select example">
                                      <option selected>Team</option>
                                      <option value="1">---</option>
                                      <option value="2">Yes</option>
                                      <option value="3">No</option>
                                    </select>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>18a. Is the project open to extend the vesting schedule (only for initial launch project)? </label>
                                    <select className="form-select" aria-label="Default select example">
                                      <option selected>Team</option>
                                      <option value="1">---</option>
                                      <option value="2">Yes</option>
                                      <option value="3">No</option>
                                    </select>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>18b. Are you open to lower the valuation of the project (only for initial launch project)?</label>
                                    <select className="form-select" aria-label="Default select example">
                                      <option selected>Team</option>
                                      <option value="1">---</option>
                                      <option value="2">Yes</option>
                                      <option value="3">No</option>
                                    </select>
                         </div>
                         
                         <div className="form-group form-box clearfix">
                         <label className='form-label'>19. Please provide a copy of the Bidexpro Non-Disclosure Agreement (“NDA”) signed by the Founder/CEO of the Company.</label>
                         <p className='text-start mb-3 text-white'>Major Competitors</p>
                             <textarea name="file" type="file" className="form-control" placeholder="Community" />
                             <i className="flaticon-password"></i>
                         </div>


                         <div className="form-group form-box clearfix">
                         <label className='form-label'>20. Please attach the legal opinion documents advising or stating that the token/coin is not a security or otherwise a regulated product or instrument under applicable laws.</label>
                         <p className='text-start mb-3 text-white'>Major Competitors</p>
                             <textarea name="file" type="file" className="form-control" placeholder="Community" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>21. Please provide the incorporation documents and structure chart of your company or the project.</label>
                         <p className='text-start mb-3 text-white'>Major Competitors</p>
                             <input name="file" type="file" className="form-control" placeholder="Community" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>Please create an 8 character anti-phishing code of your choice below. Ensure that you save this code somewhere secure. [IMPORTANT]</label>
                             <textarea name="text" type="text" className="form-control" placeholder="Community" />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>Supporting documents</label>
                         <p className='text-start mb-3 text-white'>Major Competitors</p>
                             <input name="file" type="file" className="form-control" placeholder="You may upload additional supporting documents." />
                             <i className="flaticon-password"></i>
                         </div>

                         <div className="form-group form-box clearfix">
                         <label className='form-label'>Supporting documents</label>
                         <p className='text-start mb-3 text-white'>Major Competitors</p>
                             <input name="file" type="file" className="form-control" placeholder="You may upload additional supporting documents." />
                             <i className="flaticon-password"></i>
                         </div>
                        
                         <div className="row">
                             <div className="col-md-12">
                                 <div className="form-group ">
                                     <button type="submit" className="btn btn-primary btn-lg btn-theme w-50" id='getstart'><span>Submit</span></button>
                                     <p className='py-3 text-white'>Never submit passwords or credit card details through WorkForms</p>
                                 </div>
                             </div>
                         
                         </div>
                        
                     </form>

                 </div>
             </div>
                 </div>
             </div>
             </div>
         </div>





     </div>
     </div>
 </div>
        
    </div>
  )
}
