import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function News(){
    return(
        <>

<div className="inner_header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="inner_heading">
                                <h3>
                                <Link to="/"> <i className="fa fa-angle-left"></i> </Link>
                                    Flash News
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

       

            <section className="my-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1 pe-0">
                            <div className="just">
                                <p> 
                                    Just now
                                </p>
                            </div>
                        </div>
                        <div className="col-md-11 ps-0">
                            <div className="just_box">
                                <h3>Alleged BlackRock Wallet Address Withdrew $1.2M Worth PEPE: On-Chain Data</h3>
                                <p>PEPE’s rise has ignited renewed investor interest in memecoins as a whole, and the frenzy continued even as bitcoin’s 2023 rally stalled. 
                                A wallet bearing the name of investment giant BlackRock withdrew 1.27 trillion PEPE (which is worth around $1.98 million) from Bidexpro to four addresses on May 18th. 

                                According to Lookonchain’s data, these four addresses use the same Bidexpro deposit address as blackrockfund3.eth. 
                                On April 21, BlackRock Fund purchased 1T PEPE (worth around $244K at that time) at an average price of $0.0000002431 and sold the entire stash (wort</p>

                        <div className="just_btn d-flex align-items-center justify-content-between">
                            <a href=""># Market</a>

                            <div>
                                <a href=""> <i className="fa fa-thumb"></i> 195</a>
                                <a href=""> <i className="fa fa-share-alt"></i></a>
                            </div>
                        </div>

                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-1 pe-0">
                            <div className="just">
                                <p> 
                                    Just now
                                </p>
                            </div>
                        </div>
                        <div className="col-md-11 ps-0">
                            <div className="just_box">
                                <h3>Alleged BlackRock Wallet Address Withdrew $1.2M Worth PEPE: On-Chain Data</h3>
                                <p>PEPE’s rise has ignited renewed investor interest in memecoins as a whole, and the frenzy continued even as bitcoin’s 2023 rally stalled. 
                                A wallet bearing the name of investment giant BlackRock withdrew 1.27 trillion PEPE (which is worth around $1.98 million) from Bidexpro to four addresses on May 18th. 
                                                    
                                According to Lookonchain’s data, these four addresses use the same Bidexpro deposit address as blackrockfund3.eth. 
                                On April 21, BlackRock Fund purchased 1T PEPE (worth around $244K at that time) at an average price of $0.0000002431 and sold the entire stash (wort</p>

                        <div className="just_btn d-flex align-items-center justify-content-between">
                            <a href=""># Market</a>

                            <div>
                                <a href=""> <i className="fa fa-thumb"></i> 195</a>
                                <a href=""> <i className="fa fa-share-alt"></i></a>
                            </div>
                        </div>

                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-1 pe-0">
                            <div className="just">
                                <p> 
                                    Just now
                                </p>
                            </div>
                        </div>
                        <div className="col-md-11 ps-0">
                            <div className="just_box">
                                <h3>Alleged BlackRock Wallet Address Withdrew $1.2M Worth PEPE: On-Chain Data</h3>
                                <p>PEPE’s rise has ignited renewed investor interest in memecoins as a whole, and the frenzy continued even as bitcoin’s 2023 rally stalled. 
                                A wallet bearing the name of investment giant BlackRock withdrew 1.27 trillion PEPE (which is worth around $1.98 million) from Bidexpro to four addresses on May 18th. 
                                                    
                                According to Lookonchain’s data, these four addresses use the same Bidexpro deposit address as blackrockfund3.eth. 
                                On April 21, BlackRock Fund purchased 1T PEPE (worth around $244K at that time) at an average price of $0.0000002431 and sold the entire stash (wort</p>

                        <div className="just_btn d-flex align-items-center justify-content-between">
                            <a href=""># Market</a>

                            <div>
                                <a href=""> <i className="fa fa-thumb"></i> 195</a>
                                <a href=""> <i className="fa fa-share-alt"></i></a>
                            </div>
                        </div>

                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-1 pe-0">
                            <div className="just">
                                <p> 
                                    Just now
                                </p>
                            </div>
                        </div>
                        <div className="col-md-11 ps-0">
                            <div className="just_box">
                                <h3>Alleged BlackRock Wallet Address Withdrew $1.2M Worth PEPE: On-Chain Data</h3>
                                <p>PEPE’s rise has ignited renewed investor interest in memecoins as a whole, and the frenzy continued even as bitcoin’s 2023 rally stalled. 
                                A wallet bearing the name of investment giant BlackRock withdrew 1.27 trillion PEPE (which is worth around $1.98 million) from Bidexpro to four addresses on May 18th. 
                                                    
                                According to Lookonchain’s data, these four addresses use the same Bidexpro deposit address as blackrockfund3.eth. 
                                On April 21, BlackRock Fund purchased 1T PEPE (worth around $244K at that time) at an average price of $0.0000002431 and sold the entire stash (wort</p>

                        <div className="just_btn d-flex align-items-center justify-content-between">
                            <a href=""># Market</a>

                            <div>
                                <a href=""> <i className="fa fa-thumb"></i> 195</a>
                                <a href=""> <i className="fa fa-share-alt"></i></a>
                            </div>
                        </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-1 pe-0">
                            <div className="just">
                                <p> 
                                    Just now
                                </p>
                            </div>
                        </div>
                        <div className="col-md-11 ps-0">
                            <div className="just_box">
                                <h3>Alleged BlackRock Wallet Address Withdrew $1.2M Worth PEPE: On-Chain Data</h3>
                                <p>PEPE’s rise has ignited renewed investor interest in memecoins as a whole, and the frenzy continued even as bitcoin’s 2023 rally stalled. 
                                A wallet bearing the name of investment giant BlackRock withdrew 1.27 trillion PEPE (which is worth around $1.98 million) from Bidexpro to four addresses on May 18th. 
                                                    
                                According to Lookonchain’s data, these four addresses use the same Bidexpro deposit address as blackrockfund3.eth. 
                                On April 21, BlackRock Fund purchased 1T PEPE (worth around $244K at that time) at an average price of $0.0000002431 and sold the entire stash (wort</p>

                        <div className="just_btn d-flex align-items-center justify-content-between">
                            <a href=""># Market</a>

                            <div>
                                <a href=""> <i className="fa fa-thumb"></i> 195</a>
                                <a href=""> <i className="fa fa-share-alt"></i></a>
                            </div>
                        </div>

                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-1 pe-0">
                            <div className="just">
                                <p> 
                                    Just now
                                </p>
                            </div>
                        </div>
                        <div className="col-md-11 ps-0">
                            <div className="just_box">
                                <h3>Alleged BlackRock Wallet Address Withdrew $1.2M Worth PEPE: On-Chain Data</h3>
                                <p>PEPE’s rise has ignited renewed investor interest in memecoins as a whole, and the frenzy continued even as bitcoin’s 2023 rally stalled. 
                                A wallet bearing the name of investment giant BlackRock withdrew 1.27 trillion PEPE (which is worth around $1.98 million) from Bidexpro to four addresses on May 18th. 
                                                    
                                According to Lookonchain’s data, these four addresses use the same Bidexpro deposit address as blackrockfund3.eth. 
                                On April 21, BlackRock Fund purchased 1T PEPE (worth around $244K at that time) at an average price of $0.0000002431 and sold the entire stash (wort</p>

                        <div className="just_btn d-flex align-items-center justify-content-between">
                            <a href=""># Market</a>

                            <div>
                                <a href=""> <i className="fa fa-thumb"></i> 195</a>
                                <a href=""> <i className="fa fa-share-alt"></i></a>
                            </div>
                        </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-1 pe-0">
                            <div className="just">
                                <p> 
                                    Just now
                                </p>
                            </div>
                        </div>
                        <div className="col-md-11 ps-0">
                            <div className="just_box">
                                <h3>Alleged BlackRock Wallet Address Withdrew $1.2M Worth PEPE: On-Chain Data</h3>
                                <p>PEPE’s rise has ignited renewed investor interest in memecoins as a whole, and the frenzy continued even as bitcoin’s 2023 rally stalled. 
                                A wallet bearing the name of investment giant BlackRock withdrew 1.27 trillion PEPE (which is worth around $1.98 million) from Bidexpro to four addresses on May 18th. 
                                                    
                                According to Lookonchain’s data, these four addresses use the same Bidexpro deposit address as blackrockfund3.eth. 
                                On April 21, BlackRock Fund purchased 1T PEPE (worth around $244K at that time) at an average price of $0.0000002431 and sold the entire stash (wort</p>

                        <div className="just_btn d-flex align-items-center justify-content-between">
                            <a href=""># Market</a>

                            <div>
                                <a href=""> <i className="fa fa-thumb"></i> 195</a>
                                <a href=""> <i className="fa fa-share-alt"></i></a>
                            </div>
                        </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-1 pe-0">
                            <div className="just">
                                <p> 
                                    Just now
                                </p>
                            </div>
                        </div>
                        <div className="col-md-11 ps-0">
                            <div className="just_box">
                                <h3>Alleged BlackRock Wallet Address Withdrew $1.2M Worth PEPE: On-Chain Data</h3>
                                <p>PEPE’s rise has ignited renewed investor interest in memecoins as a whole, and the frenzy continued even as bitcoin’s 2023 rally stalled. 
                                A wallet bearing the name of investment giant BlackRock withdrew 1.27 trillion PEPE (which is worth around $1.98 million) from Bidexpro to four addresses on May 18th. 
                                                    
                                According to Lookonchain’s data, these four addresses use the same Bidexpro deposit address as blackrockfund3.eth. 
                                On April 21, BlackRock Fund purchased 1T PEPE (worth around $244K at that time) at an average price of $0.0000002431 and sold the entire stash (wort</p>

                        <div className="just_btn d-flex align-items-center justify-content-between">
                            <a href=""># Market</a>

                            <div>
                                <a href=""> <i className="fa fa-thumb"></i> 195</a>
                                <a href=""> <i className="fa fa-share-alt"></i></a>
                            </div>
                        </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-1 pe-0">
                            <div className="just">
                                <p> 
                                    Just now
                                </p>
                            </div>
                        </div>
                        <div className="col-md-11 ps-0">
                            <div className="just_box">
                                <h3>Alleged BlackRock Wallet Address Withdrew $1.2M Worth PEPE: On-Chain Data</h3>
                                <p>PEPE’s rise has ignited renewed investor interest in memecoins as a whole, and the frenzy continued even as bitcoin’s 2023 rally stalled. 
                                A wallet bearing the name of investment giant BlackRock withdrew 1.27 trillion PEPE (which is worth around $1.98 million) from Bidexpro to four addresses on May 18th. 
                                                    
                                According to Lookonchain’s data, these four addresses use the same Bidexpro deposit address as blackrockfund3.eth. 
                                On April 21, BlackRock Fund purchased 1T PEPE (worth around $244K at that time) at an average price of $0.0000002431 and sold the entire stash (wort</p>

                        <div className="just_btn d-flex align-items-center justify-content-between">
                            <a href=""># Market</a>

                            <div>
                                <a href=""> <i className="fa fa-thumb"></i> 195</a>
                                <a href=""> <i className="fa fa-share-alt"></i></a>
                            </div>
                        </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-1 pe-0">
                            <div className="just">
                                <p> 
                                    Just now
                                </p>
                            </div>
                        </div>
                        <div className="col-md-11 ps-0">
                            <div className="just_box">
                                <h3>Alleged BlackRock Wallet Address Withdrew $1.2M Worth PEPE: On-Chain Data</h3>
                                <p>PEPE’s rise has ignited renewed investor interest in memecoins as a whole, and the frenzy continued even as bitcoin’s 2023 rally stalled. 
                                A wallet bearing the name of investment giant BlackRock withdrew 1.27 trillion PEPE (which is worth around $1.98 million) from Bidexpro to four addresses on May 18th. 
                                                    
                                According to Lookonchain’s data, these four addresses use the same Bidexpro deposit address as blackrockfund3.eth. 
                                On April 21, BlackRock Fund purchased 1T PEPE (worth around $244K at that time) at an average price of $0.0000002431 and sold the entire stash (wort</p>

                        <div className="just_btn d-flex align-items-center justify-content-between">
                            <a href=""># Market</a>

                            <div>
                                <a href=""> <i className="fa fa-thumb"></i> 195</a>
                                <a href=""> <i className="fa fa-share-alt"></i></a>
                            </div>
                        </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        <Footer />


        </>
    );
}

export default News;