import React from "react";
import { Link } from "react-router-dom";



function Signup(){
    return(
        <> 
         
<div className="wrapper">
    <div className="Login_form">
        <div className="container">
        <div className="row">
            <div className="offset-md-4 col-md-4">

                <div className="form-inner1">

                <div className="row align-items-center">    
                    <div className="col-md-12" id="signup1">
                          <div className="form-section">
                    <div className="form-inner">
                        {/* <a href="login-1.html" className="logo">
                            <img src="assests/logo.png" alt="logo" />
                        </a> */}
                        <h1 className="text-center">Sign Up to BidexPro</h1>

                        <div className="extra-login form-group clearfix">
                                <span>Or</span>
                            </div>

                        <form action="#" method="GET">
                            <div className="form-group form-box clearfix">
                                <input name="email" type="email" className="form-control" placeholder="Email Address" aria-label="Email Address" />
                               
                                <i className="flaticon-mail-2"></i>
                            </div>
                            <div className="form-group form-box clearfix">
                                <input name="password" type="password" className="form-control" autoComplete="off" placeholder="Password" aria-label="Password" />
                                <i className="flaticon-password"></i>
                            </div>
                            <div className="form-group form-box clearfix">
                                <input name="text" type="text" className="form-control" autoComplete="off" placeholder="Referral Code (Optional)" aria-label="Referral Code (Optional)" />
                                <i className="flaticon-password"></i>
                            </div>
                            <div className="checkbox form-group form-box ">
                                <p >Already have an account? <Link to="/login" className="thembo">Login</Link></p>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary btn-lg btn-theme" id="signup"><span>Sign up</span></button>
                                    </div>
                                </div>
                                debugger;
                                  <p >By clicking next, I hereby acknowledge that I agree to the terms of the <a href="#" className="thembo">Bidexpro.com</a></p>
                            </div>
                           
                        </form>

                    </div>
                </div>
                    </div>



                    <div className="col-md-5 d-none" >  
                        <div className="qr_box">
                            <h3>Scan to log in instantly</h3>
                            <img src="assests/qr.png" className="img-fluid" />
                        </div>
                        <ul className="list-unstyled text-white">
                            <li>1. Scan the above QR code</li>
                            <li>2. Tap Menu/Settings  QR Code Scanner</li>
                            <li>3. Scan the above QR code</li>
                        </ul>    
                    </div>

                        

                    <div className="col-md-12" id="otp1">

<div className="form-section">
<div className="form-inner">

<h1 className="text-center mb-4">OTP Verification</h1>

<form action="#" method="GET">
<div className="form-group form-box clearfix">
  <input name="text" type="text" className="form-control" placeholder="Your phone number" aria-label="Your phone number" />
  <i className="flaticon-mail-2"></i>
</div>


<div className="row">
  <div className="col-md-12">
      <div className="form-group">
          <button type="submit" className="btn btn-primary btn-lg btn-theme" id="otp_veri"><span>Send</span></button>
      </div>
  </div>

  <p>Don't get code? <a href="#" id="forget_login" className="thembo">Resend</a></p>
</div>

</form>
</div>
</div>
</div>


<div className="col-md-12" id="otp2">

<div className="form-section">
<div className="form-inner">
{/* <a href="login-1.html" className="logo">
<img src="assests/logo.png" alt="logo" />
</a>  */}
<h1 className="text-center mb-4">OTP Verification</h1>

<form action="#" method="GET">

<div className="row">
  <div className="col-md-12">
      <div className="form-group form-box clearfix text-center">
          <label>Your OTP Code</label>
      </div>
  </div>

          <div className="col-md-2">
              <div className="form-group form-box clearfix">
                  <input name="email" type="email" className="form-control" />
                  <i className="flaticon-mail-2"></i>
              </div>
          </div>
          <div className="col-md-2">
              <div className="form-group form-box clearfix">
                  <input name="email" type="email" className="form-control" />
                  <i className="flaticon-mail-2"></i>
              </div>
          </div>
          <div className="col-md-2">
              <div className="form-group form-box clearfix">
                  <input name="email" type="email" className="form-control" />
                  <i className="flaticon-mail-2"></i>
              </div>
          </div>
          <div className="col-md-2">
              <div className="form-group form-box clearfix">
                  <input name="email" type="email" className="form-control" />
                  <i className="flaticon-mail-2"></i>
              </div>
          </div>
          <div className="col-md-2">
              <div className="form-group form-box clearfix">
                  <input name="email" type="email" className="form-control" />
                  <i className="flaticon-mail-2"></i>
              </div>
          </div>
          <div className="col-md-2">
              <div className="form-group form-box clearfix">
                  <input name="email" type="email" className="form-control" />
                  <i className="flaticon-mail-2"></i>
              </div>
          </div>
      

  

</div>



<div className="row">
  <div className="col-md-12">
      <div className="form-group">
          <button type="submit" className="btn btn-primary btn-lg btn-theme"><span>Forget</span></button>
      </div>
  </div>

  <p>Already have an account? <Link to="/" id="forget_login" className="thembo">Login</Link></p>
</div>

</form>
</div>
</div>
</div>
                    

                </div>
                </div>
            </div>





        </div>
        </div>
    </div>
</div>
        </>
    )
}

export default Signup;