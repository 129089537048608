import React from "react";
import { Link } from "react-router-dom";
export default function LIstingwizard() {
  return (
    <div>
      <div className="inner_header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="inner_heading">
                <h3>
                  <i className="fa fa-angle-left"></i>
                  Listing a Coin on Bidexpro.com
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-12 content-right" id="start">
        <div id="wizard_container">
          <div id="top-wizard">
            <div id="progressbar"></div>
          </div>
          <form id="wrapped" method="POST">
            <input
              id="website"
              name="website"
              type="text"
              value=""
              className="d-none"
            />
            <div id="middle-wizard">
              <div className="step">
                <h3 className="main_question text-white d-flex justify-content-center align-items-center">
                  <strong>1</strong> &nbsp;Fill Out Your Basic Information
                </h3>
                <div className="form-inner1 py-0 ">
                  <div className="row align-items-center">
                    <div className="col-md-12" id="login">
                      <div className="form-section text-ceter">
                        <div className="form-inner">
                          <form action="#" method="GET">
                            <div className="form-group form-box clearfix">
                              <input
                                name="text"
                                type="text"
                                className="form-control"
                                placeholder="Project Name"
                              />
                              <i className="flaticon-mail-2"></i>
                            </div>
                            <div className="form-group form-box clearfix">
                              <input
                                name="text"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Token/Coin Full Name"
                              />
                              <i className="flaticon-password"></i>
                            </div>
                            <div className="form-group form-box clearfix">
                              <input
                                name="text"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Token/Coin Symbol"
                              />
                              <i className="flaticon-password"></i>
                            </div>
                            <div className="form-group form-box clearfix">
                              <input
                                name="email"
                                type="email"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Email"
                              />
                              <i className="flaticon-password"></i>
                            </div>
                            <div className="row">
                              <div className="col-md-12"></div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 d-none">
                      <div className="qr_box">
                        <h3>Scan to log in instantly</h3>
                        <img src="assets/qr.png" className="img-fluid" />
                      </div>
                      <ul className="list-unstyled text-white">
                        <li>1. Scan the above QR code</li>
                        <li>2. Tap Menu/Settings QR Code Scanner</li>
                        <li>3. Scan the above QR code</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="step">
                <h3 className="main_question text-white d-flex align-items-center">
                  <strong>2</strong> &nbsp;Complete Detailed Information
                </h3>
                <div className="form-inner1 py-0">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="detail_info">
                        <div className="detail_text mt-0">
                          <p className="m-0">
                            Please make sure to click the below links and upload
                            the additional information required through the
                            Bidexpro listing form and bidexpro Info Webpage.
                          </p>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-md-3">
                            <div className="detail_box text-center">
                              <img
                                src="banner/file.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="box_text">
                              <p className="mb-0">
                                Bidexpro Listing Application Form
                              </p>
                              <a href="/listing-application-form">
                                Listing Application Form
                              </a> 
                              <div className="mb-3 form-check mt-2">
                                <input
                                  type="checkbox"
                                  name="checkbox"
                                  className="form-check-input"
                                />
                                <label className="form-check-label">
                                  I have completed the required form above.
                                </label>
                              </div>
                              <p className="mt-4">
                                Confirm by ticking the above box you have
                                finished uploading all information, then click
                                the "Apply" button to complete your application.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="step">
                <h3 className="main_question text-white d-flex">
                  <strong>3</strong> &nbsp;Application Submitted Successfully
                </h3>
                <div className="form-inner1 py-0 ">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="detail_info">
                        <div className="detail_text mt-0">
                          <p className="m-0">
                            Please make sure to click the below links and upload
                            the additional information required through the
                            Bidexpro listing form and bidexpro Info Webpage.
                          </p>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-md-12">
                            <div className="box_text border-0 text-center">
                              <img
                                src="banner/check.png"
                                className="img-fluid"
                                alt=""
                              />
                              <h3 className="my-3">
                                Application Submited <br /> Successfully
                              </h3>
                              <p className="mt-4">
                                Your application is pending review. Applications
                                that pass the initial review will be contacted
                                at a later date, as outlined during the
                                submission process.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="bottom-wizard"
              className="d-flex align-items-center justify-content-center"
            >

              {/* <button type="button" name="backward" className="btn btn-primary backward w-50">Prev</button>
              <button type="button" name="forward" className="btn btn-primary forward w-50 ms-3"> Next </button>
              <button type="submit" name="process" className="btn btn-primary submit w-50"> Submit </button> */}


              <button type="button" name="backward" className="btn btn-primary backward w-50">Prev</button>
              <button type="button" name="forward" className="btn btn-primary forward w-50 ms-3"> Next </button>
              <button type="submit" name="process" className="btn btn-primary submit w-50"> Submit </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
