import React from "react";

function  Profile(){
    return(
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <div className="card mt-5">
                            <div className="card-body">

                                <div className="d-flex align-items-center my-4">
                                    <img src="https://crypo.netlify.app/assets/img/avatar.svg" className="img-fluid  " />
                                    <div className="settings-profile ms-3">
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input form-control mb-0" id="fileUpload" />
                                            {/* <label className="custom-file-label" for="fileUpload">Choose avatar</label> */}
                                        </div>
                                    </div>
                                </div>

                                  <h5 className="card-title">General Information</h5>

                                  <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label text-white">First name</label>
                                            <input type="text" className="form-control"  placeholder="First Name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label text-white">Last name</label>
                                            <input type="text" className="form-control"  placeholder="Last name"/>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label text-white">Email</label>
                                            <input type="email" className="form-control"  placeholder="Enter your Email"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label text-white">Phone</label>
                                            <input type="text" className="form-control"  placeholder="Enter phone number"/>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label text-white">Language</label>
                                            <select className="form-select">
                                            <option selected="">English</option>
                                            <option>Mandarin Chinese</option>
                                            <option>Spanish</option>
                                            <option>Arabic</option>
                                            <option>Russian</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                                <label className="form-label text-white">Currency</label>
                                                <select className="form-select">
                                                    <option selected="">USD</option>
                                                    <option>EUR</option>
                                                    <option>GBP</option>
                                                    <option>CHF</option>
                                                </select>
                                            </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mt-4">
                                            <button className="btn btn-primary">Update</button>
                                        </div>
                                    </div>
                                  </div>
                            </div>  
                        </div>
                    </div>


                    <div className="offset-md-2 col-md-8">
                        <div className="card mt-5">
                            <div className="card-body">
                                  <h5 className="card-title">Change Password</h5>

                                  <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label text-white">Current password</label>
                                            <input type="text" className="form-control"  placeholder="Enter your password"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label text-white">New password</label>
                                            <input type="text" className="form-control"  placeholder="Enter new password"/>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label text-white">Security questions #1</label>
                                            <select className="form-select">
                                                <option selected="">What was the name of your first pet?</option>
                                                <option>What's your Mother's middle name?</option>
                                                <option>What was the name of your first school?</option>
                                                <option>Where did you travel for the first time?</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label text-white">Answer</label>
                                            <input type="text" className="form-control"  placeholder="Enter your answer"/>
                                        </div>
                                    </div>

                                   
                                    <div className="col-md-6">
                                        <div className="mt-4">
                                            <button className="btn btn-primary">Update</button>
                                        </div>
                                    </div>
                                  </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile;